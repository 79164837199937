import ParentDatePicker, { DatePickerOnChangeParams, DatePickerProps } from './datePicker';
import RangePicker, { RangeDatePickerOnChangeParams, RangeDatePickerProps } from './range-picker';
import Time from './time';

export type CompoundedComponent = typeof ParentDatePicker & {
  RangeDate: typeof RangePicker;
  TimePicker: typeof Time;
};

const DatePicker = ParentDatePicker as CompoundedComponent;
DatePicker.RangeDate = RangePicker;
DatePicker.TimePicker = Time;

export type {
  DatePickerProps,
  RangeDatePickerProps,
  DatePickerOnChangeParams,
  RangeDatePickerOnChangeParams,
};
export default DatePicker;
