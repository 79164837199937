export const downloadFile = (url, filename) => {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Download failed');
      }
      return response.blob();
    })
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
      // Handle error if needed
    });
};

export type ReportsFilterType = {
  userId?: string;
  startDate?: string;
  endDate?: string;
  page: number;
  limit: number;
  sort: string;
  order: 'DESC' | 'ASC';
};

export const initialFilter: ReportsFilterType = {
  page: 1,
  limit: 8,
  sort: 'tripDate',
  order: 'DESC',
};
