import { FC } from 'react';

import { Table } from 'ui';

const KlrBookingsList: FC = () => {
  const columns = [
    {
      title: 'Перевізник, Маршрут',
    },
    {
      title: 'Дата',
    },
    {
      title: 'Пасажир',
    },
    {
      title: 'Контакти',
    },
    {
      title: 'Ціна',
    },
    {
      title: 'Статус',
    },
    {
      title: 'Агент',
    },
    {
      title: 'Коментарі',
    },
    {
      title: 'Дії',
    },
  ];
  return (
    <div>
      <Table dataSource={[]} columns={columns} scroll={{ x: 600 }} />
    </div>
  );
};

export default KlrBookingsList;
