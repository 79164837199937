import React, { FC, useEffect, useState } from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { UploadProps } from 'antd/es/upload/interface';
import 'antd/lib/modal/style/css';
import 'antd/lib/slider/style/css';
import { getBase64 } from 'tools/base64';
import { Icon, Typography } from 'ui';

import s from './UploadLogo.module.scss';

const { Dragger } = Upload;

interface UploadLogoProps {
  handleAdd: (url: string) => void;
  logoUrl?: string;
}

const UploadLogo: FC<UploadLogoProps> = (props) => {
  const { handleAdd, logoUrl } = props;
  const [imageUrl, setImageUrl] = useState<string>();

  const draggerProps: UploadProps = {
    onRemove: () => {
      setImageUrl('');
    },
    beforeUpload: (file) => {
      getBase64(file, (url) => {
        setImageUrl(url);
      });

      return false;
    },
    multiple: false,
    maxCount: 1,
    showUploadList: false,
  };

  const handleDeleteLogo = () => {
    handleAdd('');
  };

  useEffect(() => {
    if (imageUrl) {
      handleAdd(imageUrl);
    } else {
      handleAdd('');
    }
  }, [handleAdd, imageUrl]);

  return (
    <div>
      <ImgCrop
        rotationSlider
        modalProps={{ okButtonProps: { block: true }, cancelButtonProps: { block: true } }}>
        <Dragger {...draggerProps} className={s.dragger} disabled={!!logoUrl}>
          {logoUrl ? (
            <img src={logoUrl} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <div className={s.hint}>
              <Icon name="thumbnail" size={40} />
              <Typography type="small" className={s.uploadHint}>
                Drag & drop or click to add image
              </Typography>
              <Typography type="small" className={s.uploadHintType}>
                JPEG or PNG, not larger than 2MB.
              </Typography>
            </div>
          )}
          {logoUrl && (
            <button onClick={handleDeleteLogo} className={s.delete}>
              <Icon name="trashBox" />
            </button>
          )}
        </Dragger>
      </ImgCrop>
    </div>
  );
};

export default UploadLogo;
