import { CSSProperties, FC } from 'react';

import { rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cn from 'classnames';
import Icon from 'ui/icon';
import { Typography } from 'ui/index';

import s from './TimetableItem.module.scss';

interface TimetableItemProps {
  city: string;
  station: string;
  time: string;
  change?: boolean;
  id: string;
  handleDelete?: () => void;
  handleEdit?: () => void;
  disableDrag?: boolean;
}

const TimetableItem: FC<TimetableItemProps> = ({
  city,
  station,
  id,
  time,
  change,
  handleDelete,
  handleEdit,
  disableDrag = false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    strategy: rectSortingStrategy,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <div ref={setNodeRef} style={style} className={cn(s.item, { [s.disableDrag]: disableDrag })}>
      {!disableDrag && (
        <div {...listeners} {...attributes} ref={setActivatorNodeRef} className={s.drag}>
          <Icon name="drag" size={36} />
        </div>
      )}
      <div className={s.info}>
        <div>
          <Typography type="main" weight={600}>
            {city}
          </Typography>
          <Typography type="main">{station}</Typography>
        </div>
        <div>
          <Typography type="main" weight={600}>
            {time}
          </Typography>
          {change && (
            <div className={s.change}>
              <Typography type="small" color="green">
                Пересадка
              </Typography>
            </div>
          )}
        </div>
        <div className={s.actions}>
          {!!handleEdit && (
            <button className={s.action} onClick={handleEdit}>
              <Icon name="edit" size={18} />
            </button>
          )}
          {!!handleDelete && (
            <button className={s.action} onClick={handleDelete}>
              <Icon name="delete" size={18} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimetableItem;
