import React, { FC, useCallback, useEffect } from 'react';

import { Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CARRIER_COMFORT, CREATE_CARRIER_ROUTE } from 'routes/core/list';
import { Breadcrumbs, Button, Icon, Typography } from 'ui';

import {
  useDeleteCarrierMutation,
  useLazyGetCarrierByIdQuery,
} from 'services/carriers/carriersApiService';

import { getHoursRefund } from 'pages/carriers/create-new-carrier/createNewCarrier.utils';

import ComponentWithContainer from 'components/componentWithContainer';
import RoutesListTable from 'containers/routes-list-table';

import s from './CarriersFullInfo.module.scss';

const CarriersFullInfo: FC = () => {
  const params = useParams();
  const [getCarrierById, { data: carrier }] = useLazyGetCarrierByIdQuery();
  const [deleteCarrier] = useDeleteCarrierMutation();
  const navigate = useNavigate();

  const BREADCRUMBS_ROUTES = [
    { name: 'Список перевізників', path: CARRIER_COMFORT },
    { name: `Перевізник ${carrier?.name}` },
  ];

  useEffect(() => {
    if (params?.id) {
      getCarrierById(params?.id);
    }
  }, [getCarrierById, params.id]);

  const handleDeleteCarrier = useCallback(() => {
    if (params?.id) {
      deleteCarrier(params?.id).then(() => {
        navigate(CARRIER_COMFORT);
      });
    }
  }, [deleteCarrier, navigate, params?.id]);

  const goToEdit = () => {
    navigate(`${CREATE_CARRIER_ROUTE}?id=${params?.id}`);
  };

  const carrierActions = (
    <div className={s.actions}>
      <Button color="secondary" onClick={handleDeleteCarrier}>
        Видалити
      </Button>
      <Button onClick={goToEdit}>Редагувати</Button>
    </div>
  );

  return (
    <ComponentWithContainer title={`Перевізник ${carrier?.name || ''}`} actions={carrierActions}>
      <Breadcrumbs routes={BREADCRUMBS_ROUTES} />
      <div className={s.wrapper}>
        <div className={s.block}>
          <div className={s.info}>
            {!!carrier?.baggageAllowance && (
              <div>
                <Typography type="h4" className={s.sectionTitle}>
                  Багаж
                </Typography>
                <Typography type="main" weight={600}>
                  {carrier?.baggageAllowance}
                </Typography>
              </div>
            )}
            {!!carrier?.services?.length && (
              <div className={s.services}>
                <Typography type="h4" className={s.sectionTitle}>
                  Сервіси
                </Typography>
                <div className={s.servicesContent}>
                  {carrier?.services.map((service, id) => (
                    <Tag key={`${service.id}.${id}`} color="#256eff" className={s.tag}>
                      {service.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {carrier?.supportPhones?.length && (
              <div>
                <Typography type="h4" className={s.sectionTitle}>
                  Телефони звязку
                </Typography>
                {carrier?.supportPhones?.map((item, i) => (
                  <Typography type="main" weight={600} key={`${item}_${i}`}>
                    {item}
                  </Typography>
                ))}
              </div>
            )}
            {!!carrier?.refundsPolicy?.length && (
              <div>
                <Typography type="h4" className={s.sectionTitle}>
                  Політика повернення квитка
                </Typography>
                <ul>
                  {carrier?.refundsPolicy?.map((refund, index) => (
                    <li key={index}>
                      <div className={s.refundItem}>
                        <Typography type="main" weight={600}>
                          {refund.maxHours === 100
                            ? 'Відміна рейсу перевізником'
                            : `Повернення за ${getHoursRefund(refund.maxHours)}`}
                        </Typography>
                        <Typography type="main" weight={600}>
                          {refund.percent}% від вартості квитка
                        </Typography>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!!carrier?.discounts?.length && (
              <div>
                <Typography type="h4" className={s.sectionTitle}>
                  Дісконти
                </Typography>
                {carrier?.discounts?.map((discount, index) => (
                  <div key={index}>
                    <Typography type="main" weight={600}>
                      {discount.label}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            {carrier?.logo ? (
              <img src={carrier.logo} alt={carrier.name} className={s.logo} />
            ) : (
              <div className={s.emptyLogo}>
                <Icon name="logoSmall" size={70} />
              </div>
            )}
          </div>
        </div>

        {!!carrier?.routes?.length && (
          <div>
            <Typography type="h4">Маршрути</Typography>
            <RoutesListTable routesList={carrier?.routes} carrierName={carrier?.name} />
          </div>
        )}
      </div>
    </ComponentWithContainer>
  );
};

export default CarriersFullInfo;
