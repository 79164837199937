import { FC } from 'react';

import type { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import 'antd/lib/date-picker/style/css';
import { Locale } from 'antd/lib/locale-provider';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/uk';
import { ConfigProvider } from 'ui/index';

import s from './DatePicker.module.scss';
import { GeneratePicker } from './utils';

export interface DatePickerProps extends PickerDateProps<Dayjs> {
  fullWidth?: boolean;
}
export type DatePickerOnChangeParams = Parameters<NonNullable<DatePickerProps['onChange']>>;

dayjs.locale('uk'); // Set the Day.js locale to Ukrainian (uk)
const locale: Locale = {
  locale: 'uk',
  DatePicker: {
    lang: {
      locale: 'uk',
      placeholder: 'Виберіть дату',
      rangePlaceholder: ['Початкова дата', 'Кінцева дата'],
      today: 'Сьогодні',
      now: 'Зараз',
      backToToday: 'Повернутися до сьогодення',
      ok: 'OK',
      clear: 'Очистити',
      month: 'Місяць',
      year: 'Рік',
      timeSelect: 'Вибрати час',
      dateSelect: 'Вибрати дату',
      monthSelect: 'Оберіть місяць',
      yearSelect: 'Оберіть рік',
      decadeSelect: 'Оберіть десятиліття',
      yearFormat: 'YYYY',
      dateFormat: 'DD.MM.YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
      monthBeforeYear: true,
      previousMonth: 'Попередній місяць (PageUp)',
      nextMonth: 'Наступний місяць (PageDown)',
      previousYear: 'Попередній рік (Control + left)',
      nextYear: 'Наступний рік (Control + right)',
      previousDecade: 'Попереднє десятиліття',
      nextDecade: 'Наступне десятиліття',
      previousCentury: 'Попереднє століття',
      nextCentury: 'Наступне століття',
    },
    timePickerLocale: {},
  },
};

const DatePicker: FC<DatePickerProps> = (props) => {
  const { className = '', fullWidth, ...otherProps } = props;

  return (
    <ConfigProvider locale={locale}>
      <GeneratePicker
        className={cn(s.wrapper, { [className]: className, [s.fullWidth]: fullWidth })}
        {...otherProps}
      />
    </ConfigProvider>
  );
};

export default DatePicker;
