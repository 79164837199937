import { FC, useEffect } from 'react';

import { Button, DatePicker, Form, Input, Label, Select, Switch, TextArea, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { useLazyGetCarriersQuery } from 'services/carriers/carriersApiService';

import { PREPAYMENT_OPTION } from 'pages/routes-list/routes-create-form/routesCreateForm.utils';

import CityDropdown from 'containers/city-dropdown';

import s from './RoutesCreateFormBaseInfo.module.scss';

const RoutesCreateFormBaseInfo: FC = () => {
  const [getCarriers, { data: carrierList }] = useLazyGetCarriersQuery();

  useEffect(() => {
    getCarriers();
  }, [getCarriers]);

  return (
    <>
      <div>
        <Label required>Назва Маршруту</Label>
        <Form.Item name="routeName" rules={[formRules.required]}>
          <Input name="routeName" placeholder="Введіть назву маршруту" />
        </Form.Item>
      </div>
      <div>
        <Label required>Номер Маршруту</Label>
        <Form.Item name="routeNumber" rules={[formRules.required]}>
          <Input name="routeNumber" placeholder="Введіть номер маршруту" />
        </Form.Item>
      </div>

      <div>
        <Label required>Перевізник</Label>
        <Form.Item name="carrier" rules={[formRules.required]}>
          <Select
            placeholder="Виберіть перевізника"
            options={carrierList?.map((carrier) => ({ label: carrier?.name, value: carrier?.id }))}
          />
        </Form.Item>
      </div>

      <div className={s.block}>
        <div className={s.item}>
          <Form.Item name="fromCity" fullWidth rules={[formRules.required]}>
            <CityDropdown
              label="Місто відправлення"
              placeholder="Введіть місто відправлення"
              name="fromCity"
            />
          </Form.Item>
        </div>
        <div className={s.item}>
          <Form.Item name="toCity" fullWidth rules={[formRules.required]}>
            <CityDropdown
              label="Місто призначення"
              placeholder="Введіть місто призначення"
              name="toCity"
            />
          </Form.Item>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.item}>
          <Label required>Час відправлення</Label>
          <Form.Item name="departureTime" fullWidth rules={[formRules.required]}>
            <DatePicker.TimePicker fullWidth format="HH:mm" />
          </Form.Item>
        </div>
        <div className={s.item}>
          <Label required>Час прибуття</Label>
          <Form.Item name="arrivalTime" fullWidth rules={[formRules.required]}>
            <DatePicker.TimePicker fullWidth format="HH:mm" />
          </Form.Item>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.item}>
          <Label required>Ціна</Label>
          <Form.Item name={['price', 'value']} rules={[formRules.required]}>
            <Input.Number name="routeNumber" placeholder="0.00" style={{ width: '100%' }} />
          </Form.Item>
        </div>
        <div className={s.item}>
          <Label required>Валюта</Label>
          <Form.Item name={['price', 'currency']} rules={[formRules.required]}>
            <Select
              options={[
                { label: 'Гривня', value: 'UAH' },
                { label: 'Польский злотий', value: 'PLN' },
                { label: 'Чешська крона', value: 'CZK' },
                { label: 'Євро', value: 'EUR' },
                { label: 'Долар', value: 'USD' },
              ]}
              placeholder="Виберіть валюту"
              style={{ minWidth: 200 }}
            />
          </Form.Item>
        </div>
      </div>
      <div className={s.block}>
        <div className={s.item}>
          <Label required>Оплата</Label>
          <Form.Item
            name="prepayment"
            initialValue={{ value: 'undefined' }}
            rules={[formRules.required]}>
            <Select options={PREPAYMENT_OPTION} />
          </Form.Item>
        </div>
      </div>

      <div>
        <Label required>Станція відправлення</Label>
        <Form.Item name="fromStation" rules={[formRules.required]}>
          <Input name="fromStation" placeholder="Введіть станцію відправлення" />
        </Form.Item>
      </div>
      <div>
        <Label required>Станція призначення</Label>
        <Form.Item name="toStation" rules={[formRules.required]}>
          <Input name="toStation" placeholder="Введіть станцію призначення" />
        </Form.Item>
      </div>
      <div className={s.space}>
        <div className={s.switch}>
          <Form.Item name="freeChoiceOfPlaces">
            <Switch />
          </Form.Item>
          <Typography type="main" weight={600}>
            Безкоштовний вибір місця
          </Typography>
        </div>

        <div className={s.switch}>
          <Form.Item name="addressDelivery">
            <Switch />
          </Form.Item>
          <Typography type="main" weight={600}>
            Можливі адресні перевезення
          </Typography>
        </div>

        <div className={s.switch}>
          <Form.Item name="freeSeating">
            <Switch />
          </Form.Item>
          <Typography type="main" weight={600}>
            Вільна розсадка
          </Typography>
        </div>
      </div>

      <div>
        <Label>Комментар</Label>
        <Form.Item name="routeComment">
          <TextArea name="comment" placeholder="Введіть коментар" maxLength={250} />
        </Form.Item>
      </div>
      <div className={s.submit}>
        <Button htmlType="submit" fullWidth>
          Перейти до наступного кроку
        </Button>
      </div>
    </>
  );
};

export default RoutesCreateFormBaseInfo;
