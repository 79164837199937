import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Form, Icon, Modal, Select, Typography } from 'ui';

import { useLazyGetAllDiscountsQuery } from 'services/discounts/discountsApiService';
import {
  useAddSalesMutation,
  useDeleteRouteMutation,
  useRemoveSalesMutation,
  useUpdatePopularStatusMutation,
  useUpdateRecommendedStatusMutation,
} from 'services/routes/routesService';

import AddedNewSaleField from 'containers/routes-list-table/routes-table-action-dropdown/addedNewSaleField';

import { getEditRoute } from '../../../routes/routes-list/list';
import s from './RoutesTableActionDropdown.module.scss';

interface RoutesTableActionDropdownProps {
  routeId: string;
  info: {
    popular: boolean;
    recommended: boolean;
    sale?: { value: number; name: string };
  };
}

const RoutesTableActionDropdown: FC<RoutesTableActionDropdownProps> = (props) => {
  const { routeId, info } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [deleteRoute] = useDeleteRouteMutation();
  const [updatePopular] = useUpdatePopularStatusMutation();
  const [updateRecommended] = useUpdateRecommendedStatusMutation();
  const [addSale, { isLoading }] = useAddSalesMutation();
  const [removeSale] = useRemoveSalesMutation();
  const [getAllDiscounts] = useLazyGetAllDiscountsQuery();
  const [salesList, setSalesList] = useState<{ value: string; name: string }[]>([]);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const DROPDOWN_ITEMS = [
    {
      label: 'Редагувати Маршрут',
      key: 'edit',
    },
    {
      label: 'Видалити Маршрут',
      key: 'delete',
    },
    {
      label: !info?.sale?.value ? 'Додати знижку' : 'Видалити знижку',
      key: !info?.sale?.value ? 'addSale' : 'deleteSale',
    },
    {
      label: info?.popular ? 'Видалити з популярних' : 'Додати до популярних',
      key: info?.popular ? 'deletePopular' : 'makePopular',
    },
    {
      label: info?.recommended ? 'Видалити з рекомендованих' : 'Додати до рекомендованих',
      key: info?.recommended ? 'deleteRecommended' : 'makeRecommended',
    },
  ];

  const handleAddSale = useCallback(
    (data) => {
      console.log(data);
      addSale({
        sale: { value: parseInt(data?.sale?.value.split('_')[1], 10), name: data.sale.label },
        id: routeId,
      }).then((data) => {
        if (data) {
          setOpenModal(false);
        }
      });
    },
    [addSale, routeId],
  );

  const handleItemClick = useCallback(
    (event: { key: string }) => {
      if (event.key === 'delete') {
        deleteRoute(routeId);
      }

      if (event.key === 'edit') {
        navigate(getEditRoute(routeId));
      }

      if (event.key === 'makePopular') {
        updatePopular({ id: routeId, status: 'true' });
      }

      if (event.key === 'deletePopular') {
        updatePopular({ id: routeId, status: 'false' });
      }

      if (event.key === 'makeRecommended') {
        updateRecommended({ id: routeId, status: 'true' });
      }

      if (event.key === 'deleteRecommended') {
        updateRecommended({ id: routeId, status: 'false' });
      }

      if (event.key === 'addSale') {
        getAllDiscounts().then((data) => {
          if (data.data) {
            const res = data.data.map((item) => ({
              ...item,
              value: `${item?.name.split(' ')[0]}_${item?.value}`,
            }));
            setSalesList(res);
          }
        });
        setOpenModal(true);
      }

      if (event.key === 'deleteSale') {
        removeSale({ id: routeId });
      }

      setOpen(false);
    },
    [deleteRoute, getAllDiscounts, navigate, removeSale, routeId, updatePopular, updateRecommended],
  );

  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={setOpen}
        items={DROPDOWN_ITEMS}
        handleItemClick={handleItemClick}
        placement="bottomLeft">
        <button
          className={s.dropdownButton}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          <Icon name="verticalDots" />
        </button>
      </Dropdown>
      <Modal handleClose={() => setOpenModal(false)} open={openModal} footer={false}>
        <Modal.Header>
          <Typography type="h4">Додати знижку</Typography>
        </Modal.Header>
        <Modal.Content>
          <Form className={s.form} onFinish={handleAddSale}>
            <Form.Item name="sale">
              <Select
                options={salesList?.map((item) => ({ label: item.name, value: item.value }))}
                placeholder="Виберіть знижку"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AddedNewSaleField setSalesList={setSalesList} />
                  </>
                )}
              />
            </Form.Item>
            <Modal.Actions>
              <Button htmlType="submit" fullWidth isLoading={isLoading}>
                Зберегти
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default RoutesTableActionDropdown;
