import { FC, useEffect } from 'react';

import 'react-algolia-places/build/style.css';
import { Button, Typography } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import ContrabusItem from 'pages/booking/booking-list/contrabus-item';

import { KlrRouteType, RouteType } from 'types/routes/routesTypes';
import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

import s from './BookingList.module.scss';
import KlrItem from './klr-item';
import TopTicketItem from './topTicket-item';

interface BookingListProps {
  contraBus?: RouteType[];
  klr?: KlrRouteType[];
  topTicket?: FullRouteType[];
  handleChangeDate: (date: string) => void;
  fetching: {
    contraBus?: boolean;
    klr?: boolean;
    topTicket?: boolean;
  };
}
const BookingList: FC<BookingListProps> = (props) => {
  const { contraBus, klr, topTicket, fetching, handleChangeDate } = props;
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const showContentRoles = ['ADMIN', 'OPERATOR'].includes(String(currentUser?.roles));

  return (
    <div className={s.wrapper}>
      {(!!topTicket?.length || !!klr?.length || !!contraBus?.length) && (
        <Typography type="h4">Маршрути TopTicket</Typography>
      )}
      {!fetching.topTicket && !!topTicket?.length && (
        <div className={s.partners}>
          {topTicket[0]?.id ? (
            topTicket?.map((route) => <TopTicketItem key={route.id} item={route} />)
          ) : (
            <Button
              onClick={() =>
                handleChangeDate(String(topTicket[0]))
              }>{`На вибрану дату рейсів немає, доступна дата ${topTicket[0]}`}</Button>
          )}
        </div>
      )}
      {!fetching.klr && !!klr?.length && (
        <div className={s.partners}>
          {showContentRoles && <Typography type="h4">Маршрути KLR</Typography>}
          {klr?.map((route) => (
            <KlrItem key={route.route_id} route={route} />
          ))}
        </div>
      )}

      {!fetching.contraBus && !!contraBus?.length && (
        <div className={s.partners}>
          {showContentRoles && <Typography type="h4">Маршрути ContraBus</Typography>}
          {contraBus?.map((route) => (
            <ContrabusItem key={route.data_bundle} route={route} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BookingList;
