import { FC, useEffect } from 'react';

import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
// import Logo from 'src/logo.png';
import { Icon, Typography } from 'ui';

import {
  useLazyGetCurrentUserQuery,
  useLogoutMutation,
} from 'services/user-management/userManagementApiService';
import { setIsOpenedMobileMenu } from 'slices/systemSlice';
import { useAppDispatch } from 'store/index';

import { BOOKING_ROUTE } from '../../routes/core/list';
import { USER_SETTING_ROUTE } from '../../routes/user-management/list';
import s from './AppHeader.module.scss';

const AppHeader: FC = () => {
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [logoutUser] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goToSetting = () => {
    navigate(USER_SETTING_ROUTE);
  };

  const handleOpenMenu = () => {
    dispatch(setIsOpenedMobileMenu(true));
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (currentUser?.id) {
      if (!currentUser?.active) {
        logoutUser();
      }
    }
  }, [currentUser, logoutUser]);

  return (
    <Layout.Header className={s.wrapper}>
      <button className={s.button} onClick={() => navigate(BOOKING_ROUTE)}>
        <Icon name="logo" height={34} width={150} />
        {/*<img src={Logo} alt="logo" />*/}
      </button>
      <div className={s.hideMobile}>
        <button className={s.profile} onClick={goToSetting}>
          <Typography type="small" color="grey">
            {currentUser?.fullName}
          </Typography>
          <Icon name="profile" />
        </button>
      </div>
      <div className={s.mobileBlock}>
        <button className={s.profile} onClick={handleOpenMenu}>
          <Icon name="burgerMenu" />
        </button>
      </div>
    </Layout.Header>
  );
};

export default AppHeader;
