import Cookies from 'js-cookie';

export const setTokenCookies = (accessToken?: string, refreshToken?: string) => {
  Cookies.set('access_token', accessToken);
  Cookies.set('refresh_token', refreshToken);
  Cookies.set('is_logged_in', 'true'); // 1 hour
};

export const updateAccessTokenCookie = (accessToken: string) => {
  Cookies.set('access_token', accessToken, { expires: 15 / 1440 }); // 15 minutes
};

export const getAccessToken = () => Cookies.get('accessToken');
export const getIsAuth = () => Cookies.get('isAuth') === 'true';
export const getRefreshToken = () => Cookies.get('refresh_token');

export const getIsLoggedIn = () => Cookies.get('is_logged_in') === 'true';

export const removeTokenCookies = () => {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  Cookies.remove('is_logged_in');

  return true;
};
