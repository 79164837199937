import { FC, PropsWithChildren, useMemo } from 'react';

import classNames from 'classnames';

import s from './HintPassword.module.scss';

interface HintPasswordProps extends PropsWithChildren {
  field: string;
  value: string;
}
const HintPassword: FC<HintPasswordProps> = ({ field, children, value }) => {
  const validate = useMemo(() => {
    const length = value?.length >= 10,
      upperCase = /[A-Z]/.test(value),
      number = /\d/.test(value),
      symbol = /[`~!@#$%^&*()_=+]/.test(value);

    return { length, upperCase, number, symbol };
  }, [value]);

  return (
    <div
      className={classNames(s.item, {
        [s.invalid]: !validate[field] && value?.length > 0,
        [s.valid]: validate[field] && value?.length > 0,
      })}>
      {children}
    </div>
  );
};

export default HintPassword;
