import { FC, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { Form } from 'ui';

import {
  useCreateContraBusBookingMutation,
  useCreateTopTicketBookingMutation,
} from 'services/bookings/bookingsApiService';

import BookingCreateFormSuccessStep from 'pages/booking/booking-create/booking-create-form/booking-create-form-steps/booking-create-form-success-step';

import { RouteForBookingType } from 'types/routes/routesTypes';

import BookingCreateFormFirstStep from './booking-create-form-steps/booking-create-form-first-step';

interface BookingCreateFormProps {
  route: RouteForBookingType;
}
export type PassengerType = {
  firstName: string;
  lastName: string;
  phone: string;
  comment?: string;
  discount?: string;
  ticketType?: string;
}[];

const BookingCreateForm: FC<BookingCreateFormProps> = (props) => {
  const { route } = props;
  const [form] = Form.useForm();
  const [createBookingTopTicket, { isLoading: isTopTicketLoading }] =
    useCreateTopTicketBookingMutation();
  const [createBookingContraBus, { isLoading: isContraBusLoading }] =
    useCreateContraBusBookingMutation();
  const [passengers, setPassengers] = useState<PassengerType>([]);
  const [step, setStep] = useState('first');
  const isLoading = isTopTicketLoading || isContraBusLoading;

  const handleSubmit = useCallback(async () => {
    if (route.owner === 'contraBus') {
      if ('data_bundle' in route.route) {
        await createBookingContraBus({
          passengers_data: passengers?.map((passenger) => ({
            name: passenger.firstName,
            surname: passenger.lastName,
            phone: passenger.phone,
            viber: passenger.phone,
          })),
          data_bundle: route?.route.data_bundle as string,
        }).then((data) => {
          if (data) {
            setStep('success');
          }
        });
      }
    }

    if (route.owner === 'topTicket') {
      const result: { routeId: string; tripDate: string; passengers: PassengerType } = {
        routeId: '',
        tripDate: '',
        passengers: passengers,
      };
      if ('id' in route.route) {
        result.routeId = route.route.id;
      }
      if ('departureDateTime' in route.route) {
        result.tripDate = dayjs(route.route.departureDateTime).format('YYYY-MM-DD');
      }
      await createBookingTopTicket(result).then((data) => {
        if ('data' in data) {
          setStep('success');
        }
      });
    }
  }, [createBookingContraBus, createBookingTopTicket, passengers, route]);

  return (
    <div>
      {step === 'first' && (
        <BookingCreateFormFirstStep
          form={form}
          handleSubmit={handleSubmit}
          setPassengers={setPassengers}
          passengers={passengers}
          route={route}
          isLoading={isLoading}
        />
      )}
      {step === 'success' && <BookingCreateFormSuccessStep booking={passengers} />}
    </div>
  );
};

export default BookingCreateForm;
