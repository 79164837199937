import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { loginEndpoint } from 'services/user-management/userManagementApiService';

import { UserProfileType, UserType } from 'types/core/userTypes';

export const SLICE_NAME = 'authSlice';

export type AuthState = {
  accessToken?: UserType['accessToken'] | null;
  refreshToken?: UserType['refreshToken'] | null;
  userProfile?: UserProfileType | null;
  isLoggedIn?: boolean;
};

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  userProfile: null,
};

export const authSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      return { ...state, ...action.payload };
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(loginEndpoint.matchFulfilled, (state) => {
      state.isLoggedIn = true;
    });
  },
});

export const { setAuthState, logout } = authSlice.actions;
export default authSlice.reducer;
