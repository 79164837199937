import { SERVICES_TAGS } from '../../../constants/servicesTags';

import { UserType } from 'types/core/userTypes';
import { CurrentUserResponseType } from 'types/user-management/api';
import {
  LoginRequestProps,
  RegisterRequestProps,
  RegisterResponseProps,
} from 'types/user-management/auth';
import { CurrentUserType, USER_ROLES } from 'types/user-management/core';

import initialApiService from '../initialApiService';

const userManagementApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserType, LoginRequestProps>({
      query: (body) => ({
        url: '/users/login',
        method: 'POST',
        body,
      }),
    }),

    register: builder.mutation<RegisterResponseProps, RegisterRequestProps>({
      query: (body) => ({
        url: '/users/register',
        method: 'POST',
        body,
      }),
    }),

    getAllUser: builder.query<CurrentUserResponseType[], void>({
      query: () => ({
        url: '/users/all',
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.GET_USERS],
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/users/logout',
        method: 'POST',
      }),
    }),

    getCurrentUser: builder.query<CurrentUserResponseType, void>({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
    }),

    changePassword: builder.mutation<
      { message: string },
      { currentPassword: string; newPassword: string; confirmNewPassword: string }
    >({
      query: (body) => ({
        url: '/users/change-password',
        method: 'PUT',
        body,
      }),
    }),

    inviteUser: builder.mutation<
      { email: string; role: USER_ROLES; key: string },
      { email: string; role: USER_ROLES; carrier?: string }
    >({
      query: (body) => ({
        url: '/users/invite',
        method: 'POST',
        body,
      }),
    }),
    getOperators: builder.query<CurrentUserType[], void>({
      query: () => ({
        url: '/users/operators',
        method: 'GET',
      }),
    }),
    changeActiveStatus: builder.mutation<CurrentUserType, { id: string; active: boolean }>({
      query: (body) => ({
        url: '/users/change-active-status',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_USERS],
    }),
    deleteUser: builder.mutation<CurrentUserType, { id: string }>({
      query: ({ id }) => ({
        url: `/users/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_USERS],
    }),
  }),
});

export const loginEndpoint = userManagementApiService.endpoints.login;

export const {
  useLoginMutation,
  useLazyGetAllUserQuery,
  useLazyGetCurrentUserQuery,
  useLogoutMutation,
  useChangePasswordMutation,
  useInviteUserMutation,
  useRegisterMutation,
  useLazyGetOperatorsQuery,
  useChangeActiveStatusMutation,
  useDeleteUserMutation,
} = userManagementApiService;
