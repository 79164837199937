import { forwardRef, memo } from 'react';

import { Input as InputAntd, InputProps as InputPropsAntd, InputRef } from 'antd';
import 'antd/lib/input/style/css';
import cn from 'classnames';

import Icon, { TIconsLiteral } from '../icon';
import s from './Input.module.scss';

export interface InputProps extends Omit<InputPropsAntd, 'size'> {
  name: string;
  icon?: TIconsLiteral;
  label?: string;
  iconColor?: string;
  size?: 'small' | 'middle' | 'large';
  className?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
}

const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  const {
    value,
    icon,
    label,
    iconColor,
    size = 'middle',
    disabled = false,
    name,
    placeholder,
    fullWidth = false,
    onChange,
    className = '',
    isLoading = false,
    ...otherProps
  } = props;
  return (
    <div className={cn(s.container, { [s.disabled]: disabled, [s.fullWidth]: fullWidth })}>
      <label className={s.wrapper} htmlFor={name}>
        {label && <div className={s.label}>{label}</div>}

        <InputAntd
          ref={ref}
          className={cn(s.input, s[size], {
            [className]: className,
            [s.inputIcon]: icon,
          })}
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          id={name}
          {...otherProps}
        />
        {icon && <Icon className={s.icon} color={iconColor} name={icon} size={24} />}
        {isLoading && <Icon className={s.loader} name="loader" />}
      </label>
    </div>
  );
});

export default memo(Input);
