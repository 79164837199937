import { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { BOOKING_ROUTE } from 'routes/core/list';
import { Button, Tabs } from 'ui/index';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import ContraBusBookingsList from 'pages/tickets-list/contents/contra-bus';
import KlrBookingsList from 'pages/tickets-list/contents/klr';
import { TICKETS_LIST_TABS, TICKETS_TABS_CONFIGURATION } from 'pages/tickets-list/ticketList.utils';

import ComponentWithContainer from 'components/componentWithContainer';

import TopTicketBookingsList from './contents/top-ticket';

const TicketList: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(TICKETS_LIST_TABS.TOP_TICKET);
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const navigate = useNavigate();

  const content = {
    [TICKETS_LIST_TABS.TOP_TICKET]: <TopTicketBookingsList />,
    ...(['ADMIN', 'OPERATOR'].includes(String(currentUser?.roles)) && {
      [TICKETS_LIST_TABS.KLR]: <KlrBookingsList />,
      [TICKETS_LIST_TABS.CONTRA_BUS]: <ContraBusBookingsList />,
    }),
  };
  const handleGoToBooking = () => {
    navigate(BOOKING_ROUTE);
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const createNewRoute = (
    <>
      <Button onClick={handleGoToBooking}>Забронювати</Button>
    </>
  );

  return (
    <ComponentWithContainer title="Список квитків" actions={createNewRoute} smallPadding>
      <Tabs
        items={TICKETS_TABS_CONFIGURATION(currentUser?.roles)}
        activeKey={activeTab}
        animated={true}
        onTabClick={setActiveTab}
      />
      <div>{content[activeTab]}</div>
    </ComponentWithContainer>
  );
};

export default TicketList;
