import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/user-management/list';
import { Button, Form, HintPassword, Input, Label, Modal, Typography } from 'ui';

import {
  useChangePasswordMutation,
  useLogoutMutation,
} from 'services/user-management/userManagementApiService';
import { logout } from 'slices/userSlice';
import { useAppDispatch } from 'store/index';

import s from './ChangePasswordModal.module.scss';

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}
const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const newPassword = Form.useWatch('newPassword', form);
  const repeatPassword = Form.useWatch('confirmNewPassword', form);
  const checkNewPassword =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[`~!@#$%^&*()_=+])[a-zA-Z\d`~!@#$%^&*()_=+]{10,}$/.test(newPassword);
  const validPassword = !repeatPassword?.lenght && newPassword === repeatPassword;
  const disabledSubmit = !validPassword || !checkNewPassword;
  const [changePassword] = useChangePasswordMutation();
  const [logoutUser] = useLogoutMutation();

  const handleSubmit = async (data) => {
    const res = await changePassword(data);

    if ('data' in res) {
      logoutUser().then((data) => {
        if (data) {
          navigate(LOGIN_ROUTE);
          dispatch(logout());
        }
      });
    }
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <Modal.Header>
        <Typography type="h4">Зміна паролю</Typography>
      </Modal.Header>
      <Modal.Content>
        <Typography type="main" weight={600}>
          Після того як ви збережете зміни вам потрібно буде повторно увійти в систему
        </Typography>

        <Form className={s.form} form={form} onFinish={handleSubmit}>
          <div>
            <Label>Старий пароль</Label>
            <Form.Item name="currentPassword">
              <Input.Password name="oldPassword" type="password" placeholder="Старий пароль" />
            </Form.Item>
          </div>
          <div>
            <Label>Новий пароль</Label>
            <Form.Item name="newPassword">
              <Input.Password name="newPassword" type="password" placeholder="Новий пароль" />
            </Form.Item>

            <div className={s.hint}>
              <HintPassword field="length" value={newPassword}>
                10 characters
              </HintPassword>

              <HintPassword field="upperCase" value={newPassword}>
                Uppercase
              </HintPassword>

              <HintPassword field="number" value={newPassword}>
                Number
              </HintPassword>

              <HintPassword field="symbol" value={newPassword}>
                Symbol
              </HintPassword>
            </div>
          </div>
          <div>
            <Label>Повторіть новий пароль</Label>
            <Form.Item name="confirmNewPassword">
              <Input.Password
                name="repeatNewPassword"
                type="password"
                placeholder="Повторіть новий пароль"
              />
            </Form.Item>
            {!validPassword && (
              <Typography type="small" color="red">
                Паролі повинні бути однакові
              </Typography>
            )}
          </div>

          <Button htmlType="submit" disabled={disabledSubmit}>
            Змінити
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ChangePasswordModal;
