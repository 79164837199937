import { FC, useEffect, useState } from 'react';

import { Tabs } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import ComponentWithContainer from 'components/componentWithContainer';

import { AgentsSetting, ApiKeysSetting, ProfileSetting } from './user-setting-content';
import { SETTING_TABS_ENUM, getSettingTabsConfiguration } from './userSetting.utils';

const UserSettings: FC = () => {
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [activeTab, setActiveTab] = useState<string>(SETTING_TABS_ENUM.PROFILE);
  const content = {
    [SETTING_TABS_ENUM.PROFILE]: <ProfileSetting />,
    [SETTING_TABS_ENUM.API_KEYS]: <ApiKeysSetting />,
    [SETTING_TABS_ENUM.AGENTS]: <AgentsSetting />,
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <ComponentWithContainer title="Налаштування">
      <Tabs
        items={getSettingTabsConfiguration(currentUser?.roles)}
        activeKey={activeTab}
        animated={true}
        onTabClick={setActiveTab}
      />
      <div>{content[activeTab]}</div>
    </ComponentWithContainer>
  );
};

export default UserSettings;
