import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';

import {
  CarrierCreateType,
  CarrierTypes,
  CarrierWithRoutesType,
  ContraBusCarrierInfoType,
} from 'types/carriers/carriersTypes';

const carriersApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getCarriers: builder.query<CarrierTypes[], void>({
      query: () => '/carriers',
      providesTags: [SERVICES_TAGS.GET_CARRIERS],
    }),
    getCarrierById: builder.query<CarrierWithRoutesType, string>({
      query: (id) => `/carriers/${id}`,
    }),
    updateCarrier: builder.mutation<CarrierWithRoutesType, { id: string } & CarrierCreateType>({
      query: ({ id, ...data }) => ({
        url: `/carriers/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_CARRIERS],
    }),
    createCarrier: builder.mutation<CarrierTypes, CarrierCreateType>({
      query: (data) => ({
        url: '/carriers/add',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_CARRIERS],
    }),
    deleteCarrier: builder.mutation<CarrierTypes, string>({
      query: (id) => ({
        url: `/carriers/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_CARRIERS],
    }),
    getContraBusCarrierInfo: builder.query<
      ContraBusCarrierInfoType[],
      { data_bundle: string; carrier_name }
    >({
      query: (body) => ({
        url: `/info/contrabus/carrier/details`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCarriersQuery,
  useCreateCarrierMutation,
  useUpdateCarrierMutation,
  useDeleteCarrierMutation,
  useLazyGetCarrierByIdQuery,
  useLazyGetContraBusCarrierInfoQuery,
} = carriersApiService;
