import { FC } from 'react';

import TextAreaAntd, { TextAreaProps as TextAreaPropsAntd } from 'antd/lib/input/TextArea';
import 'antd/lib/input/style/css';
import cn from 'classnames';

import sInput from '../input/Input.module.scss';
import s from './Textarea.module.scss';

export interface TextareaProps extends TextAreaPropsAntd {
  name: string;
  label?: string;
  fullWidth?: boolean;
}

const Textarea: FC<TextareaProps> = (props) => {
  const {
    name,
    label,
    disabled = false,
    showCount = true,
    className = '',
    fullWidth = false,
    ...otherProps
  } = props;
  return (
    <div
      className={cn(sInput.container, {
        [sInput.disabled]: disabled,
        [sInput.fullWidth]: fullWidth,
      })}>
      <label className={sInput.wrapper} htmlFor={name}>
        {label && <div className={sInput.label}>{label}</div>}
        <TextAreaAntd
          showCount={showCount}
          className={cn(s.textArea, { [className]: className })}
          {...otherProps}
        />
      </label>
    </div>
  );
};

export default Textarea;
