import { FC, useEffect, useState } from 'react';

import { Button, Icon, Table } from 'ui';

import {
  useDeleteDiscountMutation,
  useLazyGetAllDiscountsQuery,
} from 'services/discounts/discountsApiService';

import CreateNewDiscountModal from 'pages/sales/create-new-discount-modal';
import EditDiscountModal from 'pages/sales/edit-discount-modal';

import ComponentWithContainer from 'components/componentWithContainer';

import { DiscountType } from 'types/discounts/discountsType';

import s from './Sales.module.scss';

const Sales: FC = () => {
  const [getAllDiscounts, { data: discountsList }] = useLazyGetAllDiscountsQuery();
  const [isOpenedCreateModal, setIsOpenedCreateModal] = useState(false);
  const [editingDiscount, setEditingDiscount] = useState<DiscountType>();
  const [deleteDiscount] = useDeleteDiscountMutation();
  const createNewDiscount = (
    <>
      <Button onClick={() => setIsOpenedCreateModal(true)}>Створити нову знижку</Button>
    </>
  );

  const handleDeleteDiscount = async (id: string) => {
    const res = await deleteDiscount(id);

    if ('data' in res) {
      console.log(res.data);
    }
  };

  useEffect(() => {
    getAllDiscounts();
  }, [getAllDiscounts]);

  const columns = [
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Знижка',
      dataIndex: 'value',
      key: 'value',
      render: (value: string) => `${value}%`,
    },
    {
      title: 'Дії',
      key: 'actions',
      render: (data) => {
        return (
          <div className={s.actions}>
            <button className={s.action} onClick={() => setEditingDiscount(data)}>
              <Icon name="edit" size={26} />
            </button>
            <button className={s.action} onClick={() => handleDeleteDiscount(data.id)}>
              <Icon name="trashBox" size={24} />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <ComponentWithContainer title="Знижки" actions={createNewDiscount}>
      <Table dataSource={discountsList} columns={columns} rowKey="id" />
      <CreateNewDiscountModal open={isOpenedCreateModal} onClose={setIsOpenedCreateModal} />
      {editingDiscount && <EditDiscountModal open={editingDiscount} onClose={setEditingDiscount} />}
    </ComponentWithContainer>
  );
};

export default Sales;
