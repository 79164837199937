import { FC } from 'react';

import { InputNumber, InputNumberProps } from 'antd';
import 'antd/lib/input-number/style/css';
import cn from 'classnames';

import s from './NumericInput.module.scss';

interface NumericInputProps extends InputNumberProps {
  currencyMask?: boolean;
}
const NumericInput: FC<NumericInputProps> = (props) => {
  const { currencyMask, ...rest } = props;
  return (
    <div className={s.wrapper}>
      {currencyMask && <div className={s.mask}>€</div>}
      <InputNumber className={cn(s.input, { [s.maskWrapper]: currencyMask })} {...rest} />
    </div>
  );
};

export default NumericInput;
