export const getDate = (date?: string, time?: string) => {
  // дата у форматі "dd/mm/yyyy"
  const parts = date?.split('.'); // розбиваємо дату на складові

  if (parts) {
    const year = parts[2];
    const month = parts[1].padStart(2, '0'); // додаємо "0" до місяця, якщо він однозначний
    const day = parts[0].padStart(2, '0'); // додаємо "0" до дня, якщо він однозначний
    return `${year}-${month}-${day} ${time}:00`;
  }
};
