import { Layout, Space } from 'antd';
import { getIsAuth } from 'tools/cookieTools';

// import { Button } from 'ui';
import { isLoggedInSelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store/index';

import AppHeader from 'containers/app-header';
import AppNavigation from 'containers/app-navigation';

import s from './App.module.scss';
import Pages from './pages';

function App() {
  const isAuth = getIsAuth();
  const isLogged = useAppSelector(isLoggedInSelector);
  const auth = isAuth || isLogged;

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        {auth && <AppHeader />}
        <Layout>
          <div className={s.contentWrapper}>
            {auth && <AppNavigation />}
            <Layout.Content className={s.content}>
              <Pages />
            </Layout.Content>
          </div>
        </Layout>
      </Layout>
    </Space>
  );
}

export default App;
