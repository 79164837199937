import { FC, PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { Typography } from 'ui/index';

import s from './ComponentWithContainer.module.scss';

interface ComponentWithContainerProps {
  title: string;
  actions?: ReactElement;
  smallPadding?: boolean;
  bordered?: boolean;
}
const ComponentWithContainer: FC<PropsWithChildren<ComponentWithContainerProps>> = ({
  children,
  title,
  actions,
  smallPadding = false,
  bordered = true,
}) => {
  return (
    <div className={cn(s.wrapper, { [s.smallPadding]: smallPadding })}>
      <div className={cn(s.title, { [s.bordered]: bordered })}>
        <Typography type="h3">{title}</Typography>
        {actions && <div className={s.actions}>{actions}</div>}
      </div>
      {children}
    </div>
  );
};

export default ComponentWithContainer;
