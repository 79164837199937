import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { REGISTER_ROUTE } from 'routes/user-management/list';
import { Button, Form, Icon, Input, Label, Typography } from 'ui';

import { useLoginMutation } from 'services/user-management/userManagementApiService';

import s from './Login.module.scss';

const Login: FC = () => {
  const [login, { isLoading }] = useLoginMutation();
  const navigation = useNavigate();
  const handleSubmit = async (data) => {
    const res = await login(data);
    if ('data' in res) {
      // setTokenCookies(res?.data?.accessToken, res?.data?.refreshToken);
    }
  };

  const goToRegister = () => {
    navigation(REGISTER_ROUTE);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <Icon name="logo" height={34} width={150} />
        </div>
        <div>
          <Button onClick={goToRegister}>Регістрація</Button>
        </div>
      </div>
      <div className={s.content}>
        <Form className={s.form} onFinish={handleSubmit}>
          <Typography type="h2">Логін</Typography>
          <div className={s.field}>
            <Label required>Емейл</Label>
            <Form.Item
              name="email"
              fullWidth
              rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input name="email" fullWidth />
            </Form.Item>
          </div>

          <div className={s.field}>
            <Label required>Пароль</Label>
            <Form.Item
              name="password"
              fullWidth
              rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input.Password name="password" fullWidth />
            </Form.Item>
          </div>

          <Button fullWidth htmlType="submit" isLoading={isLoading}>
            Увійти
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
