import { FC } from 'react';

import cn from 'classnames';
import { Icon, Tooltip, Typography } from 'ui/index';

import { SERVICES_TOOLTIP } from 'pages/booking/booking-list/bookingList.utils';
import s from 'pages/booking/booking-list/klr-item/KlrItem.module.scss';
import { CARRIERS_SERVICE_TYPES } from 'pages/booking/booking-list/topTicket-item/topTicketItem.utils';

interface RouteServicesProps {
  setOpenScheduleModal: (state: boolean) => void;
  handleGetServices: () => void;
  isShowServices: boolean;
  services: { id: string; service: string }[];
}

const RouteServices: FC<RouteServicesProps> = ({
  setOpenScheduleModal,
  handleGetServices,
  isShowServices,
  services,
}) => {
  // Write your component code here

  return (
    <div>
      <div className={cn(s.actions)}>
        <button className={s.action} onClick={() => setOpenScheduleModal(true)}>
          <Typography type="small" className={s.actionText}>
            Маршрут
          </Typography>
        </button>
        <button className={s.action} onClick={handleGetServices}>
          <Typography type="small" className={s.actionText}>
            Зручності
          </Typography>
        </button>
      </div>
      <div>
        {!!services.length && isShowServices && (
          <div className={s.service}>
            {services?.map((service) => (
              <Tooltip
                key={service.id}
                title={SERVICES_TOOLTIP[CARRIERS_SERVICE_TYPES[service.service]]}>
                <div>
                  <Icon name={CARRIERS_SERVICE_TYPES[service.service]} size={26} />
                </div>
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RouteServices;
