import { FC } from 'react';

import { Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getFullInfoRoute } from 'routes/core/list';
import { Icon, Table } from 'ui';

import { CarrierService, CarrierTypes } from 'types/carriers/carriersTypes';

import s from './CarriersList.module.scss';

interface CarriersListProps {
  list?: CarrierTypes[];
  fetching: boolean;
}
const CarriersList: FC<CarriersListProps> = (props) => {
  const { list, fetching } = props;
  const navigate = useNavigate();

  const carrierColumns = [
    {
      title: 'Логотип',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo: string) =>
        logo ? (
          <img src={`${logo}`} alt="logo" className={s.logo} />
        ) : (
          <div className={s.emptyLogo}>
            <Icon name="logoSmall" />
          </div>
        ),
    },
    {
      title: 'Назва перевізника',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Параметрі багажу',
      dataIndex: 'baggageAllowance',
      key: 'baggageAllowance',
    },
    {
      title: 'Кількість маршрутів',
      dataIndex: 'routesCount',
      key: 'routesCount',
      width: 200,
    },
    {
      title: 'Сервіси',
      dataIndex: 'services',
      key: 'services',
      render: (services: CarrierService[]) => (
        <div className={s.sevices}>
          {services.map((service, i) => (
            <Tag key={`${service.id}.${i}`} color="#256eff" className={s.tag}>
              {service.name}
            </Tag>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        {list && (
          <Table
            dataSource={list}
            columns={carrierColumns}
            rowKey="id"
            loading={fetching}
            scroll={{ x: 600 }}
            onRow={(record) => {
              return {
                onClick: () => navigate(getFullInfoRoute(record.id)),
              };
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CarriersList;
