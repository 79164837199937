import { FC, useCallback, useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Button, DatePicker, Form, Label, Select, Table, Typography } from 'ui';

import { useLazyGetReportQuery } from 'services/bookings/bookingsApiService';
import {
  useLazyGetCurrentUserQuery,
  useLazyGetOperatorsQuery,
} from 'services/user-management/userManagementApiService';

import ComponentWithContainer from 'components/componentWithContainer';
import Pagination from 'components/pagination';

import { TopTicketBookingType } from 'types/bookings/bookingsTypes';
import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

import s from './Reports.module.scss';
import { ReportsFilterType, downloadFile, initialFilter } from './reports.utils';

const Reports: FC = () => {
  const [form] = Form.useForm();
  const [getOperators, { data: operators }] = useLazyGetOperatorsQuery();
  const [getReports, { data: reports, isFetching }] = useLazyGetReportQuery();
  const [getCurrentUser, { data: user }] = useLazyGetCurrentUserQuery();
  const [requestFilter, setRequestFilter] = useState<ReportsFilterType>(initialFilter);

  const handleChangeForm = (
    _,
    all: { userId: { value: string }; startDate: Dayjs; endDate: Dayjs },
  ) => {
    if (all.userId) {
      setRequestFilter((prevState) => ({ ...prevState, userId: all.userId.value }));
    }
    if (all.startDate) {
      setRequestFilter((prevState) => ({
        ...prevState,
        startDate: all.startDate.format('YYYY-MM-DD'),
      }));
    } else {
      setRequestFilter((prevState) => ({ ...prevState, startDate: '' }));
    }
    if (all.endDate) {
      setRequestFilter((prevState) => ({
        ...prevState,
        endDate: all.endDate.format('YYYY-MM-DD'),
      }));
    } else {
      setRequestFilter((prevState) => ({ ...prevState, endDate: '' }));
    }
  };

  const columns = [
    {
      title: 'Перевізник, Маршрут',
      dataIndex: 'route',
      key: 'route',
      render: (route: FullRouteType) => {
        return <div>{`${route.carrier?.name}, ${route?.routeName}`}</div>;
      },
    },
    {
      title: 'Дата',
      dataIndex: 'tripDate',
      key: 'tripDate',
      render: (tripDate: string, row: TopTicketBookingType) => {
        const preparedDate = dayjs(tripDate).format('YYYY-MM-DD');
        const resultDate = dayjs(`${preparedDate} ${row.route.departureTime}`).format(
          'DD MMMM HH:mm',
        );

        return <div>{resultDate}</div>;
      },
    },
    {
      title: 'Пасажир',
      key: 'passenger',
      render: (row) => <div>{`${row.firstName} ${row.lastName}`}</div>,
    },
    {
      title: 'Контакти',
      dataIndex: 'phone',
    },
    {
      title: 'Ціна',
      dataIndex: 'route',
      key: 'price',
      render: (route: FullRouteType) => (
        <div>
          {route.price.value} {route.price.currency}
        </div>
      ),
    },
    {
      title: 'Статус',
    },
    {
      title: 'Коментарі',
      dataIndex: 'comment',
    },
  ];

  const handleDownloadCsv = useCallback(() => {
    const userId =
      user?.roles === 'AGENT' ? user.id : requestFilter?.userId ? requestFilter.userId : '';
    const searchString = `userId=${userId}&from=${requestFilter?.startDate || ''}&to=${
      requestFilter?.endDate || ''
    }&userCarrier=${user?.carrierId || ''}`;
    downloadFile(
      `${process.env.REACT_APP_BACKEND_BASE_URL}bookings/report/csv?${searchString}`,
      `звітність-${dayjs().format('DD.MM.YYYY')}.xlsx`,
    );
  }, [requestFilter?.endDate, requestFilter?.startDate, requestFilter?.userId, user]);

  const handleClearFilter = () => {
    form.resetFields();
    setRequestFilter(initialFilter);
  };

  const showedElements = Number(reports?.data?.length) + (Number(reports?.currentPage) - 1) * 10;

  const handleChangePage = (page: number) => {
    setRequestFilter((prevValue) => ({ ...prevValue, page }));
  };

  useEffect(() => {
    getOperators();
    getCurrentUser();
  }, [getCurrentUser, getOperators]);

  useEffect(() => {
    if (requestFilter && user?.id) {
      if (user?.roles === 'AGENT') {
        getReports({ ...requestFilter, userId: user.id });
      } else {
        getReports(requestFilter);
      }
    }
  }, [getReports, requestFilter, user]);

  return (
    <ComponentWithContainer
      title="Звітність"
      actions={<Button onClick={handleDownloadCsv}>Завантажити csv</Button>}>
      <div className={s.header}>
        <Form className={s.filter} onValuesChange={handleChangeForm} form={form}>
          {user?.roles !== 'AGENT' && (
            <div>
              <Label>Опeратор</Label>
              <Form.Item name="userId">
                <Select
                  options={operators?.map((operator) => ({
                    value: operator.id,
                    label: `${operator.firstName} ${operator.lastName}`,
                  }))}
                  placeholder="Опeратор"
                />
              </Form.Item>
            </div>
          )}
          <div className={s.date}>
            <Label>Початкова Дата</Label>
            <Form.Item name="startDate">
              <DatePicker name="startDate" placeholder="Виберіть дату" fullWidth />
            </Form.Item>
          </div>
          <div className={s.date}>
            <Label>Кінцева Дата</Label>
            <Form.Item name="endDate">
              <DatePicker name="endDate" placeholder="Виберіть дату" fullWidth />
            </Form.Item>
          </div>

          <Button type="primary" htmlType="button" onClick={handleClearFilter}>
            Очистити
          </Button>
        </Form>

        <div>
          <Typography type="h3">За місяць {reports?.month}</Typography>
          <Typography type="h4">За день {reports?.today}</Typography>
        </div>
      </div>

      <Table
        dataSource={reports?.data}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={isFetching}
        scroll={{ x: 600 }}
      />

      <Pagination
        showedElements={showedElements}
        handlePageChange={handleChangePage}
        total={reports?.totalPages}
        totalElements={reports?.totalCount}
        currentPage={Number(reports?.currentPage)}
      />
    </ComponentWithContainer>
  );
};

export default Reports;
