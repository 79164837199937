import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';
import { Typography } from 'ui/index';

import s from './Label.module.scss';

interface LabelProps {
  className?: string;
  required?: boolean;
  mode?: 'dark' | 'light';
}

const Label: FC<PropsWithChildren<LabelProps>> = (props) => {
  const { className = '', required, children, mode } = props;
  return (
    <Typography
      type="small"
      className={cn(s.label, {
        [s.required]: required,
        [className]: className,
        [s.dark]: mode === 'dark',
      })}>
      {children}
    </Typography>
  );
};

export default Label;
