import { Navigate } from 'react-router-dom';

import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import Booking from 'pages/booking';
import BookingCreate from 'pages/booking/booking-create';
import CarrierComfort from 'pages/carriers';
import CarriersFullInfo from 'pages/carriers/carriers-full-info';
import CreateNewCarrier from 'pages/carriers/create-new-carrier';
import Reports from 'pages/reports';
import RoutesList from 'pages/routes-list';
import RoutesCreateForm from 'pages/routes-list/routes-create-form';
import Sales from 'pages/sales';
import TicketsList from 'pages/tickets-list';
import EditBooking from 'pages/tickets-list/contents/edit-booking';
import UserSettings from 'pages/user-settings';

import { RouteType } from 'types/core/routeTypes';

import {
  BOOKING_ROUTE,
  CARRIER_COMFORT,
  CREATE_BOOKING_ROUTE,
  CREATE_CARRIER_ROUTE,
  EDIT_TICKET_ROUTE,
  FULL_CARRIER_INFO,
  REPORTS_ROUTE,
  SALES_ROUTE,
  TICKETS_ROUTE,
} from './core/list';
import { ROUTE_CREATE_ROUTE, ROUTE_EDIT_ROUTE, ROUTE_LIST_ROUTE } from './routes-list/list';
import { LOGIN_ROUTE, REGISTER_ROUTE, USER_SETTING_ROUTE } from './user-management/list';

export const HOME_NON_AUTH_ROUTE = LOGIN_ROUTE;
export const UNKNOWN_ROUTE = '*';

export const AUTH_ROUTES: RouteType[] = [
  { path: CREATE_CARRIER_ROUTE, element: <CreateNewCarrier /> },
  { path: FULL_CARRIER_INFO, element: <CarriersFullInfo /> },
  { path: CARRIER_COMFORT, element: <CarrierComfort /> },
  { path: BOOKING_ROUTE, element: <Booking /> },
  { path: CREATE_BOOKING_ROUTE, element: <BookingCreate /> },
  { path: REPORTS_ROUTE, element: <Reports /> },
  { path: ROUTE_LIST_ROUTE, element: <RoutesList /> },
  { path: ROUTE_CREATE_ROUTE, element: <RoutesCreateForm /> },
  { path: ROUTE_EDIT_ROUTE, element: <RoutesCreateForm /> },
  { path: SALES_ROUTE, element: <Sales /> },
  { path: TICKETS_ROUTE, element: <TicketsList /> },
  { path: EDIT_TICKET_ROUTE, element: <EditBooking /> },
  { path: USER_SETTING_ROUTE, element: <UserSettings /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={BOOKING_ROUTE} /> },
];

export const NON_AUTH_ROUTES: RouteType[] = [
  { path: LOGIN_ROUTE, element: <Login /> },
  { path: REGISTER_ROUTE, element: <Register /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_NON_AUTH_ROUTE} /> },
];
