import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';
import { GetAllRoutesType, GetContraBusRouteInfoType } from 'services/routes/routes.api.type';

import {
  ContraBusRoutesSearchType,
  KlrRoutesSearchType,
  KlrRoutesType,
  RouteType,
} from 'types/routes/routesTypes';
import { CreationRouteType, FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

const routesService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    searchContraBusRoutes: builder.query<RouteType[], ContraBusRoutesSearchType>({
      query: (body) => ({
        url: '/info/routes/contrabus',
        method: 'post',
        body,
      }),
    }),
    searchKlrRoutes: builder.query<KlrRoutesType, KlrRoutesSearchType>({
      query: (body) => ({
        url: '/info/routes/klr',
        method: 'post',
        body,
      }),
    }),
    getContraBusRouteInfo: builder.query<GetContraBusRouteInfoType, { data_bundle: string }>({
      query: (body) => ({
        url: `/info/contrabus/route/details`,
        method: 'POST',
        body,
      }),
    }),
    searchTopTicketsRoutes: builder.query<
      FullRouteType[],
      { fromCity: string; toCity: string; tripDate: string }
    >({
      query: (params) => ({
        url: 'routes/find',
        method: 'GET',
        params,
      }),
    }),
    getAllRoutes: builder.query<FullRouteType[], void>({
      query: () => ({
        url: '/routes',
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    getCarrierRoutes: builder.query<GetAllRoutesType, string>({
      query: (id) => ({
        url: `/routes/carrier/${id}`,
        method: 'GET',
      }),
    }),
    createRoute: builder.mutation<GetAllRoutesType, CreationRouteType>({
      query: (body) => ({
        url: '/routes/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    editRoute: builder.mutation<GetAllRoutesType, CreationRouteType & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/routes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    deleteRoute: builder.mutation<void, string>({
      query: (id) => ({
        url: `/routes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    getCarrierRouteById: builder.query<CreationRouteType, string>({
      query: (id) => ({
        url: `/routes/${id}`,
        method: 'GET',
      }),
    }),
    getKlrRuteDetails: builder.query<GetAllRoutesType, string>({
      query: (id) => ({
        url: `info/klr/route/details`,
        method: 'POST',
        body: {
          route_id: id,
        },
      }),
    }),
    updatePopularStatus: builder.mutation<void, { id: string; status: string }>({
      query: ({ id, status }) => ({
        url: `routes/${id}/popular/${status}`,
        method: 'PATCH',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    updateRecommendedStatus: builder.mutation<void, { id: string; status: string }>({
      query: ({ id, status }) => ({
        url: `routes/${id}/recommended/${status}`,
        method: 'PATCH',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    addSales: builder.mutation<void, { id: string; sale: { value: number; name: string } }>({
      query: (body) => ({
        url: `/routes/add-sale`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
    removeSales: builder.mutation<void, { id: string }>({
      query: (body) => ({
        url: `/routes/remove-sale`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_ROUTES],
    }),
  }),
});

export const {
  useLazySearchContraBusRoutesQuery,
  useLazySearchKlrRoutesQuery,
  useCreateRouteMutation,
  useLazyGetAllRoutesQuery,
  useLazyGetCarrierRoutesQuery,
  useLazySearchTopTicketsRoutesQuery,
  useDeleteRouteMutation,
  useLazyGetContraBusRouteInfoQuery,
  useUpdatePopularStatusMutation,
  useUpdateRecommendedStatusMutation,
  useLazyGetKlrRuteDetailsQuery,
  useLazyGetCarrierRouteByIdQuery,
  useEditRouteMutation,
  useAddSalesMutation,
  useRemoveSalesMutation,
} = routesService;
