import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Form,
  FormInstance,
  Icon,
  Input,
  Label,
  Select,
  Switch,
  TextArea,
  Typography,
} from 'ui';
import { formRules } from 'utils/formRules';

import { CarrierTypes } from 'types/carriers/carriersTypes';
import { RouteForBookingType } from 'types/routes/routesTypes';
import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

import { PassengerType } from '../../bookingCreateForm';
import s from './BookingCreateFormFirstStep.module.scss';

interface BookingCreateFormFirstStepProps {
  handleSubmit: () => void;
  form: FormInstance;
  passengers: PassengerType;
  setPassengers: Dispatch<SetStateAction<PassengerType>>;
  route: RouteForBookingType;
  isLoading: boolean;
}

const BookingCreateFormFirstStep: FC<BookingCreateFormFirstStepProps> = (props) => {
  const { handleSubmit, form, passengers, setPassengers, route, isLoading } = props;
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isAdded, setIsAdded] = useState(false);
  const [isMoreOption, setIsMoreOption] = useState(false);
  const [carrier, setCarrier] = useState<CarrierTypes>();

  const handleEdit = (passenger, index) => {
    form.setFieldsValue(passenger);
    setEditIndex(index);
  };

  const handleAddPassenger = useCallback(() => {
    setIsAdded(true);
    form
      .validateFields()
      .then((data) => {
        const ticketType = data?.ticketType?.label || 'full';
        if (editIndex !== null) {
          setPassengers((prevState) => {
            const updatedPassengers = [...prevState];
            updatedPassengers[editIndex] = { ...data, ticketType };
            return updatedPassengers;
          });
        } else {
          setPassengers((prevState) => [...prevState, { ...data, ticketType }]);
        }
      })
      .then(() => {
        form.resetFields();
        setEditIndex(null);
        setIsAdded(false);
      });
  }, [editIndex, form, setPassengers]);

  useEffect(() => {
    if (route.owner === 'topTicket') {
      const res = route.route as FullRouteType;
      setCarrier(res.carrier as CarrierTypes);
    }
  }, [route.owner, route.route]);

  const getDiscountOptions = useCallback((): { label: string; value: number }[] => {
    if (carrier?.discounts) {
      return [{ label: 'Повний', value: 100 }, ...carrier.discounts];
    }

    return [{ label: 'Повний', value: 100 }];
  }, [carrier]);

  return (
    <div className={s.content}>
      <Form className={s.form} onFinish={handleSubmit} form={form}>
        <div>
          <Label required>{`Ім'я`}</Label>
          <Form.Item
            name="firstName"
            rules={[passengers?.length === 0 || isAdded ? formRules.required : {}]}>
            <Input name="firsName" placeholder="Введіть ім'я" />
          </Form.Item>
        </div>
        <div>
          <Label required>Прізвище</Label>
          <Form.Item
            name="lastName"
            rules={[passengers?.length === 0 || isAdded ? formRules.required : {}]}>
            <Input name="lastName" placeholder="Введіть прізвище" />
          </Form.Item>
        </div>
        <div>
          <Label required>Номер телефону</Label>
          <Form.Item
            name="phone"
            rules={[passengers?.length === 0 || isAdded ? formRules.required : {}]}>
            <Input name="phoneNumber" placeholder="Введіть номер телефону" />
          </Form.Item>
        </div>

        <div className={s.switchBlock}>
          <Switch value={isMoreOption} onChange={setIsMoreOption} />
          <Typography type="main" weight={600}>
            Додаткові Поля
          </Typography>
        </div>

        {isMoreOption && (
          <div className={s.moreOptions}>
            <div>
              <Label>Тип квитка</Label>
              <Form.Item name="ticketType" initialValue={{ value: 'full', label: 'Повний' }}>
                <Select options={getDiscountOptions()} />
              </Form.Item>
            </div>
            <div>
              <Label>Коментар</Label>
              <Form.Item name="comment">
                <TextArea name="comment" placeholder="Введіть коментар" maxLength={250} />
              </Form.Item>
            </div>
            <div>
              <Label>Знижка</Label>
              <Form.Item name="discount">
                <Input name="discount" placeholder="Введіть знижку в відсотках" />
              </Form.Item>
            </div>
          </div>
        )}

        <Button
          htmlType="button"
          variant="outline"
          color="grey"
          className={s.submit}
          onClick={handleAddPassenger}>
          <Icon name={editIndex === null ? 'plus' : 'checkWide'} size={24} />
          <Typography type="main" weight={500}>
            {editIndex === null ? 'Додати пасажира' : 'Змінити пасажира'}
          </Typography>
        </Button>
        <Button
          htmlType="submit"
          className={s.submit}
          disabled={!passengers.length}
          isLoading={isLoading}>
          Забронювати
        </Button>
      </Form>
      {!!passengers?.length && (
        <div className={s.passengersWrapper}>
          <Typography type="h5">Пасажири</Typography>
          <div className={s.passengers}>
            {passengers.map((passenger, index) => (
              <div key={index} className={s.passenger}>
                <div>
                  <Label>{`Ім'я`}</Label>
                  <div className={s.name}>
                    <Typography type="main" weight={500}>
                      {passenger.firstName}
                    </Typography>
                    <Typography type="main" weight={500}>
                      {passenger.lastName}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Label>Номер телефону</Label>
                  <Typography type="main" weight={500}>
                    {passenger.phone}
                  </Typography>
                </div>
                <div>
                  <Label>Коментар</Label>
                  <Typography type="main" weight={500}>
                    {passenger.comment || '-'}
                  </Typography>
                </div>
                <div>
                  <Label>Знижка</Label>
                  <Typography type="main" weight={500}>
                    {`${passenger?.discount || 0}%` || '-'}
                  </Typography>
                </div>
                <div>
                  <Label>Тип квитка</Label>
                  <Typography type="main" weight={500}>
                    {getDiscountOptions()?.find(
                      (discount) => discount.label === passenger.ticketType,
                    )?.label || '-'}
                  </Typography>
                </div>
                <div className={s.actions}>
                  <button className={s.action} onClick={() => handleEdit(passenger, index)}>
                    <Icon name="edit" size={24} />
                  </button>

                  <button
                    className={s.action}
                    onClick={() => setPassengers(passengers.filter((_, i) => i !== index))}>
                    <Icon name="delete" size={24} color="red" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCreateFormFirstStep;
