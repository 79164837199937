import { FC, useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_LIST_ROUTE } from 'routes/routes-list/list';
import ShortUniqueId from 'short-unique-id';
import { Breadcrumbs, Button, Form } from 'ui';

import { useLazyGetAllCitiesQuery } from 'services/cities/citiesApiService';
import {
  useCreateRouteMutation,
  useEditRouteMutation,
  useLazyGetCarrierRouteByIdQuery,
} from 'services/routes/routesService';

import ComponentWithContainer from 'components/componentWithContainer';

import s from './RoutesCreateForm.module.scss';
import RoutesCreateFormBaseInfo from './routes-create-form-base-info';
import RoutesCreateFormTripDetails from './routes-create-form-trip-details';
import { TripDetailsType, prepareRouteDate } from './routesCreateForm.utils';

const RoutesCreateForm: FC = () => {
  const { id } = useParams();
  const [getAllCities, { data: citiesList }] = useLazyGetAllCitiesQuery();
  const [formBaseInfo] = Form.useForm();
  const [formDetails] = Form.useForm();
  const [createRoute, { isLoading }] = useCreateRouteMutation();
  const [editRoute] = useEditRouteMutation();
  const [getRoute, { data: route }] = useLazyGetCarrierRouteByIdQuery();
  const [step, setStep] = useState<'baseInfo' | 'details'>('baseInfo');
  const [baseInfoData, setBaseInfoData] = useState();
  const [tripDetails, setTripDetails] = useState<TripDetailsType>({});
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);

  const BREADCRUMBS_ROUTES = [
    { name: 'Список маршрутів', path: ROUTE_LIST_ROUTE },
    { name: isEdit ? 'Редагування маршруту' : 'Створення маршруту' },
  ];

  const handleCreateRoute = useCallback(() => {
    if (baseInfoData && tripDetails && formDetails.getFieldsValue()) {
      const result = prepareRouteDate(
        baseInfoData,
        tripDetails,
        formDetails.getFieldsValue(),
        isEdit,
      );

      if (isEdit && id) {
        editRoute({ id: id, ...result });
      } else {
        createRoute(result);
      }
      formBaseInfo.resetFields();
      formDetails.resetFields();
      navigate(ROUTE_LIST_ROUTE);
    }
  }, [
    baseInfoData,
    createRoute,
    editRoute,
    formBaseInfo,
    formDetails,
    id,
    isEdit,
    navigate,
    tripDetails,
  ]);

  useEffect(() => {
    if (id) {
      getRoute(id);
      setIsEdit(true);
      getAllCities();
    }
  }, [getAllCities, getRoute, id]);

  useEffect(() => {
    if (isEdit && route) {
      const fromCity = citiesList?.find((city) => city.name === route?.fromCity);
      const toCity = citiesList?.find((city) => city.name === route?.toCity);

      formBaseInfo.setFieldsValue({
        routeName: route?.routeName,
        routeNumber: route?.routeNumber,
        carrier: route?.carrier,
        fromCity: fromCity,
        toCity: toCity,
        departureTime: dayjs(route?.departureTime, 'HH:mm'),
        arrivalTime: dayjs(route?.arrivalTime, 'HH:mm'),
        price: {
          value: route?.price?.value,
          currency: { value: route?.price?.currency },
        },
        fromStation: route?.fromStation,
        toStation: route?.toStation,
        freeChoiceOfPlaces: route?.freeChoiceOfPlaces,
        routeComment: route?.routeComment,
        prepayment: route?.prepayment,
      });

      formDetails.setFieldsValue({
        travelDuration: {
          days: route?.travelDuration?.days || 0,
          hours: route?.travelDuration?.hours || 0,
          mins: route?.travelDuration?.mins || 0,
        },
        seats: route.seats,
        tripDetails: {
          frequency: route?.tripDetails?.frequency?.weekDays?.map((item) => ({ value: item })),
        },
      });

      setTripDetails({ timetables: route.tripDetails?.timetable });
    }
  }, [citiesList, formBaseInfo, formDetails, isEdit, route]);

  useEffect(() => {
    if (!isEdit) {
      const uid = new ShortUniqueId({ length: 10 });

      formBaseInfo.setFieldValue('routeNumber', uid());
    }
  }, [formBaseInfo, isEdit]);

  return (
    <ComponentWithContainer title={isEdit ? 'Редагувати маршрут' : 'Створити маршрут'}>
      <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      {step === 'baseInfo' && (
        <Form
          className={s.form}
          form={formBaseInfo}
          onFinish={(data) => {
            setStep('details');
            setBaseInfoData(data);
          }}>
          <RoutesCreateFormBaseInfo />
        </Form>
      )}
      {step === 'details' && (
        <>
          <RoutesCreateFormTripDetails
            citiesList={citiesList}
            form={formDetails}
            setTripDetails={setTripDetails}
            baseInfo={baseInfoData}
            tripDetails={tripDetails}
            isEdit={isEdit}
          />

          <Button
            htmlType="button"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleCreateRoute}
            fullWidth
            style={{ marginTop: 32 }}>
            {isEdit ? 'Зберегти зміни' : 'Створити рейс'}
          </Button>
        </>
      )}
    </ComponentWithContainer>
  );
};

export default RoutesCreateForm;
