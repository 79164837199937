import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { Button, Form, Input, Label, Modal } from 'ui';
import { formRules } from 'utils/formRules';

import { useUpdateDiscountMutation } from 'services/discounts/discountsApiService';

import { DiscountType } from 'types/discounts/discountsType';

import s from './EditDiscountModal.module.scss';

interface CreateNewDiscountModalProps {
  open: DiscountType;
  onClose: Dispatch<SetStateAction<DiscountType | undefined>>;
}

const EditDiscountModal: FC<CreateNewDiscountModalProps> = (props) => {
  const { open, onClose } = props;
  const [form] = Form.useForm();
  const [updateDiscount, { isLoading }] = useUpdateDiscountMutation();

  const handleClose = () => {
    onClose(undefined);
  };

  const handleSubmit = useCallback(
    async (data) => {
      if (data) {
        const body = {
          ...data,
          id: open?.id,
        };
        const res = await updateDiscount(body);

        if ('data' in res) {
          onClose(undefined);
          form.resetFields();
        }
      }
    },
    [open?.id, updateDiscount, onClose, form],
  );

  return (
    <Modal open={!!open?.id} handleClose={handleClose} footer={false}>
      <Modal.Header>Створення Знижки</Modal.Header>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content>
          <div className={s.item}>
            <Label required>Назва</Label>
            <Form.Item name="name" initialValue={open?.name} fullWidth>
              <Input placeholder="Назва" name="name" disabled />
            </Form.Item>
          </div>
          <div className={s.item}>
            <Label required>Значення</Label>
            <Form.Item name="value" rules={[formRules.required]}>
              <Input placeholder="0.00" name="value" suffix="%" />
            </Form.Item>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={handleClose} variant="outline" color="grey" fullWidth>
            Закрити
          </Button>
          <Button fullWidth loading={isLoading} htmlType="submit">
            Створити
          </Button>
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default EditDiscountModal;
