import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Divider, InputRef, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CARRIER_COMFORT } from 'routes/core/list';
import { useQuery } from 'tools/hooks/useQuery';
import { Breadcrumbs, Button, Form, Input, Label, Select, Switch, TextArea, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import {
  useCreateCarrierMutation,
  useLazyGetCarrierByIdQuery,
  useUpdateCarrierMutation,
} from 'services/carriers/carriersApiService';

import { CARRIERS_SERVICES } from 'pages/carriers/carriers.utils';
import UploadLogo from 'pages/carriers/create-new-carrier/upload-logo';
import { discountSelectOptions } from 'pages/routes-list/routes-create-form/routes-create-form-trip-details/routesCreateFormTripDetails.utils';

import ComponentWithContainer from 'components/componentWithContainer';

import s from './CreateNewCarrier.module.scss';
import {
  getHoursRefund,
  refundPercentSelectOptions,
  refundTimeSelectOptions,
} from './createNewCarrier.utils';

const CreateNewCarrier: FC = () => {
  const [form] = Form.useForm();
  const query = useQuery();
  const [createCarrier, { isLoading }] = useCreateCarrierMutation();
  const [editCarrier] = useUpdateCarrierMutation();
  const [getCarrierById, { data: carrier }] = useLazyGetCarrierByIdQuery();
  const [refundsList, setRefundsList] = useState<{ maxHours: number; percent: number }[]>([]);
  const [refundChecked, setRefundChecked] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const refundPercent = Form.useWatch('refundPercent', form)?.value;
  const refundTime = Form.useWatch('refundTime', form)?.value;
  const [discounts, setDiscounts] =
    useState<{ value: string; label: string }[]>(discountSelectOptions);
  const disableAddButton = refundChecked && (!refundPercent || !refundTime);
  const [discountItem, setDiscountItem] = useState<{ value?: string; label?: string }>();
  const inputRef = useRef<InputRef>(null);
  const onDiscountChange = useCallback(
    (event, field: 'value' | 'label') => {
      if (field === 'label') {
        if (discountItem?.value) {
          setDiscountItem((prevState) => ({
            value: `${prevState?.label ? prevState?.label.split(' ')[0] : ''}${prevState?.value}`,
            label: event.target.value,
          }));
        }

        setDiscountItem((prevState) => ({ ...prevState, label: event.target.value }));
      }

      if (field === 'value') {
        setDiscountItem((prevState) => ({
          ...prevState,
          value: `${prevState?.label ? prevState?.label.split(' ')[0] : ''}_${event}`,
        }));
      }
    },
    [discountItem],
  );
  const addItem = () => {
    if (discountItem?.value && discountItem?.label) {
      const res = {
        value: discountItem.value,
        label: `${discountItem.label} - ${discountItem.value.split('_')[1]}%`,
      };
      setDiscounts((prevState) => [...prevState, res]);
    }
    setDiscountItem({});
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleAddRefund = useCallback(() => {
    const result = { maxHours: refundTime, percent: refundPercent };

    if (result) {
      setRefundsList((prevState) => [...prevState, result]);
      form.resetFields(['refundPercent', 'refundTime']);
    }
  }, [form, refundPercent, refundTime]);

  const BREADCRUMBS_ROUTES = [
    { name: 'Список перевізників', path: CARRIER_COMFORT },
    { name: isEdit ? 'Редагування перевізника' : 'Створення перевізника' },
  ];

  const handleSubmit = useCallback(
    (data) => {
      const requestBody = {
        ...data,
      };

      if (data.services) {
        requestBody.services = data.services.map((service) => {
          return CARRIERS_SERVICES.find((item) => item.service === service.value);
        });
      }

      if (data.discounts) {
        requestBody.discounts = data.discounts?.map((item) => ({
          label: item.label,
          value: parseInt(item?.value.split('_')[1], 10),
        }));
      }

      if (refundsList.length) {
        requestBody.refundsPolicy = refundsList;
      }

      if (logoUrl) {
        requestBody.logo = logoUrl;
      }

      if (data?.supportPhone_1 || data?.supportPhone_1 || data?.supportPhone_3) {
        const res = [
          data?.supportPhone_1 || null,
          data?.supportPhone_2 || null,
          data?.supportPhone_3 || null,
        ];
        requestBody.supportPhones = res.filter((item) => !!item);
      }

      if (isEdit) {
        editCarrier({ ...requestBody, id: query.get('id') }).then(() => {
          navigate(CARRIER_COMFORT);
        });
      } else {
        createCarrier(requestBody).then((data) => {
          if (data) {
            navigate(CARRIER_COMFORT);
          }
        });
      }
    },
    [query, createCarrier, editCarrier, isEdit, logoUrl, navigate, refundsList],
  );

  useEffect(() => {
    if (!refundChecked) {
      setRefundsList([]);
    }
  }, [refundChecked]);

  useEffect(() => {
    const id = query.get('id');
    if (id) {
      getCarrierById(String(id));
      setIsEdit(true);
    }
  }, [getCarrierById, query]);

  useEffect(() => {
    if (carrier?.name) {
      const discounts: {}[] = [];
      carrier?.discounts?.forEach((item) => {
        discountSelectOptions.forEach((disc) => {
          if (item.label === disc.label) {
            discounts.push({ value: disc.value, label: item.label });
          }
        });
      });

      setLogoUrl(carrier.logo);

      const phones = {};
      carrier?.supportPhones?.forEach((item, i) => {
        phones[`supportPhone_${i + 1}`] = item;
      });

      form.setFieldsValue({
        name: carrier.name,
        baggageAllowance: carrier.baggageAllowance,
        services: carrier.services.map((item) => ({ value: item.service })),
        discounts: discounts,
        ...phones,
      });
    }
  }, [carrier, form]);

  return (
    <ComponentWithContainer title={isEdit ? 'Редагувати перевізника' : 'Створити перевізника'}>
      <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />

      <Form form={form} onFinish={handleSubmit}>
        <div className={s.content}>
          <div className={s.block}>
            <div className={s.nameInfo}>
              <div className={s.item}>
                <Label required>Назва перевізника</Label>
                <Form.Item name="name" rules={[formRules.required]}>
                  <Input name="name" placeholder="Введіть назву перевізника" />
                </Form.Item>
              </div>
              <div className={s.item}>
                <Label required>Параметри багажу</Label>
                <Form.Item name="baggageAllowance" rules={[formRules.required]}>
                  <TextArea
                    name="baggageAllowance"
                    placeholder="Введіть параметри багажу"
                    showCount={false}
                  />
                </Form.Item>
              </div>
            </div>

            <UploadLogo logoUrl={logoUrl} handleAdd={setLogoUrl} />
          </div>

          <div className={s.item}>
            <Label required>Зручності</Label>
            <Form.Item name="services" rules={[formRules.required]}>
              <Select
                options={CARRIERS_SERVICES.map((service) => ({
                  label: service?.name,
                  value: service?.service,
                }))}
                placeholder="Виберіть зручність"
                mode="multiple"
                showArrow
                showSearch
              />
            </Form.Item>
          </div>

          <div className={s.item}>
            <Label>Можливі дисконти</Label>
            <Form.Item name="discounts" fullWidth>
              <Select
                mode="multiple"
                options={discounts}
                placeholder="Оберіть можливий дисконт"
                fullWidth
                showArrow
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px', width: '100%' }}>
                      <Input.Number
                        name="valueDesc"
                        placeholder="0"
                        max={100}
                        min={0}
                        onChange={(e) => onDiscountChange(e, 'value')}
                        value={parseInt((discountItem?.value || '')?.split('_')[1], 10)}
                      />
                      <Input
                        name="nameDisc"
                        placeholder="Веддіть назву дисконту"
                        ref={inputRef}
                        value={discountItem?.label || ''}
                        onChange={(e) => onDiscountChange(e, 'label')}
                        style={{ width: 300 }}
                      />
                      <Button
                        onClick={addItem}
                        disabled={!discountItem?.label || !discountItem?.value}>
                        Додати дисконт
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Form.Item>
          </div>
          <div>
            <Label>Номер телефону</Label>
            <div className={s.phones}>
              <Form.Item name="supportPhone_1" fullWidth>
                <Input name="supportPhone_1" placeholder="+380 (99) 999-99-99" />
              </Form.Item>
              <Form.Item name="supportPhone_2" fullWidth>
                <Input name="supportPhone_2" placeholder="+380 (99) 999-99-99" />
              </Form.Item>
              <Form.Item name="supportPhone_3" fullWidth>
                <Input name="supportPhone_3" placeholder="+380 (99) 999-99-99" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className={s.block}>
          <Switch onChange={setRefundChecked} value={refundChecked} />
          <Typography type="main" weight={600}>
            Додати політику повернення квитка?
          </Typography>
        </div>
        {refundChecked && (
          <div className={s.refundBlock}>
            <div className={s.rowGrid}>
              <div className={s.item}>
                <Label>Оберіть процент повернення</Label>
                <Form.Item name="refundPercent">
                  <Select options={refundPercentSelectOptions} fullWidth />
                </Form.Item>
              </div>
              <div className={s.item}>
                <Label>Оберіть час повернення</Label>
                <Form.Item name="refundTime">
                  <Select options={refundTimeSelectOptions} fullWidth />
                </Form.Item>
              </div>
              <Button onClick={handleAddRefund} disabled={disableAddButton}>
                Додати
              </Button>
            </div>
            <div className={s.refundContent}>
              <Typography type="h6">Список правил поверення</Typography>
              <ul>
                {refundsList.map((refund, index) => (
                  <li key={index}>
                    <div className={s.refundItem}>
                      <Typography type="main" weight={600}>
                        {refund.maxHours === 100
                          ? 'Відміна рейсу перевізником'
                          : `Повернення за ${getHoursRefund(refund.maxHours)}`}
                      </Typography>
                      <Typography type="main" weight={600}>
                        {refund.percent}% від вартості квитка
                      </Typography>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className={s.submit}>
          <Button fullWidth htmlType="submit" loading={isLoading}>
            {isEdit ? 'Зберегти' : 'Створити'}
          </Button>
        </div>
      </Form>
    </ComponentWithContainer>
  );
};

export default CreateNewCarrier;
