import { FC, useCallback, useEffect, useState } from 'react';

import ukUA from 'antd/lib/locale/uk_UA';
import dayjs from 'dayjs';
import { handleCopyToClipboard } from 'tools/handleCopyToClipboard';
import { Button, ConfigProvider, Form, Label, Modal, Table, Typography } from 'ui';
import TextArea from 'ui/textarea/textarea';

import {
  useCancelContraBusBookingMutation,
  useChangeContraBusStatusMutation,
  useLazyGetBookingsQuery,
} from 'services/bookings/bookingsApiService';

import FilterList from 'pages/tickets-list/contents/filter-list/filterList';
import {
  PAYMENT_TICKETS_STATUS_ENUM,
  SORT_OPTIONS_ORDERS,
  TICKETS_STATUS_ENUM,
  getRowStyle,
} from 'pages/tickets-list/ticketList.utils';

import BookingsTableDropdown from 'containers/bookings-table-dropdown';

import { ContraBusBookingType } from 'types/bookings/bookingsTypes';

import s from './ContraBusBookingsList.module.scss';

const ContraBusBookingsList: FC = () => {
  const [getAllBookings, { data: bookingsData, isFetching }] = useLazyGetBookingsQuery();
  const [requestFilter, setRequestFilter] = useState<{
    startDate?: string;
    endDate?: string;
    search?: string;
  }>({});
  const [changeBookingStatus] = useChangeContraBusStatusMutation();
  const [openedCancelBookingModal, setOpenedCancelBookingModal] = useState('');
  const [cancelBooking] = useCancelContraBusBookingMutation();

  const handleActions = (key: string, order: ContraBusBookingType) => {
    if (key === 'copyLink') {
      handleCopyToClipboard(order.link_to_pdf);
    }
    if (key === 'printTicket') {
      const newWindow = window.open(order.link_to_pdf, '_blank');

      // Wait for the PDF to load in the new window
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
          newWindow.onafterprint = () => {
            newWindow.close();
          };
        };
      }
    }
    if (key === 'viber') {
      window.open(`viber://chat?number=${order.viber}`);
    }
    if (key === 'telegram') {
      window.open(`https://t.me/${order.phone.replace(/\s/g, '')}`);
    }
    if (key === 'whatsApp') {
      window.open(`https://wa.me/${order.phone.replace(/\s/g, '')}`);
    }
    if (key === 'confirmBooking') {
      changeBookingStatus({
        id: order.ticket_id,
        status: 'agent_confirm',
      });
    }

    if (key === 'cancelBooking') {
      setOpenedCancelBookingModal(order.ticket_id);
    }
  };

  const handleCancelBooking = useCallback(
    (data) => {
      cancelBooking({
        id: openedCancelBookingModal,
        description: data.description,
      });
      setOpenedCancelBookingModal('');
    },
    [cancelBooking, openedCancelBookingModal],
  );

  const columns = [
    {
      title: 'Перевізник, Маршрут',
      key: 'tripInfo',
      render: (_, row) => {
        return (
          <div>
            <Typography type="main" weight={500}>
              {row.carrier}
            </Typography>
            <Typography type="main" weight={600}>
              {row.route}
            </Typography>
            <Typography type="main" weight={600}>
              {row.from} - {row.to}
            </Typography>
          </div>
        );
      },
      width: 250,
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return (
          <Typography type="main" weight={600}>
            {dayjs(date, 'DD.MM.YYYY').format('DD MMMM YYYY')}
          </Typography>
        );
      },
      sorter: (a, b) => {
        const dateA = dayjs(a.date, 'DD.MM.YYYY');
        const dateB = dayjs(b.date, 'DD.MM.YYYY');
        return dateA.diff(dateB);
      },
      sortDirections: SORT_OPTIONS_ORDERS,
    },
    {
      title: 'Пасажир',
      dataIndex: 'name_surname',
      key: 'name_surname',
    },
    {
      title: 'Контакти',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ціна',
      dataIndex: 'price',
      key: 'price',
      render: (price, row) => (
        <div>
          <Typography type="main" weight={600}>
            {`${price}  ${row.currency}`}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'booking_type',
      key: 'status',
      render: (type) => <div>{PAYMENT_TICKETS_STATUS_ENUM[type?.toUpperCase()]}</div>,
    },
    {
      title: 'Агент',
      dataIndex: 'agent',
      key: 'agent',
    },
    {
      title: 'Коментарі',
      dataIndex: 'comments',
      key: 'comments',
      render: (comment) => <div>{comment || '-'}</div>,
    },
    {
      title: 'Підтвердження',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <div>{TICKETS_STATUS_ENUM[status?.toUpperCase()]}</div>,
    },
    {
      title: 'Дії',
      key: 'actions',
      render: (row) => (
        <BookingsTableDropdown handleClick={(key) => handleActions(key, row)} status={row.status} />
      ),
    },
  ];

  useEffect(() => {
    const filter: { search_field?: string; start_date?: string; final_date?: string } = {
      search_field: requestFilter?.search,
    };
    if (requestFilter?.startDate) {
      filter.start_date = dayjs(requestFilter?.startDate).format('DD.MM.YYYY');
    }
    if (requestFilter?.endDate) {
      filter.final_date = dayjs(requestFilter?.endDate).format('DD.MM.YYYY');
    }
    getAllBookings(filter);
  }, [getAllBookings, requestFilter]);

  return (
    <div>
      <FilterList setRequestFilter={setRequestFilter} />
      <ConfigProvider locale={ukUA}>
        <Table
          dataSource={bookingsData?.list}
          rowKey="ticket_id"
          columns={columns}
          loading={isFetching}
          rowClassName={(record) => getRowStyle(record.status)}
          scroll={{ x: 600 }}
        />
      </ConfigProvider>
      <Modal
        handleClose={() => setOpenedCancelBookingModal('')}
        open={!!openedCancelBookingModal}
        footer={false}>
        <Modal.Header>
          <Typography type="h4">Вкажіть причину</Typography>
        </Modal.Header>
        <Modal.Content>
          <Form className={s.form} onFinish={handleCancelBooking}>
            <div>
              <Label>Причина відміни</Label>
              <Form.Item name="description">
                <TextArea name="description" maxLength={200} />
              </Form.Item>
            </div>
            <Modal.Actions>
              <Button htmlType="submit" fullWidth>
                Скасувати
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ContraBusBookingsList;
