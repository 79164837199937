export const refundPercentSelectOptions = [
  { value: '0', label: '0%' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
  { value: 25, label: '25%' },
  { value: 30, label: '30%' },
  { value: 35, label: '35%' },
  { value: 40, label: '40%' },
  { value: 45, label: '45%' },
  { value: 50, label: '50%' },
  { value: 55, label: '55%' },
  { value: 60, label: '60%' },
  { value: 65, label: '65%' },
  { value: 70, label: '70%' },
  { value: 75, label: '75%' },
  { value: 80, label: '80%' },
  { value: 85, label: '85%' },
  { value: 90, label: '90%' },
  { value: 95, label: '95%' },
  { value: 100, label: '100%' },
];

export const refundTimeSelectOptions = [
  { value: 1, label: '1 година' },
  { value: 12, label: '12 годин' },
  { value: 24, label: '24 години' },
  { value: 48, label: '48 години' },
  { value: '0', label: 'Менше одної години' },
  { value: 100, label: 'Відміна рейсу перевізником' },
];

export const getHoursRefund = (hours: number) => {
  let hoursString: string;
  if (hours % 10 === 1 && hours !== 11) {
    hoursString = `годину`;
  } else if (
    (hours % 10 === 2 && hours !== 12) ||
    (hours % 10 === 3 && hours !== 13) ||
    (hours % 10 === 4 && hours !== 14)
  ) {
    hoursString = `години`;
  } else {
    hoursString = `годин`;
  }

  return `${hours} ${hoursString}`;
};
