import initialApiService from 'services/initialApiService';

import { CityType } from 'types/cities/citiesTypes';

const CitiesApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    searchCity: builder.query<CityType[], string>({
      query: (name) => ({
        url: '/cities/search',
        method: 'GET',
        params: {
          name,
        },
        cache: 'no-cache',
      }),
    }),
    getAllCities: builder.query<CityType[], void>({
      query: () => ({
        url: '/cities',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazySearchCityQuery, useLazyGetAllCitiesQuery } = CitiesApiService;
