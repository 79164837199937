export const formRules = {
  required: {
    required: true,
    message: "Поле обов'язкове",
  },
  email: {
    pattern: /\S+@\S+\.\S+/,
    message: 'Неправильний формат електронної пошти',
    validateTrigger: ['onBlur'],
  },
};
