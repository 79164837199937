import { FC, useEffect, useState } from 'react';

import { Form, Input, Label, Typography } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import ChangePasswordModal from 'pages/user-settings/user-setting-content/profile-setting/change-password-modal';

import s from './ProfileSetting.module.scss';

const ProfileSetting: FC = () => {
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [isOpenedChangePasswordModal, setIsOpenedChangePasswordModal] = useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsOpenedChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsOpenedChangePasswordModal(false);
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <Typography type="main">Ваш профіль</Typography>
        <Typography type="h5">{currentUser?.fullName}</Typography>
      </div>

      <Form className={s.form}>
        <div className={s.row}>
          <div className={s.formItem}>
            <Label>Email</Label>
            <Input name="email" value={currentUser?.email} disabled fullWidth />
          </div>
          <div className={s.formItem}>
            <Label>Пароль</Label>
            <Input name="password" type="password" defaultValue="Qwerty1234" disabled fullWidth />
            <button className={s.changePassword} onClick={handleOpenChangePasswordModal}>
              Змінити пароль
            </button>
          </div>
        </div>
        <div>
          <Typography type="h5">Контактна інформація</Typography>
          <div className={s.contacts}>
            <Form.Item>
              <Input name="phone" placeholder="Номер телефону" />
            </Form.Item>
          </div>
        </div>
      </Form>

      <ChangePasswordModal
        open={isOpenedChangePasswordModal}
        onClose={handleCloseChangePasswordModal}
      />
    </div>
  );
};

export default ProfileSetting;
