import { FC, PropsWithChildren, memo } from 'react';

import { Dropdown as DropdownAntd, MenuProps } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import 'antd/lib/dropdown/style/css';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import cn from 'classnames';

import s from './Dropdown.module.scss';

export type HandleItemClickType = Pick<MenuProps, 'onClick'>['onClick'];
export type HandleItemClickParamsType = Parameters<NonNullable<HandleItemClickType>>[0];
export interface DropdownProps extends Omit<DropdownButtonProps, 'menu'>, PropsWithChildren {
  items: ItemType[];
  handleItemClick: HandleItemClickType;
}

const Dropdown: FC<DropdownProps> = (props) => {
  const {
    align,
    children,
    trigger = ['click'],
    items,
    handleItemClick,
    placement = 'bottomLeft',
    getPopupContainer,
    disabled = false,
    open = false,
    onOpenChange,
    className = '',
  } = props;
  return (
    <DropdownAntd
      className={cn(s.wrapper, { [className]: className })}
      align={align}
      open={open}
      disabled={disabled}
      getPopupContainer={getPopupContainer}
      menu={{ items, onClick: handleItemClick }}
      trigger={trigger}
      placement={placement}
      onOpenChange={onOpenChange}>
      {children}
    </DropdownAntd>
  );
};

export default memo(Dropdown);
