import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { CREATE_BOOKING_ROUTE } from 'routes/core/list';
import { Button } from 'ui/index';

import s from './SelectRouteButton.module.scss';

interface SelectRouteButtonProps {
  onClick: () => void;
}

const SelectRouteButton: FC<SelectRouteButtonProps> = (props) => {
  const { onClick } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    onClick();
    navigate(CREATE_BOOKING_ROUTE);
  };

  return (
    <div className={s.selectButton}>
      <Button fullWidth onClick={handleClick}>
        Обрати
      </Button>
    </div>
  );
};

export default SelectRouteButton;
