import dayjs, { Dayjs } from 'dayjs';

import { CreationRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

export type RouteBaseInfoType = {
  routeName: string;
  routeNumber: string;
  carrier: {
    label: string;
    value: string;
    key: string;
  };
  prepayment: {
    label: string;
    value: string;
    key: string;
  };
  fromCity: {
    id: number;
    name: string;
    klrCityId: number;
    contraBusCityId: number;
    country_code: string;
  };
  toCity: {
    id: number;
    name: string;
    klrCityId: number;
    contraBusCityId: number;
    country_code: string;
  };
  departureTime: Dayjs;
  arrivalTime: Dayjs;
  price: {
    value: number;
    currency: {
      label: string;
      value: string;
      key: string;
    };
  };
  fromStation: string;
  toStation: string;
  freeChoiceOfPlaces: boolean;
  routeComment?: string;
};

export type TripDetailsType = {
  timetables?: {
    city: string;
    station: string;
    departureTime: string;
    change?: boolean;
  }[];
};

type RouteDetailsType = {
  travelDuration: {
    days: number;
    hours: number;
    mins: number;
  };
  tripDetails: {
    frequency: {
      label: string;
      value: number;
      key: number;
    }[];
  };
  seats: number;
  // discounts: {
  //   label: string;
  //   value: string;
  //   key: string;
  // }[];
};

export const prepareRouteDate = (
  baseInfo: RouteBaseInfoType,
  tripDetail: TripDetailsType,
  detailsInfo: RouteDetailsType,
  isEdit: boolean,
) => {
  const result: CreationRouteType = {
    routeName: baseInfo.routeName,
    routeNumber: baseInfo.routeNumber,
    carrier: baseInfo.carrier?.value,
    fromCity: baseInfo.fromCity?.name,
    toCity: baseInfo.toCity?.name,
    departureTime: dayjs(baseInfo.departureTime).format('HH:mm'),
    arrivalTime: dayjs(baseInfo.arrivalTime).format('HH:mm'),
    price: {
      value: baseInfo.price.value,
      currency: baseInfo.price.currency.value,
    },
    fromStation: baseInfo.fromStation,
    toStation: baseInfo.toStation,
    freeChoiceOfPlaces: baseInfo.freeChoiceOfPlaces,
    seats: Number(detailsInfo.seats),
    tripDetails: {},
    prepayment: baseInfo.prepayment.value,
  } as CreationRouteType;

  if (baseInfo.routeComment) {
    result.routeComment = baseInfo.routeComment;
  }

  if (tripDetail.timetables) {
    result.tripDetails.timetable = [
      ...(!isEdit
        ? [
            {
              city: baseInfo.fromCity?.name,
              station: baseInfo.fromStation,
              departureTime: dayjs(baseInfo.departureTime).format('HH:mm'),
            },
          ]
        : []),
      ...(tripDetail.timetables || []),
      ...(!isEdit
        ? [
            {
              city: baseInfo.toCity?.name,
              station: baseInfo.toStation,
              departureTime: dayjs(baseInfo.arrivalTime).format('HH:mm'),
            },
          ]
        : []),
    ];
  }

  if (detailsInfo.travelDuration) {
    result.travelDuration = detailsInfo.travelDuration;
  }
  if (detailsInfo.tripDetails.frequency) {
    result.tripDetails.frequency = {
      weekDays: detailsInfo.tripDetails.frequency.map((item) => item.value),
    };
  }

  return result;
};

export const PREPAYMENT_OPTION = [
  {
    label: 'Бeз предоплати',
    value: 'undefined',
  },
  {
    label: 'Обовʼязкова передоплата від 50% вартості квитка',
    value: "Обов'язкова передоплата від 50% вартості квитка",
  },
  {
    label: "Обов'язкова передоплата від 1000 грн або 25 євро за кожен квиток!",
    value: "Обов'язкова передоплата від 1000 грн або 25 євро за кожен квиток!",
  },
  {
    label: "Обов'язкова передоплата від 20% вартості квитка.",
    value: "Обов'язкова передоплата від 20% вартості квитка.",
  },
  {
    label: "Обов'язкова повна оплата одного квитка для груп з трьох і більше осіб!",
    value: "Обов'язкова повна оплата одного квитка для груп з трьох і більше осіб!",
  },
  {
    label: "Обов'язкова попередня оплата від 15% вартості кожного квитка!",
    value: "Обов'язкова попередня оплата від 15% вартості кожного квитка!",
  },
  {
    label: "Обов'язкова повна попередня оплата квитка.",
    value: "Обов'язкова повна попередня оплата квитка.",
  },
  {
    label: 'Лише повна оплата квитка. Бронювати після отримання оплати!',
    value: 'Лише повна оплата квитка. Бронювати після отримання оплати!',
  },
  {
    label: "Обов'язкова попередня оплата від 50 злт. або 500 грн. за кожен квиток!",
    value: "Обов'язкова попередня оплата від 50 злт. або 500 грн. за кожен квиток!",
  },
];
