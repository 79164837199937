import s from './TicketList.module.scss';

export enum TICKETS_LIST_TABS {
  TOP_TICKET = 'topTicket',
  KLR = 'KLR',
  CONTRA_BUS = 'ContraBus',
}

const getOtherTabs = (role) => {
  if (['ADMIN', 'OPERATOR'].includes(role)) {
    return [
      {
        label: 'Броні ContraBus',
        key: TICKETS_LIST_TABS.CONTRA_BUS,
      },
      {
        label: 'Броні KLR',
        key: TICKETS_LIST_TABS.KLR,
      },
    ];
  }

  return [];
};

export const TICKETS_TABS_CONFIGURATION = (role) => [
  {
    label: 'Броні TopTicket',
    key: TICKETS_LIST_TABS.TOP_TICKET,
  },
  ...getOtherTabs(role),
];

export enum PAYMENT_TICKETS_STATUS_ENUM {
  FREE = 'Бронь',
  PAID = 'Оплачено',
}

export enum TICKETS_STATUS_ENUM {
  UNDEFINED = 'Не відомо',
  AGENT_CONFIRM = 'Підтверджено агентом',
  BOOKING_CONFIRMED = 'Підтверджено перевізником',
  AGENT_CANCEL = 'Відміна до поїздки',
  REBOOKED = 'Переоформлено',
  CARRIER_CANCEL = 'Відміна перевізником',
  UNCONFIRMED = 'Не їхав',
  CONFIRMED = 'Їхав',
}

export type SortOrder = 'descend' | 'ascend' | null;

export const SORT_OPTIONS_ORDERS = ['descend', 'ascend', null] as SortOrder[];

export const getRowStyle = (status) => {
  if (status === 'agent_confirm') {
    return s.rowConfirm;
  }

  if (status === 'rebooked') {
    return s.rowRebooked;
  }
  if (status === 'booking_confirmed') {
    return s.rowCarrierConfirm;
  }
  if (status === 'agent_cancel') {
    return s.rowCanceled;
  }
  if (status === 'carrier_cancel') {
    return s.rowCanceled;
  }
  if (status === 'unconfirmed') {
    return s.unconfirmed;
  }
  if (status === 'confirmed') {
    return s.confirmed;
  }
};
