import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { BOOKING_ROUTE } from 'routes/core/list';
import { Breadcrumbs, Typography } from 'ui/index';

import { routeForBookingSelector } from 'selectors/eventsSlice.selector';
import { useAppSelector } from 'store/index';

import BookingCreateForm from 'pages/booking/booking-create/booking-create-form';
import { getRouteDate, getRouteName } from 'pages/booking/booking-create/bookingCreate.utils';

import ComponentWithContainer from 'components/componentWithContainer';

import s from './BookingCreate.module.scss';

const BREADCRUMBS_ROUTES = [
  { name: 'Пошук рейсів', path: BOOKING_ROUTE },
  { name: 'Бронювання квитка' },
];
const BookingCreate: FC = () => {
  const selectedRoute = useAppSelector(routeForBookingSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedRoute) {
      navigate(BOOKING_ROUTE);
    }
  }, [navigate, selectedRoute]);

  return (
    <ComponentWithContainer title="Бронювання квитка">
      <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      {selectedRoute?.route && (
        <>
          <div className={s.routeInfo}>
            <Typography type="h3">
              {`Рейс: ${getRouteName(selectedRoute.route)}  ${getRouteDate(selectedRoute.route)}`}
            </Typography>
          </div>

          <BookingCreateForm route={selectedRoute} />
        </>
      )}
    </ComponentWithContainer>
  );
};

export default BookingCreate;
