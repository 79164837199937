import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';
import { AUTH_ROUTES, NON_AUTH_ROUTES } from 'routes/routes';
import { getIsAuth } from 'tools/cookieTools';

import { isLoggedInSelector } from 'selectors/userSlice.selectors';

import { RouteType } from 'types/core/routeTypes';

import { useAppSelector } from '../store';

const renderRoutes = ({ path, element }: RouteType, index: number): React.ReactElement => (
  <Route path={path} element={element} key={`route__${index}__${path}`} />
);

const Pages = () => {
  const isAuth = getIsAuth();
  const isLogged = useAppSelector(isLoggedInSelector);

  let routes: React.ReactElement[] = NON_AUTH_ROUTES.map(renderRoutes);

  if (isLogged || isAuth) {
    routes = AUTH_ROUTES.map(renderRoutes);
  }
  return <Routes>{routes}</Routes>;
};

export default memo(Pages);
