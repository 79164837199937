import { FC, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { getTimeTrip } from 'tools/getTimeTrip';
import { Icon, Typography } from 'ui';

import RouteServices from 'pages/booking/booking-list/route-services';
import { setRouteForBooking } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { CARRIERS_SERVICE_TYPES } from 'pages/booking/booking-list/topTicket-item/topTicketItem.utils';

import SelectRouteButton from 'components/select-route-button';
import ScheduleModal from 'containers/schedule-modal';

import { KlrRouteType } from 'types/routes/routesTypes';

import s from './KlrItem.module.scss';

interface KlrItemProps {
  route: KlrRouteType;
}
const KlrItem: FC<KlrItemProps> = ({ route }) => {
  const dispatch = useAppDispatch();
  const [isShowServices, setIsShowServices] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  const handleGetServices = useCallback(async () => {
    setIsShowServices(!isShowServices);
  }, [isShowServices]);

  const handleSelectRoute = useCallback(() => {
    dispatch(
      setRouteForBooking({
        route: route,
        owner: 'klr',
      }),
    );
  }, [route, dispatch]);

  return (
    <div className={s.item}>
      <div>
        <div className={s.tripDate}>
          <Typography type="h3">{dayjs(route?.datetime_departure).format('HH:mm')}</Typography>
          <Typography type="main" weight={600}>
            {dayjs(route?.datetime_departure).format('DD MMM')}
          </Typography>

          <div className={s.hide}>
            {route?.travel && (
              <div className={s.duration}>
                <Typography type="small" color="green">
                  {getTimeTrip(route?.travel)}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div>
          <Typography type="main" color="primary">
            {route?.from_city_name},
          </Typography>
          <Typography type="small" color="primary">
            {route?.from_station}
          </Typography>
        </div>
        <div className={s.hide}>
          <div className={s.actions}>
            <button className={s.action} onClick={() => setOpenScheduleModal(true)}>
              <Typography type="small" className={s.actionText}>
                Маршрут
              </Typography>
            </button>
            <button className={s.action} onClick={handleGetServices}>
              <Typography type="small" className={s.actionText}>
                Зручності
              </Typography>
            </button>
          </div>
          <div>
            {route?.included?.trip_options && isShowServices && (
              <div className={s.service}>
                {route?.included?.trip_options?.map((service) => (
                  <div key={service.option}>
                    <Icon name={CARRIERS_SERVICE_TYPES[service.option]} size={26} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className={s.tripDate}>
          <Typography type="h3">{dayjs(route?.datetime_arrival).format('HH:mm')}</Typography>
          <Typography type="main" weight={600}>
            {dayjs(route?.datetime_arrival).format('DD MMM')}
          </Typography>
        </div>
        <div>
          <Typography type="main" color="primary">
            {route?.to_city_name},
          </Typography>
          <Typography type="small" color="primary">
            {route?.to_station}
          </Typography>
        </div>
      </div>
      <div className={s.mobile}>
        {route?.travel && (
          <div className={s.duration}>
            <Typography type="small" color="green">
              {getTimeTrip(route?.travel)}
            </Typography>
          </div>
        )}
        <RouteServices
          handleGetServices={handleGetServices}
          isShowServices={isShowServices}
          services={route?.included?.trip_options.map((item) => ({
            id: item.option,
            service: item.option,
          }))}
          setOpenScheduleModal={setOpenScheduleModal}
        />
      </div>
      <div className={s.price}>
        <Typography type="h3" weight={600}>
          {`${route?.price}
          ${route?.currency}`}
        </Typography>
        <SelectRouteButton onClick={handleSelectRoute} />
      </div>

      <ScheduleModal
        open={openScheduleModal}
        onClose={() => setOpenScheduleModal(false)}
        schedule={route?.included?.trip_details?.timetable?.map((item) => ({
          city: item?.city,
          time: item?.departure_time,
          station: item?.station,
          change: item?.change,
        }))}
      />
    </div>
  );
};

export default KlrItem;
