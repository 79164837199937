export enum CARRIERS_SERVICE_TYPES {
  toilet = 'wc',
  TV = 'tv',
  wifi = 'wifi',
  electronicTicket = 'electronicTicket',
  paymentInDriver = 'paymentInDriver',
  drinks = 'drinks',
  pets = 'pets',
  audioSystem = 'music',
  phoneCharge = 'power',
  climateControl = 'air',
  passport = 'passport',
}
