export const CARRIERS_SERVICES = [
  {
    name: 'Туалет',
    service: 'toilet',
  },
  {
    name: 'Телевізор',
    service: 'TV',
  },
  { name: 'WI-FI', service: 'wifi' },
  { name: 'Електронний квиток', service: 'electronicTicket' },
  { name: 'Можлива оплата у водія', service: 'paymentInDriver' },
  { name: 'Напої', service: 'drinks' },
  { name: 'Спільна аудиосистема', service: 'audioSystem' },
  { name: 'Зарядка телефону', service: 'phoneCharge' },
  { name: 'Проїзд з тваринами', service: 'pets' },
  { name: 'Клімат контроль', service: 'climateControl' },
  { name: 'Паспортний контроль', service: 'passport' },
];
