import { FC, PropsWithChildren, ReactElement, memo } from 'react';

import cn from 'classnames';

import s from './Sider.module.scss';

export interface SiderProps {
  className?: string;
  headerContent?: ReactElement;

  opened: boolean;

  theme?: 'light' | 'dark';
}

const Sider: FC<PropsWithChildren<SiderProps>> = (props) => {
  const { children, className = '', headerContent, opened, theme = 'dark' } = props;

  return (
    <div className={cn(s.sider, s.long, s[theme], { [className]: className, [s.opened]: opened })}>
      <div className={cn(s.header, s[theme])}>{headerContent}</div>
      {children}
    </div>
  );
};

export default memo(Sider);
