import { FC, SVGProps } from 'react';

import { servicesIcon } from './services/servicesIcon.utils';

import { ReactComponent as ArrowDownOutline } from './default/arrowDownOutline.svg';
import { ReactComponent as ArrowDropdown } from './default/arrowDropdown.svg';
import { ReactComponent as ArrowRight } from './default/arrowRight.svg';
import { ReactComponent as BankCard } from './default/bankCard.svg';
import { ReactComponent as Bell } from './default/bell.svg';
import { ReactComponent as Bookmark } from './default/bookmark.svg';
import { ReactComponent as Briefcase } from './default/briefcase.svg';
import { ReactComponent as burgerMenu } from './default/burgerMenu.svg';
import { ReactComponent as CancelCircle } from './default/cancelCircle.svg';
import { ReactComponent as Change } from './default/change.svg';
import { ReactComponent as Check } from './default/check.svg';
import { ReactComponent as CheckWide } from './default/checkWide.svg';
import { ReactComponent as Copy } from './default/copy.svg';
import { ReactComponent as Delete } from './default/delete.svg';
import { ReactComponent as Download } from './default/download.svg';
import { ReactComponent as Drag } from './default/drag.svg';
import { ReactComponent as Edit } from './default/edit.svg';
import { ReactComponent as Email } from './default/email.svg';
import { ReactComponent as Error } from './default/error.svg';
import { ReactComponent as ExclamationTriangle } from './default/exclamationTriangle.svg';
import { ReactComponent as Eye } from './default/eye.svg';
import { ReactComponent as Favorite } from './default/favorite.svg';
import { ReactComponent as Fiat } from './default/fiat.svg';
import { ReactComponent as File } from './default/file.svg';
import { ReactComponent as FilesFolder } from './default/filesFolder.svg';
import { ReactComponent as Finish } from './default/finish.svg';
import { ReactComponent as Idea } from './default/idea.svg';
import { ReactComponent as IdeaColor } from './default/ideaHint.svg';
import { ReactComponent as Info } from './default/info.svg';
import { ReactComponent as Kiosk } from './default/kiosk.svg';
import { ReactComponent as Lightning } from './default/lightning.svg';
import { ReactComponent as Links } from './default/links.svg';
import { ReactComponent as List } from './default/list.svg';
import { ReactComponent as Loader } from './default/loader.svg';
import { ReactComponent as Logo } from './default/logo.svg';
import { ReactComponent as LogoSmall } from './default/logoSmall.svg';
import { ReactComponent as Plus } from './default/plus.svg';
import { ReactComponent as Profile } from './default/profile.svg';
import { ReactComponent as Receipt } from './default/receipt.svg';
import { ReactComponent as RefreshArrows } from './default/refreshArrows.svg';
import { ReactComponent as Search } from './default/search.svg';
import { ReactComponent as Setting } from './default/setting.svg';
import { ReactComponent as SimpleArrowRight } from './default/simpleArrowRight.svg';
import { ReactComponent as Success } from './default/success.svg';
import { ReactComponent as SwapArrows } from './default/swapArrows.svg';
import { ReactComponent as Telegram } from './default/telegram.svg';
import { ReactComponent as Test } from './default/test.svg';
import { ReactComponent as Thumbnail } from './default/thumbnail.svg';
import { ReactComponent as Time } from './default/time.svg';
import { ReactComponent as Tip } from './default/tip.svg';
import { ReactComponent as TopUp } from './default/topUp.svg';
import { ReactComponent as TrashBox } from './default/trashBox.svg';
import { ReactComponent as VerticalDots } from './default/verticalDots.svg';
import { ReactComponent as Warning } from './default/warning.svg';
import { ReactComponent as BankDetails } from './navbar/bank-details.svg';
import { ReactComponent as Calendar } from './navbar/calendar.svg';
import { ReactComponent as Dashboard } from './navbar/dashboard.svg';
import { ReactComponent as Help } from './navbar/help.svg';
import { ReactComponent as LogoutWhite } from './navbar/logout-white.svg';
import { ReactComponent as Logout } from './navbar/logout.svg';
import { ReactComponent as MyEvents } from './navbar/my-events.svg';
import { ReactComponent as Notification } from './navbar/notification.svg';
import { ReactComponent as OrganiserDetails } from './navbar/organiser-details.svg';
import { ReactComponent as RoutesList } from './navbar/routes.svg';
import { ReactComponent as TicketSeller } from './navbar/ticket-seller.svg';
import { ReactComponent as TicketKiosk } from './navbar/tickets-kiosk.svg';
import { ReactComponent as TicketOrders } from './navbar/tickets-orders.svg';
import { ReactComponent as TicketReports } from './navbar/tickets-reports.svg';
import { ReactComponent as TicketScan } from './navbar/tickets-scan.svg';

export const Icons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  ...servicesIcon,
  dashboard: Dashboard,
  setting: Setting,
  myEvents: MyEvents,
  ticketOrders: TicketOrders,
  ticketKiosk: TicketKiosk,
  ticketSeller: TicketSeller,
  ticketScan: TicketScan,
  ticketReports: TicketReports,
  notification: Notification,
  arrowDropdown: ArrowDropdown,
  arrowRight: ArrowRight,
  briefcase: Briefcase,
  calendar: Calendar,
  organiser_details: OrganiserDetails,
  bank_details: BankDetails,
  check: Check,
  delete: Delete,
  eye: Eye,
  info: Info,
  plus: Plus,
  profile: Profile,
  search: Search,
  telegram: Telegram,
  test: Test,
  lightning: Lightning,
  exclamationTriangle: ExclamationTriangle,
  cancelCircle: CancelCircle,
  copy: Copy,
  edit: Edit,
  help: Help,
  simpleArrowRight: SimpleArrowRight,
  topUp: TopUp,
  email: Email,
  time: Time,
  list: List,
  checkWide: CheckWide,
  bankCard: BankCard,
  verticalDots: VerticalDots,
  links: Links,
  trashBox: TrashBox,
  warning: Warning,
  fiat: Fiat,
  filesFolder: FilesFolder,
  loader: Loader,
  error: Error,
  file: File,
  bookmark: Bookmark,
  finish: Finish,
  swapArrows: SwapArrows,
  refreshArrows: RefreshArrows,
  bell: Bell,
  download: Download,
  arrowDownOutline: ArrowDownOutline,
  burgerMenu: burgerMenu,
  receipt: Receipt,
  logo: Logo,
  kiosk: Kiosk,
  logout: Logout,
  logoutWhite: LogoutWhite,
  thumbnail: Thumbnail,
  idea: Idea,
  ideaColor: IdeaColor,
  tip: Tip,
  routesList: RoutesList,
  logoSmall: LogoSmall,
  favorite: Favorite,
  success: Success,
  change: Change,
  drag: Drag,
};
