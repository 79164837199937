import { FC, useEffect } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { TICKETS_ROUTE } from 'routes/core/list';
import { Breadcrumbs, Button, Form, Input, Label, TextArea, Typography } from 'ui';

import {
  useEditBookingMutation,
  useLazyGetBookingByIdQuery,
} from 'services/bookings/bookingsApiService';

import ComponentWithContainer from 'components/componentWithContainer';

import s from './EditBooking.module.scss';

const BREADCRUMBS_ROUTES = [
  { name: 'Список квитків', path: TICKETS_ROUTE },
  { name: 'Редагування бронювання' },
];

interface EditBookingProps {
  // Define your component props here
}

const EditBooking: FC<EditBookingProps> = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const [getBooking, { data: booking }] = useLazyGetBookingByIdQuery();
  const [editBooking] = useEditBookingMutation();

  const handleSubmit = async (data) => {
    if (data) {
      await editBooking({
        ...data,
        id: params.id,
      });
    }
  };

  useEffect(() => {
    if (params.id) {
      getBooking(params.id);
    }
  }, [getBooking, params.id]);

  useEffect(() => {
    if (booking) {
      form.setFieldsValue({ ...booking });
    }
  }, [booking, form]);

  return (
    <ComponentWithContainer title="Редагування бронювання">
      <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      <Form form={form} onFinish={handleSubmit}>
        <div>
          <Typography type="h5">Інформація про бронювання</Typography>
        </div>
        <div className={s.header}>
          <div>
            <Label>Номер бронювання</Label>
            <Input name="bookNumber" disabled value={booking?.id} />
          </div>
          <div>
            <Label>Перевізник</Label>
            <Input name="carrier" disabled value={booking?.route?.carrier?.name} />
          </div>
          <div>
            <Label>Місто відправлення</Label>
            <Input name="fromCity" disabled value={booking?.route?.fromCity} />
          </div>
          <div>
            <Label>Місто прибуття</Label>
            <Input name="toCity" disabled value={booking?.route?.toCity} />
          </div>
          <div>
            <Label>Дата поїздки</Label>
            <Input name="tripDate" disabled value={dayjs(booking?.tripDate).format('DD.MM.YYYY')} />
          </div>
        </div>
        <div className={s.content}>
          <div>
            <Label>{`Ім'я`}</Label>
            <Form.Item name="firstName">
              <Input name="firstName" />
            </Form.Item>
          </div>
          <div>
            <Label>Прізвище</Label>
            <Form.Item name="lastName">
              <Input name="lastName" />
            </Form.Item>
          </div>
          <div>
            <Label>Номер телефону</Label>
            <Form.Item name="phone">
              <Input name="phone" />
            </Form.Item>
          </div>
        </div>
        <div className={s.comment}>
          <Label>Коментар</Label>
          <Form.Item name="comment">
            <TextArea name="comment" maxLength={250} />
          </Form.Item>
        </div>

        <Button htmlType="submit">Зберегти</Button>
      </Form>
    </ComponentWithContainer>
  );
};

export default EditBooking;
