import { FC, useCallback, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { getTimeTrip } from 'tools/getTimeTrip';
import { Icon, Typography } from 'ui/index';

import RouteServices from 'pages/booking/booking-list/route-services';
import { setRouteForBooking } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import s from 'pages/booking/booking-list/klr-item/KlrItem.module.scss';

import SelectRouteButton from 'components/select-route-button';
import ScheduleModal from 'containers/schedule-modal';

import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

interface TopTicketItemProps {
  item: FullRouteType;
}
const TopTicketItem: FC<TopTicketItemProps> = (props) => {
  const { item } = props;
  const dispatch = useAppDispatch();
  const [isShowServices, setIsShowServices] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  const handleGetServices = useCallback(async () => {
    setIsShowServices(!isShowServices);
  }, [isShowServices]);

  const handleSelectRoute = useCallback(() => {
    dispatch(
      setRouteForBooking({
        route: item,
        owner: 'topTicket',
      }),
    );
  }, [item, dispatch]);

  return (
    <div
      className={cn(s.item, {
        [s.recommended]:
          item?.recommended &&
          !(item?.prepayment && item?.prepayment !== 'undefined') &&
          !item?.sale?.value,
        [s.priceLabel]: !!item?.prepayment && item?.prepayment !== 'undefined',
        [s.sale]: !!item?.sale?.value && !(item?.prepayment && item?.prepayment !== 'undefined'),
      })}>
      {item?.recommended &&
        !(item?.prepayment && item?.prepayment !== 'undefined') &&
        !item?.sale?.value && (
          <div className={s.favorite}>
            <Icon name="favorite" />
            <Typography type="small" color="green">
              Topticket рекомендує
            </Typography>
          </div>
        )}
      {item?.prepayment && item?.prepayment !== 'undefined' && (
        <div className={s.favorite}>
          <Icon name="favorite" color="#F95A61" />
          <Typography type="small" color="red">
            {item?.prepayment}
          </Typography>
        </div>
      )}
      {item?.sale?.value && !(item?.prepayment && item?.prepayment !== 'undefined') && (
        <div className={s.favorite}>
          <Icon name="favorite" color="#008eff" />
          <Typography type="small" color="primary">
            {item?.sale?.name}
          </Typography>
        </div>
      )}
      <div>
        <div className={s.tripDate}>
          <Typography type="h3">{dayjs(item?.departureDateTime).format('HH:mm')}</Typography>
          <Typography type="main" weight={600}>
            {dayjs(item?.departureDateTime).format('DD MMM')}
          </Typography>

          <div className={s.hide}>
            {item?.travelDuration && (
              <div className={s.duration}>
                <Typography type="small" color="green">
                  {getTimeTrip(item?.travelDuration)}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div>
          <Typography type="main" color="primary">
            {item?.fromCity},
          </Typography>
          <Typography type="small" color="primary">
            {item?.fromStation}
          </Typography>
        </div>
        <div className={s.hide}>
          <RouteServices
            handleGetServices={handleGetServices}
            isShowServices={isShowServices}
            services={item['carrier'].services}
            setOpenScheduleModal={setOpenScheduleModal}
          />
        </div>
      </div>
      <div>
        <div className={s.tripDate}>
          <Typography type="h3">{dayjs(item?.arrivalDate).format('HH:mm')}</Typography>
          <Typography type="main" weight={600}>
            {dayjs(item?.arrivalDate).format('DD MMM')}
          </Typography>
        </div>
        <div>
          <Typography type="main" color="primary">
            {item?.toCity},
          </Typography>
          <Typography type="small" color="primary">
            {item?.toStation}
          </Typography>
        </div>
      </div>
      <div className={s.mobile}>
        {item?.travelDuration && (
          <div className={s.duration}>
            <Typography type="small" color="green">
              {getTimeTrip(item?.travelDuration)}
            </Typography>
          </div>
        )}
        <RouteServices
          handleGetServices={handleGetServices}
          isShowServices={isShowServices}
          services={item['carrier']?.services}
          setOpenScheduleModal={setOpenScheduleModal}
        />
      </div>
      <div className={s.price}>
        <Typography type="h3" weight={600}>
          {`${item?.price.value}
          ${item?.price.currency}`}
        </Typography>
        <SelectRouteButton onClick={handleSelectRoute} />
      </div>

      <ScheduleModal
        open={openScheduleModal}
        onClose={() => setOpenScheduleModal(false)}
        schedule={[
          { city: item?.fromCity, station: item?.fromStation, time: item?.departureTime || '' },
          ...(item?.tripDetails?.timetable?.map((trip) => ({
            city: trip.city,
            station: trip.station,
            time: trip.departureTime,
            change: trip.change,
          })) || []),
          { city: item?.toCity, station: item?.toStation, time: item?.arrivalTime || '' },
        ]}
      />
    </div>
  );
};

export default TopTicketItem;
