import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/index';

import { useLazyGetCarriersQuery } from 'services/carriers/carriersApiService';

import ComponentWithContainer from 'components/componentWithContainer';

import { CREATE_CARRIER_ROUTE } from '../../routes/core/list';
import CarriersList from './carriers-list';

// import CreateNewCarrierModal from './create-new-carrier-modal';

const Carriers: FC = () => {
  const [getCarriers, { isFetching: carrierFetching, data: carrierList }] =
    useLazyGetCarriersQuery();
  // const [openedCreatedModal, setOpenedCreatedModal] = useState(false);
  const navigate = useNavigate();

  const handleGoToCreateCarrier = () => {
    navigate(CREATE_CARRIER_ROUTE);
  };

  useEffect(() => {
    getCarriers();
  }, [getCarriers]);

  const createNewCarrierActionsButton = (
    <>
      <Button onClick={handleGoToCreateCarrier}>Додати нового перевізника</Button>
    </>
  );

  return (
    <ComponentWithContainer title="Перевізники" actions={createNewCarrierActionsButton}>
      <CarriersList list={carrierList} fetching={carrierFetching} />

      {/*<CreateNewCarrierModal*/}
      {/*  open={openedCreatedModal}*/}
      {/*  onClose={() => setOpenedCreatedModal(false)}*/}
      {/*/>*/}
    </ComponentWithContainer>
  );
};

export default Carriers;
