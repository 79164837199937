import { FC, memo } from 'react';

import { Table as TableAntd, TableProps as TablePropsAntd } from 'antd';
import 'antd/lib/table/style/css';

import './Table.module.scss';

// eslint-disable-next-line
export type TableProps<RecordType extends Record<any, any> = any> = TablePropsAntd<RecordType>;

const Table: FC<TableProps> = (props) => {
  return <TableAntd {...props} />;
};

// export type { TTableProps }

export default memo(Table);
