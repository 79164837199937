export const getDropdownItems = (isActive) => [
  {
    label: 'Видалити користувача',
    key: 'deleteUser',
  },
  {
    label: isActive ? 'Деактивувати користувача' : 'Активувати користувача',
    key: isActive ? 'deactivateUser' : 'activateUser',
  },
];
