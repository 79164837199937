import { SERVICES_TAGS } from '../../../constants/servicesTags';
import {
  CreateContraBusBookingPayloadType,
  CreateContraBusBookingResponseType,
  CreateTopTicketBookingPayloadType,
  CreateTopTicketBookingResponseType,
  GetContraBusBookingsResponseType,
  GetReportsResponseType,
  GetTopTicketBookingsPropsType,
  GetTopTicketBookingsResponseType,
} from 'services/bookings/bookings.api.type';
import initialApiService from 'services/initialApiService';

import { TopTicketBookingType } from 'types/bookings/bookingsTypes';

const bookingsApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getBookings: builder.query<
      GetContraBusBookingsResponseType,
      { start_date?: string; final_date?: string; search_field?: string }
    >({
      query: (body) => ({
        url: '/info/bookings/contrabus',
        method: 'POST',
        body,
      }),
      providesTags: [SERVICES_TAGS.GET_BOOKINGS],
    }),
    createTopTicketBooking: builder.mutation<
      CreateTopTicketBookingResponseType,
      CreateTopTicketBookingPayloadType
    >({
      query: ({ routeId, tripDate, ...body }) => ({
        url: `/bookings/${routeId}/${tripDate}`,
        method: 'POST',
        body,
      }),
    }),
    createContraBusBooking: builder.mutation<
      CreateContraBusBookingResponseType,
      CreateContraBusBookingPayloadType
    >({
      query: (body) => ({
        url: `/info/contrabus/booking/create`,
        method: 'POST',
        body,
      }),
    }),
    getTopTicketBookings: builder.query<
      GetTopTicketBookingsResponseType,
      GetTopTicketBookingsPropsType
    >({
      query: (params) => ({
        url: '/bookings',
        method: 'GET',
        params,
      }),
      providesTags: [SERVICES_TAGS.GET_BOOKINGS],
    }),
    getReportsCSV: builder.query<Blob, { from: string; to: string }>({
      query: (params) => ({
        url: '/bookings/report',
        cache: 'no-cache',
        params,
      }),
    }),
    getReport: builder.query<
      GetReportsResponseType,
      { startDate?: string; endDate?: string; userId?: string }
    >({
      query: (params) => ({
        url: '/bookings/report',
        method: 'GET',
        params,
      }),
    }),
    getBookingById: builder.query<TopTicketBookingType, string>({
      query: (id) => ({
        url: `/bookings/getById/${id}`,
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.GET_BOOKING_BY_ID],
    }),
    editBooking: builder.mutation<
      TopTicketBookingType,
      { id: string; firstName: string; lastName: string; phone: string; comment: string }
    >({
      query: (body) => ({
        url: `/bookings/edit/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_BOOKING_BY_ID],
    }),
    changeStatus: builder.mutation<void, { id: string; action: string }>({
      query: ({ id, ...body }) => ({
        url: `/bookings/${id}/status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_BOOKINGS],
    }),
    changeContraBusStatus: builder.mutation<void, { id: string; status: string }>({
      query: (body) => ({
        url: `/info/contrabus/booking/changeStatus`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_BOOKINGS],
    }),
    cancelContraBusBooking: builder.mutation<void, { id: string; description: string }>({
      query: (body) => ({
        url: `/info/contrabus/booking/cancel`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_BOOKINGS],
    }),
  }),
});

export const {
  useLazyGetBookingsQuery,
  useCreateTopTicketBookingMutation,
  useLazyGetTopTicketBookingsQuery,
  useLazyGetReportQuery,
  useCreateContraBusBookingMutation,
  useLazyGetBookingByIdQuery,
  useEditBookingMutation,
  useChangeStatusMutation,
  useChangeContraBusStatusMutation,
  useCancelContraBusBookingMutation,
} = bookingsApiService;
