export enum USER_ROLES {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  AGENT = 'AGENT',
  PARTNER = 'PARTNER',
}

export type CurrentUserType = {
  fullName: string;
  email: string;
  roles?: USER_ROLES;
  id: string;
  firstName: string;
  lastName: string;
  carrierId?: string;
  active?: boolean;
};
