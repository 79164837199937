import { Dispatch, FC, SetStateAction } from 'react';

import { Form, FormInstance, Input, Label, Select, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { CityType } from 'types/cities/citiesTypes';

import { RouteBaseInfoType, TripDetailsType } from '../routesCreateForm.utils';
import s from './RoutesCreateFormTripDetails.module.scss';
import { frequencySelectOptions } from './routesCreateFormTripDetails.utils';
import TripDetailsTimetable from './trip-details-timetable';

interface RoutesCreateFormTripDetailsProps {
  form: FormInstance;
  setTripDetails: Dispatch<SetStateAction<TripDetailsType>>;
  baseInfo?: RouteBaseInfoType;
  tripDetails: TripDetailsType;
  isEdit: boolean;
  citiesList?: CityType[];
}

const RoutesCreateFormTripDetails: FC<RoutesCreateFormTripDetailsProps> = (props) => {
  const { form, setTripDetails, baseInfo, tripDetails, isEdit, citiesList } = props;

  return (
    <>
      <Form form={form}>
        <div className={s.wrap}>
          <div>
            <Typography type="h6">Тривалість поїздки</Typography>
            <div className={s.content}>
              <div className={s.durationBlock}>
                <div>
                  <Label>Дні</Label>
                  <Form.Item name={['travelDuration', 'days']}>
                    <Input.Number placeholder="0" />
                  </Form.Item>
                </div>
                <div>
                  <Label>Години</Label>
                  <Form.Item name={['travelDuration', 'hours']}>
                    <Input.Number placeholder="0" />
                  </Form.Item>
                </div>
                <div>
                  <Label>Хвилини</Label>
                  <Form.Item name={['travelDuration', 'mins']}>
                    <Input.Number placeholder="0" />
                  </Form.Item>
                </div>
              </div>
              <div>
                <Label required>Кількість місць в автобусі</Label>
                <Form.Item name="seats" rules={[formRules.required]}>
                  <Input.Number name="seats" placeholder="0" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={s.item}>
            <Label required>Періодичність рейсу</Label>
            <Form.Item name={['tripDetails', 'frequency']} rules={[formRules.required]}>
              <Select
                mode="multiple"
                placeholder="Оберіть періодичність рейсу"
                showArrow
                options={frequencySelectOptions}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      <TripDetailsTimetable
        setTripDetails={setTripDetails}
        baseInfo={baseInfo}
        tripDetails={tripDetails}
        isEdit={isEdit}
        citiesList={citiesList}
      />
    </>
  );
};

export default RoutesCreateFormTripDetails;
