import React, { Dispatch, FC, SetStateAction, useCallback, useRef, useState } from 'react';

import { Divider, InputRef, Space } from 'antd';
import { Button, Input } from 'ui/index';

interface AddedNewSaleFieldProps {
  setSalesList: Dispatch<SetStateAction<{ value: string; name: string }[]>>;
}

const AddedNewSaleField: FC<AddedNewSaleFieldProps> = ({ setSalesList }) => {
  const inputRef = useRef<InputRef>(null);
  const [discountItem, setDiscountItem] = useState<{ value?: string; label?: string }>();
  const onDiscountChange = useCallback(
    (event, field: 'value' | 'label') => {
      if (field === 'label') {
        if (discountItem?.value) {
          setDiscountItem((prevState) => ({
            value: `${prevState?.label ? prevState?.label.split(' ')[0] : ''}${prevState?.value}`,
            label: event.target.value,
          }));
        }

        setDiscountItem((prevState) => ({ ...prevState, label: event.target.value }));
      }

      if (field === 'value') {
        setDiscountItem((prevState) => ({
          ...prevState,
          value: `${prevState?.label ? prevState?.label.split(' ')[0] : ''}_${event}`,
        }));
      }
    },
    [discountItem],
  );
  const addItem = () => {
    if (discountItem?.value && discountItem?.label) {
      const res = {
        value: discountItem.value,
        name: `${discountItem.label} - ${discountItem.value.split('_')[1]}%`,
      };
      setSalesList((prevState) => [...prevState, res]);
    }
    setDiscountItem({});
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <div>
      <Divider style={{ margin: '8px 0' }} />
      <Space style={{ padding: '0 8px 4px', width: '100%' }}>
        <Input.Number
          name="valueDesc"
          placeholder="0"
          max={100}
          min={0}
          onChange={(e) => onDiscountChange(e, 'value')}
          value={parseInt((discountItem?.value || '')?.split('_')[1], 10)}
        />
        <Input
          name="nameDisc"
          placeholder="Веддіть назву знижки"
          ref={inputRef}
          value={discountItem?.label || ''}
          onChange={(e) => onDiscountChange(e, 'label')}
          style={{ width: 200 }}
        />
        <Button onClick={addItem} disabled={!discountItem?.label || !discountItem?.value}>
          Додати знижку
        </Button>
      </Space>
    </div>
  );
};

export default AddedNewSaleField;
