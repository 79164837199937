export const frequencySelectOptions = [
  { value: 1, label: 'Понеділок' },
  { value: 2, label: 'Вівторок' },
  { value: 3, label: 'Середа' },
  { value: 4, label: 'Четвер' },
  { value: 5, label: "П'ятниця" },
  { value: 6, label: 'Субота' },
  { value: 0, label: 'Неділя' },
  // { value: [0, 1, 2, 3, 4, 5, 6], label: 'Кожен день' },
];

export const refundPercentSelectOptions = [
  { value: '0', label: '0%' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
  { value: 25, label: '25%' },
  { value: 30, label: '30%' },
  { value: 35, label: '35%' },
  { value: 40, label: '40%' },
  { value: 45, label: '45%' },
  { value: 50, label: '50%' },
  { value: 55, label: '55%' },
  { value: 60, label: '60%' },
  { value: 65, label: '65%' },
  { value: 70, label: '70%' },
  { value: 75, label: '75%' },
  { value: 80, label: '80%' },
  { value: 85, label: '85%' },
  { value: 90, label: '90%' },
  { value: 95, label: '95%' },
  { value: 100, label: '100%' },
];

export const refundTimeSelectOptions = [
  { value: 1, label: '1 година' },
  { value: 12, label: '12 годин' },
  { value: 24, label: '24 години' },
  { value: 48, label: '48 години' },
  { value: '0', label: 'Менше одної години' },
  { value: 100, label: 'Відміна рейсу перевізником' },
];

export const discountSelectOptions = [
  { value: 'student_10', label: 'Студент ISIC до 26 років - 10%' },
  { value: 'pensioner_10', label: 'Пенсіонери (віком від 60 років) - 10%' },
  { value: 'group_15', label: 'Групи від 5 осіб - 15%' },
  { value: 'animal_20', label: 'Знижка на перевезення тварин - 20%' },
  { value: 'children_20', label: 'Діти від 7 до 15 років - 20%' },
  { value: 'children_50', label: 'Діти - до 6 років - 50%' },
];

export enum DISCOUNT_ENUM {
  student = 10,
  pensioner = 10,
  group = 15,
  animals = 20,
  junior = 20,
  kids = 50,
}

export const getHoursRefund = (hours: number) => {
  let hoursString: string;
  if (hours % 10 === 1 && hours !== 11) {
    hoursString = `годину`;
  } else if (
    (hours % 10 === 2 && hours !== 12) ||
    (hours % 10 === 3 && hours !== 13) ||
    (hours % 10 === 4 && hours !== 14)
  ) {
    hoursString = `години`;
  } else {
    hoursString = `годин`;
  }

  return `${hours} ${hoursString}`;
};
