import { forwardRef, memo } from 'react';

import { Checkbox as CheckboxAntd, CheckboxProps as CheckboxPropsAntd } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import 'antd/lib/checkbox/style/css';
import cn from 'classnames';

import s from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<CheckboxPropsAntd, 'onChange'> {
  onChange?: (checked: boolean) => void;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { children, onChange = () => {}, value = '', className = '', ...otherProps } = props;

  const handleChange = (event: CheckboxChangeEvent) => {
    onChange(event.target.checked);
  };

  return (
    <CheckboxAntd
      ref={ref}
      onChange={handleChange}
      checked={value}
      {...otherProps}
      className={cn(s.wrapper, { [className]: className })}>
      {children}
    </CheckboxAntd>
  );
});

export default memo(Checkbox);
