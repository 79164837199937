import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTE_CREATE_ROUTE } from 'routes/routes-list/list';
import { Button } from 'ui';

import { useLazyGetCarriersQuery } from 'services/carriers/carriersApiService';
import { useLazyGetAllRoutesQuery } from 'services/routes/routesService';

import ComponentWithContainer from 'components/componentWithContainer';
import RoutesListTable from 'containers/routes-list-table';

const RoutesList: FC = () => {
  const navigate = useNavigate();
  const [getAllRoutes, { data: routesList }] = useLazyGetAllRoutesQuery();
  const [getCarriers, { data: carrierList }] = useLazyGetCarriersQuery();

  const handleGoToCreateRoute = () => {
    navigate(ROUTE_CREATE_ROUTE);
  };

  useEffect(() => {
    getAllRoutes();
    getCarriers();
  }, [getAllRoutes, getCarriers]);

  const createNewRoute = (
    <>
      <Button onClick={handleGoToCreateRoute}>Створити новий маршрут</Button>
    </>
  );

  return (
    <ComponentWithContainer title="Маршрути" actions={createNewRoute}>
      {!!routesList?.length && (
        <RoutesListTable routesList={routesList} carrierList={carrierList} />
      )}
    </ComponentWithContainer>
  );
};

export default RoutesList;
