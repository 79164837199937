import { FC, useEffect, useState } from 'react';

import { Button, Table, Typography } from 'ui';

import { useLazyGetAllUserQuery } from 'services/user-management/userManagementApiService';

import AgentTableDropdown from 'pages/user-settings/user-setting-content/agents-setting/agent-table-dropdown';
import InvateUserModal from 'pages/user-settings/user-setting-content/agents-setting/invate-user-modal/invateUserModal';

import { CarrierTypes } from 'types/carriers/carriersTypes';

import s from './AgentsSetting.module.scss';

const AgentsSetting: FC = () => {
  const [getAllUser, { data: allUser }] = useLazyGetAllUserQuery();
  const [openedInvateUserModal, setOpenedInvateUserModal] = useState(false);

  const handleOpenInvateUserModal = () => {
    setOpenedInvateUserModal(true);
  };

  const handleCloseInvateUserModal = () => {
    setOpenedInvateUserModal(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Імя',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: string, row) => (
        <span>
          {row.firstName} {row.lastName}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Перевізник',
      dataIndex: 'carrierData',
      key: 'carrierData',
      render: (text: CarrierTypes) => <div>{text?.name || '-'}</div>,
    },
    {
      title: 'Дії',
      render: (row) => <AgentTableDropdown user={row} />,
    },
  ];

  useEffect(() => {
    getAllUser();
  }, [getAllUser]);

  return (
    <div>
      <div className={s.row}>
        <Typography type="h5">Список користувачів</Typography>
        <Button onClick={handleOpenInvateUserModal}>Запросити користувача</Button>
      </div>
      <Table dataSource={allUser} columns={columns} rowKey="id" scroll={{ x: 600 }} />
      <InvateUserModal open={openedInvateUserModal} onClose={handleCloseInvateUserModal} />
    </div>
  );
};

export default AgentsSetting;
