import { FC } from 'react';

import { Timeline } from 'antd';
import { Modal, Typography } from 'ui';

import { ContraBusScheduleType } from 'types/routes/contra-bus/contraBusRoutesTypes';

import s from './Schedule.module.scss';

interface ScheduleModalProps {
  open: boolean;
  onClose: () => void;
  schedule?: ContraBusScheduleType[];
  changeInfo?: string;
}

const ScheduleModal: FC<ScheduleModalProps> = (props) => {
  const { open, onClose, schedule, changeInfo } = props;
  return (
    <Modal handleClose={onClose} open={open} footer={false}>
      <Modal.Header>
        <Typography type="h5">Розклад Маршруту</Typography>
      </Modal.Header>
      <Modal.Content>
        {!!changeInfo && <Typography type="h5">{changeInfo}</Typography>}
        <div className={s.scheduleWrap}>
          <Timeline mode="left" className={s.timeline}>
            {schedule?.map((item) => (
              <Timeline.Item
                key={item.time}
                label={item.time}
                color={item?.change ? '#386ff6' : 'grey'}>
                {item.city} - {item.station}
                {item?.change && (
                  <Typography type="small" color="grey">
                    Пересадка
                  </Typography>
                )}
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ScheduleModal;
