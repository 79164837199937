export const BOOKING_ROUTE = '/ticket-booking';

export const CARRIER_COMFORT = '/carrier';

export const CREATE_CARRIER_ROUTE = '/carrier/create';

export const REPORTS_ROUTE = '/reports';

export const SALES_ROUTE = '/sales';

export const TICKETS_ROUTE = '/tickets';

export const EDIT_TICKET_ROUTE = `${TICKETS_ROUTE}/:id`;

export const FULL_CARRIER_INFO = `${CARRIER_COMFORT}/:id`;

export const getFullInfoRoute = (id: string) => `${CARRIER_COMFORT}/${id}`;

export const CREATE_BOOKING_ROUTE = `${BOOKING_ROUTE}/create`;
