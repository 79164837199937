import {
  BOOKING_ROUTE,
  CARRIER_COMFORT,
  REPORTS_ROUTE,
  SALES_ROUTE,
  TICKETS_ROUTE,
} from 'routes/core/list';
import { ROUTE_LIST_ROUTE } from 'routes/routes-list/list';

export const NAVIGATIONS_CONFIG = [
  {
    to: BOOKING_ROUTE,
    label: 'Бронювання',
    icon: 'dashboard',
    roles: ['OPERATOR', 'ADMIN', 'PARTNER', 'AGENT', 'CARRIER', 'GLOBAL_ADMIN'],
  },
  {
    to: ROUTE_LIST_ROUTE,
    label: 'Маршрути',
    icon: 'routesList',
    roles: ['ADMIN', 'PARTNER', 'GLOBAL_ADMIN'],
  },
  {
    to: TICKETS_ROUTE,
    label: 'Список квитків',
    icon: 'calendar',
    roles: ['OPERATOR', 'ADMIN', 'PARTNER', 'AGENT', 'CARRIER', 'GLOBAL_ADMIN'],
  },
  {
    to: SALES_ROUTE,
    label: 'Знижки',
    icon: 'myEvents',
    roles: ['ADMIN', 'GLOBAL_ADMIN'],
  },
  {
    to: REPORTS_ROUTE,
    label: 'Звітність',
    icon: 'ticketOrders',
    roles: ['ADMIN', 'PARTNER', 'GLOBAL_ADMIN', 'AGENT'],
  },
  {
    to: CARRIER_COMFORT,
    label: 'Перевізники',
    icon: 'ticketScan',
    roles: ['ADMIN', 'GLOBAL_ADMIN'],
  },
];
