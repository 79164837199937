export enum SETTING_TABS_ENUM {
  PROFILE = 'profile',
  AGENTS = 'agents',
  API_KEYS = 'apiKeys',
}

export const getSettingTabsConfiguration = (roles) => {
  return [
    {
      label: 'Налаштування профілю',
      key: SETTING_TABS_ENUM.PROFILE,
    },
    {
      label: 'Налаштування користувачів',
      key: SETTING_TABS_ENUM.AGENTS,
      disabled: !roles?.includes('ADMIN'),
    },
    // {
    //   label: 'Налаштування API ключів',
    //   key: SETTING_TABS_ENUM.API_KEYS,
    // },
  ];
};
