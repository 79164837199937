import { FC, useEffect } from 'react';

import { Button, Form, Input, Label, Modal, Select, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { useLazyGetCarriersQuery } from 'services/carriers/carriersApiService';
import { useInviteUserMutation } from 'services/user-management/userManagementApiService';

import { getRolesLabel } from 'pages/user-settings/user-setting-content/agents-setting/invate-user-modal/InvateUserModal.utils';

import { USER_ROLES } from 'types/user-management/core';

import s from './InvateUserModal.module.scss';

interface InvateUserModalProps {
  open: boolean;
  onClose: () => void;
}
const InvateUserModal: FC<InvateUserModalProps> = (props) => {
  const { open, onClose } = props;
  const [form] = Form.useForm();
  const [invateUser, { isLoading }] = useInviteUserMutation();
  const [getCarriers, { data: carrierList }] = useLazyGetCarriersQuery();
  const selectedRole = Form.useWatch('role', form)?.value;

  const handleSubmit = async ({ email, role, carrier }) => {
    const res = await invateUser({ email: email, role: role.value, carrier: carrier?.value });

    if ('data' in res) {
      onClose();
      form.resetFields();
    }
  };

  useEffect(() => {
    if (selectedRole === 'PARTNER') {
      getCarriers();
    }
  }, [getCarriers, selectedRole]);

  return (
    <Modal handleClose={onClose} open={open} footer={false}>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Header>
          <Typography type="h5">Запросити користувача</Typography>
        </Modal.Header>
        <Modal.Content className={s.content}>
          <div className={s.item}>
            <Label required>Email</Label>
            <Form.Item name="email" rules={[formRules.email, formRules.required]}>
              <Input name="email" placeholder="example@email.com" />
            </Form.Item>
          </div>
          <div className={s.item}>
            <Label required>Роль</Label>
            <Form.Item name="role">
              <Select
                options={Object.keys(USER_ROLES).map((item) => ({
                  label: getRolesLabel[item],
                  value: item,
                }))}
                placeholder="Виберіть роль"
              />
            </Form.Item>
          </div>
          {selectedRole === 'PARTNER' && (
            <div className={s.item}>
              <Label required>Перевізник</Label>
              <Form.Item name="carrier" rules={[formRules.required]}>
                <Select
                  placeholder="Виберіть перевізника"
                  options={carrierList?.map((item) => ({ label: item.name, value: item.id }))}
                />
              </Form.Item>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button htmlType="submit" isLoading={isLoading}>
            Відправити запрошення
          </Button>
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default InvateUserModal;
