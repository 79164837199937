import { FC, SVGProps } from 'react';

import { ReactComponent as AddStop } from './addstop.svg';
import { ReactComponent as Air } from './air.svg';
import { ReactComponent as Drinks } from './drinks.svg';
import { ReactComponent as ElectronicTicket } from './electronicTicket.svg';
import { ReactComponent as Gps } from './gps.svg';
import { ReactComponent as Music } from './music.svg';
import { ReactComponent as Passport } from './passport.svg';
import { ReactComponent as PaymentInDriver } from './paymentInDriver.svg';
import { ReactComponent as Pets } from './pets.svg';
import { ReactComponent as Power } from './power.svg';
import { ReactComponent as Toilet } from './toilet.svg';
import { ReactComponent as Tv } from './tv.svg';
import { ReactComponent as WiFi } from './wifi.svg';

export const servicesIcon: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  wifi: WiFi,
  air: Air,
  addstop: AddStop,
  gps: Gps,
  passport: Passport,
  pets: Pets,
  power: Power,
  wc: Toilet,
  music: Music,
  tv: Tv,
  drinks: Drinks,
  electronicTicket: ElectronicTicket,
  paymentInDriver: PaymentInDriver,
};
