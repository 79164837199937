import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Layout, Space } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import useHover from 'tools/hooks/useHover.hook';
import { Icon, NavLink, Typography } from 'ui';

import { systemSliceSelector } from 'selectors/systemSlice.selector';
import {
  useLazyGetCurrentUserQuery,
  useLogoutMutation,
} from 'services/user-management/userManagementApiService';
import { setIsOpenedMobileMenu } from 'slices/systemSlice';
import { logout } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { LOGIN_ROUTE } from '../../routes/user-management/list';
import s from './AppNavigation.module.scss';
import { NAVIGATIONS_CONFIG } from './appNavigation.utils';

const AppNavigation: FC = () => {
  const navigate = useNavigate();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const hoverRef = useRef(null);
  const isOpenMenu = useAppSelector(systemSliceSelector).isOpenedMobileMenu;
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const isHoverSider = useHover(hoverRef);
  const [logoutUser] = useLogoutMutation();

  const handleLogout = useCallback(async () => {
    const res = await logoutUser();

    navigate(LOGIN_ROUTE);

    if ('data' in res) {
      dispatch(logout());
    }
  }, [dispatch, logoutUser, navigate]);

  useEffect(() => {
    if (isHoverSider && !isOpenMenu) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }

    if (isOpenMenu) {
      setCollapsed(false);
    }
  }, [isHoverSider, isOpenMenu]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const handleCloseMenu = () => {
    dispatch(setIsOpenedMobileMenu(false));
  };

  return (
    <>
      <div
        className={cn(isOpenMenu && s.shadow)}
        onClick={handleCloseMenu}
        role="button"
        onKeyDown={() => null}
        tabIndex={-1}></div>
      <Layout.Sider
        className={cn(s.sider, {
          [s.closed]: collapsed,
          [s.opened]: isOpenMenu,
          [s.hover]: isHoverSider,
        })}
        trigger={null}
        collapsible
        collapsed={collapsed}
        ref={hoverRef}>
        <Space direction="vertical" className={cn(s.sider, { [s.opened]: isOpenMenu })}>
          <div className={s.content}>
            {NAVIGATIONS_CONFIG.map(
              (config) =>
                config?.roles?.includes(currentUser?.roles || '') && (
                  <NavLink
                    label={config.label}
                    to={config.to}
                    icon={config.icon}
                    key={config.label}
                    closed={collapsed}
                  />
                ),
            )}
            <button onClick={handleLogout} className={cn(s.actions, { [s.closed]: collapsed })}>
              <Typography type="main" color="red" weight={500} className={s.logout}>
                <Icon name="logout" />
                <span className={cn({ [s.hide]: collapsed })}>Вийти</span>
              </Typography>
            </button>
          </div>
        </Space>
      </Layout.Sider>
    </>
  );
};

export default AppNavigation;
