import { FC, useCallback } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { TICKETS_ROUTE } from 'routes/core/list';
import { Button, Icon, Typography } from 'ui';

import { PassengerType } from '../../bookingCreateForm';
import s from './BookingCreateFormSuccessStep.module.scss';

interface BookingCreateFormSuccessStepProps {
  booking?: PassengerType;
}

const BookingCreateFormSuccessStep: FC<BookingCreateFormSuccessStepProps> = (props) => {
  const { booking } = props;
  const navigate = useNavigate();

  const handleGoToBookings = useCallback(() => {
    navigate(TICKETS_ROUTE);
  }, [navigate]);

  return (
    <div className={s.wrapper}>
      <Icon name="success" size={128} />
      <Typography type="h3">Бронювання прошло успішно!</Typography>
      <div className={s.result}>
        <Typography type="h4" className={s.title}>
          {!!booking?.length && booking?.length > 1 ? 'Пасажири' : 'Пасажир'}
        </Typography>
        <div className={s.passengers}>
          <div className={cn(s.item, s.head)}>
            <div>{`Ім'я`}</div>
            <div>Прізвище</div>
            <div>Телефон</div>
          </div>
          <div className={s.passengersBody}>
            {booking?.map((booking, i) => (
              <div key={`${new Date().getTime()}-${i}`} className={cn(s.item, s.body)}>
                <div>{booking.firstName}</div>
                <div>{booking.lastName}</div>
                <div>{booking.phone}</div>
              </div>
            ))}
          </div>
        </div>
        <Button onClick={handleGoToBookings}>Перейти до бронювань</Button>
      </div>
    </div>
  );
};

export default BookingCreateFormSuccessStep;
