import { FC, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { TICKETS_ROUTE } from 'routes/core/list';
import { Button, DatePicker, Form, Icon, Select, Typography } from 'ui';

import {
  useLazySearchContraBusRoutesQuery,
  useLazySearchKlrRoutesQuery,
  useLazySearchTopTicketsRoutesQuery,
} from 'services/routes/routesService';

import CityDropdown from 'containers/city-dropdown';

import { CityType } from 'types/cities/citiesTypes';
import { KlrRouteType, RouteType } from 'types/routes/routesTypes';
import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

import s from './Booking.module.scss';
import BookingList from './booking-list';

const Booking: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchCities, setSearchCities] = useState<{ from?: CityType; to?: CityType }>({});
  const [searchContraBusRoutes, { isFetching: isContraBusFetching }] =
    useLazySearchContraBusRoutesQuery();
  const [searchKlrRoutes, { isFetching: isKlrFetching }] = useLazySearchKlrRoutesQuery();
  const [searchTopTicketRoutes, { isFetching: isTopTicketFetching }] =
    useLazySearchTopTicketsRoutesQuery();
  const [routesList, setRoutesList] = useState<{
    klr?: KlrRouteType[];
    contraBus?: RouteType[];
    topTicket?: FullRouteType[];
  }>();

  const fetching = {
    topTicket: isTopTicketFetching,
    contraBus: isContraBusFetching,
    klr: isKlrFetching,
  };

  const handleSelectCity = (city: CityType, field) => {
    if (field === 'from') {
      setSearchCities({ ...searchCities, from: city });
    }
    if (field === 'to') {
      setSearchCities({ ...searchCities, to: city });
    }
  };

  const handleSubmit = (data) => {
    if (searchCities?.from && searchCities?.to) {
      const req = {
        fromCity: searchCities.from.name,
        toCity: searchCities.to.name,
        tripDate: dayjs(data.date).format('YYYY-MM-DD'),
      };
      searchTopTicketRoutes(req).then((data) => {
        if (data.data) {
          setRoutesList((prevState) => ({ ...prevState, topTicket: data.data }));
        }
      });

      if ('contraBusCityId' in searchCities.from && 'contraBusCityId' in searchCities.to) {
        const requestBody = {
          from_id: searchCities.from.contraBusCityId,
          to_id: searchCities.to.contraBusCityId,
          date: dayjs(data.date).format('DD.MM.YYYY'),
          passengers: data.passengers.value,
        };
        searchContraBusRoutes(requestBody).then((data) => {
          if (data.data) {
            setRoutesList((prevState) => ({ ...prevState, contraBus: data.data }));
          }
        });
      }
      if ('klrCityId' in searchCities.from && 'klrCityId' in searchCities.to) {
        const requestBody = {
          from_city_id: searchCities.from.klrCityId,
          to_city_id: searchCities.to.klrCityId,
          departure_date: dayjs(data.date).format('YYYY-MM-DD'),
        };
        searchKlrRoutes(requestBody).then((data) => {
          if (data.data) {
            if (data.data.count > 0) {
              setRoutesList((prevState) => ({ ...prevState, klr: data?.data?.items }));
            } else {
              setRoutesList((prevState) => ({ ...prevState, klr: [] }));
            }
          }
        });
      }
    }
  };
  const handleGoToTickets = () => {
    navigate(TICKETS_ROUTE);
  };

  const handleChangeSelectedCity = useCallback(() => {
    setSearchCities({
      from: searchCities?.to || ({} as CityType),
      to: searchCities?.from || ({} as CityType),
    });
  }, [searchCities]);

  const handleChangeDate = useCallback(
    (date: string) => {
      form.setFieldsValue({ date: dayjs(date) });
    },
    [form],
  );

  return (
    <>
      <div className={s.back}></div>
      <div className={s.container}>
        <div className={s.title}>
          <Typography type="h3">Бронювання</Typography>
          <Button onClick={handleGoToTickets} className={s.actions}>
            Список бронювань
          </Button>
        </div>
        {/*<img src={Bg} alt="" className={s.bg} />*/}
        <div className={s.block}>
          <div className={s.wrapper}>
            <Form className={s.form} form={form} onFinish={handleSubmit}>
              <div className={s.field}>
                <CityDropdown
                  placeholder="Звідки"
                  name="from"
                  handleSelectedCity={(city) => handleSelectCity(city, 'from')}
                  value={searchCities.from}
                />
                <button className={s.change} type="button" onClick={handleChangeSelectedCity}>
                  <Icon name="change" />
                </button>
              </div>
              <CityDropdown
                placeholder="Куди"
                name="to"
                handleSelectedCity={(city) => handleSelectCity(city, 'to')}
                value={searchCities.to}
              />
              <div className={s.item}>
                <Form.Item fullWidth name="date">
                  <DatePicker fullWidth />
                </Form.Item>
              </div>
              <div className={s.item}>
                <Form.Item name="passengers">
                  <Select
                    options={[
                      { value: 1, label: '1 Пасажир' },
                      { value: 2, label: '2 Пасажири' },
                      { value: 3, label: '3 Пасажири' },
                      { value: 4, label: '4 Пасажири' },
                      { value: 5, label: '5 Пасажирів' },
                    ]}
                    placeholder="Кількість Пасажирів"
                  />
                </Form.Item>
              </div>
              <Button
                fullWidth
                htmlType="submit"
                loading={isContraBusFetching || isKlrFetching || isTopTicketFetching}>
                <Icon name="search" /> Пошук
              </Button>
            </Form>
          </div>
        </div>
        <BookingList {...routesList} handleChangeDate={handleChangeDate} fetching={fetching} />
      </div>
    </>
  );
};

export default Booking;
