import { FC, useCallback, useMemo, useState } from 'react';

import { Dropdown, Icon } from 'ui/index';

import s from './BookingsTableDropdown.module.scss';

interface BookingsTableDropdownProps {
  handleClick: (key: string) => void;
  status: string;
}

const BookingsTableDropdown: FC<BookingsTableDropdownProps> = (props) => {
  const { handleClick } = props;
  const [open, setOpen] = useState<boolean>(false);

  const DROPDOWN_ITEMS = useMemo(
    () => [
      {
        label: 'Підтвердити бронювання',
        key: 'confirmBooking',
      },
      {
        label: 'Відхилити бронювання',
        key: 'cancelBooking',
      },
      {
        label: 'Переоформити бронювання',
        key: 'reBooking',
      },
      {
        label: 'Скопіювати посилання на квиток',
        key: 'copyLink',
      },
      {
        label: 'Роздрукувати квиток',
        key: 'printTicket',
      },
      {
        label: 'Звязатись в Viber',
        key: 'viber',
      },
      {
        label: 'Звязатись в Telegram',
        key: 'telegram',
      },
      {
        label: 'Звязатись в WhatsApp',
        key: 'whatsApp',
      },
    ],
    [],
  );

  const handleItemClick = useCallback(
    (event: { key: string }) => {
      const key = event.key;
      handleClick(key);
      setOpen(false);
    },
    [handleClick],
  );

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      items={DROPDOWN_ITEMS}
      handleItemClick={handleItemClick}
      placement="bottomLeft">
      <button
        className={s.dropdownButton}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <Icon name="verticalDots" />
      </button>
    </Dropdown>
  );
};

export default BookingsTableDropdown;
