import { FC, useCallback, useState } from 'react';

import { Dropdown, Icon } from 'ui/index';

import {
  useChangeActiveStatusMutation,
  useDeleteUserMutation,
} from 'services/user-management/userManagementApiService';

import { CurrentUserType } from 'types/user-management/core';

import s from './AgentTableDropdown.module.scss';
import { getDropdownItems } from './agentTableDropdown.utils';

interface AgentTableDropdownProps {
  user: CurrentUserType;
}

const AgentTableDropdown: FC<AgentTableDropdownProps> = ({ user }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [changeActiveStatus] = useChangeActiveStatusMutation();
  const [deleteUser] = useDeleteUserMutation();

  const handleItemClick = useCallback(
    (event: { key: string }) => {
      const key = event.key;

      switch (key) {
        case 'deactivateUser':
          changeActiveStatus({
            id: user.id,
            active: false,
          });
          break;
        case 'activateUser':
          changeActiveStatus({
            id: user.id,
            active: true,
          });
          break;
        case 'deleteUser':
          deleteUser({ id: user.id });
          break;
      }

      setOpen(false);
    },
    [changeActiveStatus, deleteUser, user],
  );

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      items={getDropdownItems(user.active)}
      handleItemClick={handleItemClick}
      placement="bottomLeft">
      <button
        className={s.dropdownButton}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <Icon name="verticalDots" />
      </button>
    </Dropdown>
  );
};

export default AgentTableDropdown;
