export const getHours = (hours: number) => {
  let hoursString: string;
  if (hours % 10 === 1 && hours !== 11) {
    hoursString = `година`;
  } else if (
    (hours % 10 === 2 && hours !== 12) ||
    (hours % 10 === 3 && hours !== 13) ||
    (hours % 10 === 4 && hours !== 14)
  ) {
    hoursString = `години`;
  } else {
    hoursString = `годин`;
  }

  return `${hours} ${hoursString}`;
};

export const getTimeTrip = (travel: {
  days: number | null;
  hours: number | null;
  mins: number | null;
}) => {
  const parts: string[] = [];
  if (travel?.days) {
    let daysString: string;
    if (travel.days % 10 === 1 && travel.days !== 11) {
      daysString = 'день';
    } else if (
      (travel.days % 10 === 2 && travel.days !== 12) ||
      (travel.days % 10 === 3 && travel.days !== 13) ||
      (travel.days % 10 === 4 && travel.days !== 14)
    ) {
      daysString = 'дні';
    } else {
      daysString = 'днів';
    }
    parts.push(`${travel.days} ${daysString}`);
  }
  if (travel?.hours) {
    parts.push(getHours(travel.hours));
  }
  if (travel?.mins) {
    let minsString: string;
    if (travel.mins === 1) {
      minsString = 'хвилина';
    } else if (travel.mins >= 2 && travel.mins <= 4) {
      minsString = 'хвилини';
    } else {
      minsString = 'хвилин';
    }
    parts.push(`${travel.mins} ${minsString}`);
  }
  const lastPart = parts.pop();
  if (parts.length > 0) {
    return `Подорож буде тривати ${parts.join(', ')} та ${lastPart}.`;
  } else {
    return `Подорож буде тривати ${lastPart}.`;
  }
};
