export const SERVICES_TOOLTIP = {
  wc: 'Туалет в автобусі',
  tv: 'Телебачення',
  wifi: 'Wi-Fi',
  electronicTicket: 'Електронний квиток',
  paymentInDriver: 'Оплата у водія',
  drinks: 'Напої',
  pets: 'Дозволено з тваринами',
  music: 'Музика',
  power: 'Зарядка в автобусі',
  air: 'Кондиціонування',
  addstop: 'Додати зупинку',
  passport: 'Паспортний контроль',
};
