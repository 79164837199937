import { ReactElement, forwardRef } from 'react';

import cn from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';

import Icon, { TIconsLiteral } from '../icon';
import Typography, { TypographyTypes } from '../typography';
import s from './NavLink.module.scss';

export interface NavLinkProps {
  label: string | ReactElement;
  to: string;
  icon?: TIconsLiteral;
  typographyType?: TypographyTypes;
  closed?: boolean;
  typographyClassName?: string;
  className?: string;
}

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const {
    label,
    to = '',
    icon,
    className = '',
    typographyType = 'h6',
    typographyClassName = '',
    closed = false,
  } = props;

  return (
    <RouterNavLink
      ref={ref}
      className={({ isActive }) =>
        cn(s.link, { [className]: className, [s.active]: isActive, [s.closed]: closed })
      }
      to={to}>
      <>
        {icon && <Icon name={icon} size={16} />}
        <Typography type={typographyType} className={typographyClassName}>
          {label}
        </Typography>
      </>
    </RouterNavLink>
  );
});

export default NavLink;
