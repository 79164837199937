import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { USER_SETTING_STEPS_ENUM } from 'types/core/systemTypes';

export const SLICE_NAME = 'systemSlice';

export type SystemSliceType = {
  openedSider: boolean;
  userSettingStep: USER_SETTING_STEPS_ENUM;
  isOpenedMobileMenu: boolean;
};

const initialState: SystemSliceType = {
  openedSider: true,
  userSettingStep: USER_SETTING_STEPS_ENUM.ORGANISER_DETAILS,
  isOpenedMobileMenu: false,
};

export const systemSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOpenedSider: (state, action: PayloadAction<boolean>) => {
      state.openedSider = action.payload;
    },
    setUserSettingStep: (state, action: PayloadAction<USER_SETTING_STEPS_ENUM>) => {
      state.userSettingStep = action.payload;
    },
    setIsOpenedMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.isOpenedMobileMenu = action.payload;
    },
  },
});

export const { setOpenedSider, setUserSettingStep, setIsOpenedMobileMenu } = systemSlice.actions;

export default systemSlice.reducer;
