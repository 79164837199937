import { FC } from 'react';

import { TimePicker } from 'antd';
import type { TimePickerProps } from 'antd';
import cn from 'classnames';

import s from '../DatePicker.module.scss';

interface TimeProps extends TimePickerProps {
  fullWidth?: boolean;
}
const Time: FC<TimeProps> = (props) => {
  const { className = '', fullWidth, ...otherProps } = props;
  return (
    <TimePicker
      className={cn(s.wrapper, { [className]: className, [s.fullWidth]: fullWidth })}
      {...otherProps}
    />
  );
};

export default Time;
