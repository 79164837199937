import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/user-management/list';
import { Button, Form, Icon, Input, Label, Typography } from 'ui';

import { useRegisterMutation } from 'services/user-management/userManagementApiService';

import s from './Register.module.scss';

interface RegisterProps {
  // Define your component props here
}

const Register: FC<RegisterProps> = () => {
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();

  const goToLogin = () => {
    navigate(LOGIN_ROUTE);
  };

  const handleSubmit = async (data) => {
    const res = await register(data);

    if ('data' in res) {
      goToLogin();
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <Icon name="logo" height={34} width={150} />
        </div>
        <div>
          <Button onClick={goToLogin}>Увійти</Button>
        </div>
      </div>
      <div className={s.content}>
        <Form className={s.form} onFinish={handleSubmit}>
          <Typography type="h2">Регістрація</Typography>
          <div className={s.field}>
            <Label required>{`Ім'я`}</Label>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: "Будь ласка, введіть ім'я!" }]}>
              <Input name="firstName" placeholder="Введіть ім'я" />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Label required>Прізвище</Label>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Будь ласка, введіть прізвище!' }]}>
              <Input name="lastName" placeholder="Введіть прізвище" />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Label required>Електронна пошта</Label>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Будь ласка, введіть електронну пошту!' }]}>
              <Input name="email" placeholder="Введіть адресу електронної пошти" />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Label required>Пароль</Label>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Будь ласка, введіть пароль!' }]}>
              <Input.Password name="password" fullWidth />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Label required>Пароль</Label>
            <Form.Item
              name="key"
              rules={[{ required: true, message: 'Будь ласка, введіть секретний код доступ!' }]}>
              <Input name="key" placeholder="Введіть адресу секретний код доступу" />
            </Form.Item>
          </div>

          <Button fullWidth htmlType="submit" isLoading={isLoading}>
            Зареєструватися
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Register;
