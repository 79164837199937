import { FC, useCallback } from 'react';

// import { useNavigate } from 'react-router-dom';
// import { getFullInfoRoute } from 'routes/core/list';
import { getCurrency } from 'tools/getCurrency';
import { Table, Typography } from 'ui/index';

import { CarrierTypes } from 'types/carriers/carriersTypes';
import { FullRouteType, RoutePriceDto } from 'types/routes/top-tickets/topTicketsRoutesTypes';

import RoutesTableActionDropdown from './routes-table-action-dropdown';

interface RoutesListTableProps {
  routesList: FullRouteType[];
  carrierList?: CarrierTypes[];
  carrierName?: string;
}

const RoutesListTable: FC<RoutesListTableProps> = (props) => {
  const { routesList, carrierList, carrierName } = props;
  // const navigate = useNavigate();

  const carrierColumnRender = useCallback(
    (carrier: string) => {
      if (carrierName) {
        return carrierName;
      } else {
        return carrierList?.find((c) => c.id === carrier)?.name;
      }
    },
    [carrierList, carrierName],
  );

  const routesColumns = [
    {
      title: 'Назва маршруту',
      dataIndex: 'routeName',
      key: 'name',
    },
    {
      title: 'Номер Маршруту',
      dataIndex: 'routeNumber',
      key: 'number',
    },
    {
      title: 'Перевізник',
      dataIndex: 'carrier',
      // key: 'carrier',
      render: carrierColumnRender,
    },
    {
      title: 'Ціна',
      dataIndex: 'price',
      key: 'price',
      render: (price: RoutePriceDto) => `${price.value} ${getCurrency(price.currency)}`,
    },
    {
      title: 'Звідки',
      dataIndex: 'fromCity',
      key: 'fromCity',
      render: (fromCity: string, row: FullRouteType) => (
        <div>
          <Typography type="main" weight={500}>
            {fromCity} - {row.departureTime}
          </Typography>
          <Typography type="small" color="grey">
            {row.fromStation}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Куди',
      dataIndex: 'toCity',
      key: 'toCity',
      render: (toCity: string, row: FullRouteType) => (
        <div>
          <Typography type="main" weight={500}>
            {toCity} - {row.arrivalTime}
          </Typography>
          <Typography type="small" color="grey">
            {row.toStation}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Опції',
      key: 'options',
      render: (row: FullRouteType) => (
        <RoutesTableActionDropdown
          routeId={row.id}
          info={{ popular: row.popular, recommended: row.recommended, sale: row.sale }}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={routesList}
        columns={routesColumns}
        rowKey={(data) => `${data.id}.${data.routeNumber}`}
        scroll={{ x: 600 }}
        // onRow={(record) => {
        //   return {
        //     onClick: () => navigate(getFullInfoRoute(record.id)),
        //   };
        // }}
      />
    </div>
  );
};

export default RoutesListTable;
