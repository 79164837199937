export const getCurrency = (currency: string): string => {
  const currencyList = {
    USD: '$',
    EUR: '€',
    UAH: '₴',
    PLN: 'zł',
    CZK: 'Kč',
  };

  return currencyList[currency];
};
