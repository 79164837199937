import {
  BaseQueryApi,
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import axios from 'axios';
import { getAccessToken } from 'tools/cookieTools';

const AUTH_ERROR_CODES = [401];
const token = getAccessToken();
export const baseQueryWithReauth =
  (config: FetchBaseQueryArgs) =>
  async (
    args: FetchArgs | string,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  ) => {
    let result = await fetchBaseQuery(config)(args, api, extraOptions);

    if (result.error && AUTH_ERROR_CODES.includes(result.error.status as number)) {
      try {
        const isUpdated = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}users/refresh`, {
          method: 'POST',
          withCredentials: true,
        });
        if (isUpdated) {
          result = await fetchBaseQuery(config)(args, api, extraOptions);
        } else {
          console.log('logout');
        }
      } catch {
        console.log('logout');
      }
    }

    return result;
  };

export const BASE_QUERY_WITH_AUTH = baseQueryWithReauth({
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  // baseUrl: 'http://localhost:8080/',
  credentials: 'include',
  prepareHeaders: (headers) => {
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
