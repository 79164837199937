import React, { FC, memo } from 'react';

import { Button, Typography } from 'ui';

import s from './Pagination.module.scss';

interface PaginationProps {
  totalElements?: number;
  total?: number;
  currentPage: number;
  showedElements: number | string;
  handlePageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = (props) => {
  const { totalElements, currentPage, total, handlePageChange, showedElements } = props;

  const disablePaginationOnError =
    isNaN(Number(totalElements)) ||
    isNaN(currentPage) ||
    !Number(total) ||
    isNaN(Number(showedElements));

  return (
    <div className={s.wrapper}>
      <Typography type="main" color="secondary">
        {`Показано елементів ${showedElements} з ${totalElements}`}
      </Typography>
      <div className={s.actionGroup}>
        <Button
          size="small"
          color="tertiary"
          disabled={disablePaginationOnError || currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}>
          Попередня
        </Button>
        <Button
          size="small"
          color="tertiary"
          disabled={disablePaginationOnError || currentPage === total}
          onClick={() => handlePageChange(currentPage + 1)}>
          Наступна
        </Button>
      </div>
    </div>
  );
};

export default memo(Pagination);
