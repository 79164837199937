import { RootState } from 'store';

import { USER_SETTING_STEPS_ENUM } from 'types/core/systemTypes';

export const systemSliceSelector = (state: RootState) => state.systemSlice;

export const isOpenedSiderSelector = (state: RootState): boolean =>
  systemSliceSelector(state).openedSider;

export const userSettingStepSelector = (state: RootState): USER_SETTING_STEPS_ENUM =>
  systemSliceSelector(state).userSettingStep;

export const isOpenedMobileMenuSelector = (state: RootState): boolean =>
  systemSliceSelector(state).isOpenedMobileMenu;
