import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Dropdown, Empty, MenuProps } from 'antd';
import useClickOutside from 'tools/hooks/clickOutside.hook';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { Input } from 'ui/index';

import { useLazyGetAllCitiesQuery } from 'services/cities/citiesApiService';

import { CityType } from 'types/cities/citiesTypes';

import s from './CityDropdown.module.scss';

interface CityDropdownProps {
  label?: string;
  placeholder: string;
  name: string;
  handleSelectedCity?: (city: CityType) => void;
  value?: CityType;
  onChange?: (value: CityType) => void;
}

const CityDropdown: FC<CityDropdownProps> = (props) => {
  const {
    label,
    placeholder,
    name,
    handleSelectedCity = () => {},
    value,
    onChange = () => {},
  } = props;
  const [searchCities, setSearchCities] = useState<string>();
  const [getAllCities, { data: citiesList }] = useLazyGetAllCitiesQuery();
  const [cities, setCities] = useState<CityType[]>([]);
  const [selectedCity, setSelectedCity] = useState<CityType>();
  const [openLocationDropdown, setOpenLocationDropdown] = useState<boolean>(false);
  const [searchIsDirty, setSearchIsDirty] = useState<boolean>(false);
  const cityRef = useRef(null);
  const debouncedCity = useDebounce(searchCities, 500);

  const handleSelectCity = useCallback(
    (city: CityType, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      setSelectedCity(city);
      setOpenLocationDropdown(false);
      setSearchCities('');
      handleSelectedCity(city);
      onChange(city);
    },
    [handleSelectedCity, onChange],
  );

  useClickOutside({
    ref: cityRef,
    handler: () => {
      if (openLocationDropdown && window.innerWidth > 1024) {
        setOpenLocationDropdown(false);
        setSearchIsDirty(false);
        setCities([]);
        setSearchCities('');
      }
    },
  });

  const placesList = useMemo<MenuProps['items']>(() => {
    const result = cities?.map((place, i) => ({
      label: (
        <button onClick={(e) => handleSelectCity(place, e)} className={s.cityItem}>
          {place.name}
        </button>
      ),
      key: `${place.id}.${i}`,
    }));

    if (!result?.length && searchIsDirty) {
      return [
        {
          label: (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>Нічого не знайдено</span>}></Empty>
          ),
          key: 0,
        },
      ];
    } else {
      return result;
    }
  }, [cities, handleSelectCity, searchIsDirty]);

  const handleChangeInput = useCallback(
    (e) => {
      setSearchCities(e.target.value);
      setSearchIsDirty(true);

      if (e.target.value === '') {
        setSearchIsDirty(false);
        setCities([]);
        setSelectedCity(undefined);
        onChange({} as CityType);
        handleSelectedCity({} as CityType);
      }
    },
    [handleSelectedCity, onChange],
  );

  useEffect(() => {
    if (debouncedCity) {
      const result = citiesList?.filter((city) =>
        city.name.toLowerCase()?.startsWith((debouncedCity as string).toLowerCase()),
      );

      if (result?.length) {
        setCities(result);
      }
    }
  }, [citiesList, debouncedCity]);

  useEffect(() => {
    if (value) {
      setSelectedCity(value);
    }
  }, [value]);
  useEffect(() => {
    getAllCities();
  }, [getAllCities]);

  return (
    <div className={s.item} ref={cityRef}>
      <div>
        <Dropdown
          menu={{ items: placesList }}
          open={!!placesList?.length && openLocationDropdown}
          overlayClassName={s.open}>
          <div>
            <Input
              name={name}
              label={label}
              value={searchCities || selectedCity?.name || value?.name}
              placeholder={placeholder}
              onFocus={() => setOpenLocationDropdown(true)}
              onChange={handleChangeInput}
              autoComplete="off"
            />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default CityDropdown;
