import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { RouteForBookingType } from 'types/routes/routesTypes';

export type EventsSliceType = {
  activeStep: CREATE_EVENT_FORM_STEPS_ENUM;
  routeForBooking?: RouteForBookingType;
};

export const initialState: EventsSliceType = {
  activeStep: CREATE_EVENT_FORM_STEPS_ENUM.DETAILS,
};

export const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<CREATE_EVENT_FORM_STEPS_ENUM>) => {
      state.activeStep = action.payload;
    },
    setRouteForBooking: (state, action: PayloadAction<RouteForBookingType>) => {
      state.routeForBooking = action.payload;
    },
  },
});

export const { setActiveStep, setRouteForBooking } = eventsSlice.actions;
