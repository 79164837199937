export const SERVICES_TAGS = {
  CREATE_ASSETS: 'CREATE_ASSETS',
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  GET_CARRIERS: 'GET_CARRIERS',
  GET_ROUTES: 'GET_ROUTES',
  GET_DISCOUNTS: 'GET_DISCOUNTS',
  GET_BOOKING_BY_ID: 'GET_BOOKING_BY_ID',
  GET_BOOKINGS: 'GET_BOOKINGS',
} as const;
