import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import cn from 'classnames';
import { Dayjs } from 'dayjs';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { DatePicker, Form, Icon, Input, Label } from 'ui/index';

import s from './FilterList.module.scss';

interface FilterListProps {
  setRequestFilter: Dispatch<
    SetStateAction<{
      page?: number;
      limit?: number;
      sort?: string;
      order?: string;
      startDate?: string;
      endDate?: string;
      search?: string;
    }>
  >;
}

const FilterList: FC<FilterListProps> = (props) => {
  const { setRequestFilter } = props;
  const [form] = Form.useForm();
  const searchString = Form.useWatch('search', form);
  const debounceSearch = useDebounce(searchString, 500) as String;
  const [isShowFilter, setIsShowFilter] = useState(false);

  const handleChangeForm = (_, all: { search: string; startDate: Dayjs; endDate: Dayjs }) => {
    if (all.startDate) {
      setRequestFilter((prevValue) => ({
        ...prevValue,
        startDate: all.startDate.format('YYYY-MM-DD'),
      }));
    } else {
      setRequestFilter((prevValue) => ({ ...prevValue, startDate: '' }));
    }
    if (all.endDate) {
      setRequestFilter((prevValue) => ({
        ...prevValue,
        endDate: all.endDate.format('YYYY-MM-DD'),
      }));
    } else {
      setRequestFilter((prevValue) => ({ ...prevValue, endDate: '' }));
    }
  };

  useEffect(() => {
    setRequestFilter((prevValue) => ({ ...prevValue, search: debounceSearch?.replace('+', '') }));
  }, [debounceSearch, setRequestFilter]);

  return (
    <div className={s.filters}>
      <button className={s.switchFilter} onClick={() => setIsShowFilter(!isShowFilter)}>
        Фільтр пошуку
        <Icon name="arrowDropdown" className={cn(isShowFilter && s.active)} />
      </button>

      {isShowFilter && (
        <Form form={form} className={s.filtersContent} onValuesChange={handleChangeForm}>
          <div className={s.search}>
            <Label>{`Ім'я, Номер телефону`}</Label>
            <Form.Item name="search">
              <Input name="search" placeholder="Пошук" />
            </Form.Item>
          </div>
          <div className={s.date}>
            <Label>Початкова Дата</Label>
            <Form.Item name="startDate">
              <DatePicker name="startDate" placeholder="Виберіть дату" fullWidth />
            </Form.Item>
          </div>
          <div className={s.date}>
            <Label>Кінцева Дата</Label>
            <Form.Item name="endDate">
              <DatePicker name="endDate" placeholder="Виберіть дату" fullWidth />
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default FilterList;
