import { SERVICES_TAGS } from 'constants/servicesTags';
import {
  CreateDiscountResponseType,
  CreateDiscountType,
  DiscountResponseType,
  UpdateDiscountType,
} from 'services/discounts/discounts.api.type';
import initialApiService from 'services/initialApiService';

const discountsApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getAllDiscounts: builder.query<DiscountResponseType, void>({
      query: () => '/discounts',
      providesTags: [SERVICES_TAGS.GET_DISCOUNTS],
    }),
    createDiscount: builder.mutation<CreateDiscountResponseType, CreateDiscountType>({
      query: (data) => ({
        url: '/discounts',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_DISCOUNTS],
    }),
    deleteDiscount: builder.mutation<DiscountResponseType, string>({
      query: (id) => ({
        url: `/discounts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_DISCOUNTS],
    }),
    updateDiscount: builder.mutation<DiscountResponseType, UpdateDiscountType>({
      query: (data) => ({
        url: `/discounts/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.GET_DISCOUNTS],
    }),
  }),
});

export const {
  useLazyGetAllDiscountsQuery,
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
} = discountsApiService;
