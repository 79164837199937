import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import 'antd/lib/timeline/style/css';
import dayjs from 'dayjs';
import { Button, DatePicker, Form, Input, Label, Switch, Typography } from 'ui';

import TimetableItem from 'pages/routes-list/routes-create-form/routes-create-form-trip-details/trip-details-timetable/timetable-item';

import CityDropdown from 'containers/city-dropdown';

import { CityType } from 'types/cities/citiesTypes';

import { RouteBaseInfoType, TripDetailsType } from '../../routesCreateForm.utils';
import s from './TripDetailsTimetable.module.scss';

interface TripDetailsTimetableProps {
  setTripDetails: Dispatch<SetStateAction<TripDetailsType>>;
  baseInfo?: RouteBaseInfoType;
  tripDetails: TripDetailsType;
  citiesList?: CityType[];
  isEdit: boolean;
}

const TripDetailsTimetable: FC<TripDetailsTimetableProps> = (props) => {
  const [form] = Form.useForm();
  const { setTripDetails, tripDetails, citiesList, baseInfo, isEdit } = props;
  const city = Form.useWatch('city', form);
  const departureTime = Form.useWatch('departureTime', form);
  const station = Form.useWatch('station', form);
  const [isEditTimetable, setIsEditTimetable] = useState<{ isEdit: boolean; index: number }>({
    isEdit: false,
    index: 0,
  });
  const disabledAddButton = !city || !departureTime || !station;
  const sensors = useSensors(useSensor(PointerSensor));

  const handleAddButtonClick = useCallback(
    (data) => {
      const result = {
        city: data?.city?.name,
        departureTime: dayjs(data.departureTime).format('HH:mm'),
        station: data.station,
        change: data.change,
      };

      if (result) {
        if (!isEditTimetable.isEdit) {
          setTripDetails((prevState) => {
            if (prevState.timetables) {
              return {
                timetables: [...prevState.timetables, result],
              };
            } else {
              return {
                timetables: [result],
              };
            }
          });
        } else {
          if (tripDetails.timetables) {
            const timetable = [...tripDetails.timetables];
            timetable[isEditTimetable.index] = result;

            setTripDetails({ timetables: timetable });
          }
          setIsEditTimetable({ isEdit: false, index: 0 });
        }
        form?.resetFields(['city', 'departureTime', 'station', 'change']);
      }
    },
    [form, isEditTimetable, setTripDetails, tripDetails],
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id && over && over.id !== active.id) {
      const oldIndex = tripDetails?.timetables?.findIndex(
        (item, index) =>
          `${item.city}_${item.station}_${item.departureTime}_${index}` === active.id,
      );
      const newIndex = tripDetails?.timetables?.findIndex(
        (item, index) => `${item.city}_${item.station}_${item.departureTime}_${index}` === over.id,
      );

      setTripDetails((prevState) => {
        if (prevState?.timetables?.length) {
          return {
            timetables: arrayMove(prevState.timetables, Number(oldIndex), Number(newIndex)),
          };
        } else {
          return {};
        }
      });
    }
  };

  const handleDeleteItem = (id: number) => {
    setTripDetails((prevState) => ({
      timetables: prevState?.timetables?.filter((item, i) => i !== id),
    }));
  };

  const handleEditItem = useCallback(
    (
      item: { city: string; station: string; departureTime: string; change?: boolean },
      i: number,
    ) => {
      setIsEditTimetable({ isEdit: true, index: i });
      const city = citiesList?.find((city) => city.name === item.city);

      form?.setFieldsValue({
        city: city,
        departureTime: dayjs(item.departureTime, 'HH:mm'),
        station: item.station,
        change: item.change,
      });
    },
    [citiesList, form],
  );

  return (
    <div className={s.content}>
      <div>
        <Typography type="h6">Зупинки</Typography>
        <Form form={form} onFinish={handleAddButtonClick}>
          <div className={s.wrapper}>
            <Form.Item name="city">
              <CityDropdown label="Місто" placeholder="Оберіть місто" name="city" />
            </Form.Item>

            <div>
              <Label required>Час прибуття</Label>
              <Form.Item name="departureTime">
                <DatePicker.TimePicker name="departureTime" format="HH:mm" />
              </Form.Item>
            </div>

            <div>
              <Label required>Станція</Label>
              <Form.Item name="station">
                <Input name="stantion" />
              </Form.Item>
            </div>
            <div>
              <Label>Пересадка</Label>
              <Form.Item name="change">
                <Switch />
              </Form.Item>
            </div>
          </div>
          <Button disabled={disabledAddButton} htmlType="submit">
            {isEditTimetable.isEdit ? 'Зберегти зміни' : 'Додати зупинку'}
          </Button>
        </Form>
      </div>
      <div className={s.timelineContent}>
        <Typography type="h6">Список зупинок</Typography>
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext
              items={
                tripDetails?.timetables?.map(
                  (item, index) => `${item.city}_${item.station}_${item.departureTime}_${index}`,
                ) || []
              }>
              <div>
                {!isEdit && (
                  <TimetableItem
                    city={baseInfo?.fromCity.name || ''}
                    station={baseInfo?.fromStation || ''}
                    time={dayjs(baseInfo?.departureTime).format('HH:mm')}
                    id={`${baseInfo?.fromCity.name}_${baseInfo?.fromStation}_${dayjs(
                      baseInfo?.departureTime,
                    ).format('HH:mm')}_${baseInfo?.routeNumber}`}
                    disableDrag
                  />
                )}
                {tripDetails?.timetables?.map((item, i) => (
                  <TimetableItem
                    city={item.city}
                    station={item?.station}
                    time={item?.departureTime}
                    change={item?.change}
                    key={`${item.city}_${item.station}_${item.departureTime}_${i}`}
                    id={`${item.city}_${item.station}_${item.departureTime}_${i}`}
                    handleDelete={() => handleDeleteItem(i)}
                    handleEdit={() => handleEditItem(item, i)}
                  />
                ))}
                {!isEdit && (
                  <TimetableItem
                    city={baseInfo?.toCity.name || ''}
                    station={baseInfo?.toStation || ''}
                    time={dayjs(baseInfo?.arrivalTime).format('HH:mm')}
                    id={`${baseInfo?.toCity.name}_${baseInfo?.toStation}_${dayjs(
                      baseInfo?.arrivalTime,
                    ).format('HH:mm')}_${baseInfo?.routeNumber}`}
                    disableDrag
                  />
                )}
              </div>
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </div>
  );
};

export default TripDetailsTimetable;
