import { FC, memo } from 'react';

import { Tabs as TabsAntd } from 'antd';
import { TabsProps } from 'antd';
import 'antd/lib/tabs/style/css';
import cn from 'classnames';

import s from './Tabs.module.scss';

const Tabs: FC<TabsProps> = (props) => {
  const { className = '', ...otherProps } = props;

  return (
    <TabsAntd
      {...otherProps}
      tabBarGutter={24}
      defaultActiveKey="1"
      className={cn(s.wrapper, { [className]: className })}
    />
  );
};

export default memo(Tabs);
