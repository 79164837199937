import { FC } from 'react';

import type { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import cn from 'classnames';
import { Dayjs } from 'dayjs';

import Icon from '../../icon';
import { GeneratePicker } from '../utils';
import s from './RangePicker.module.scss';

const { RangePicker } = GeneratePicker;
export interface RangeDatePickerProps extends RangePickerDateProps<Dayjs> {
  fullWidth?: boolean;
}

export type RangeDatePickerOnChangeParams = Parameters<
  NonNullable<RangeDatePickerProps['onChange']>
>;

const RangeDatePicker: FC<RangeDatePickerProps> = (props) => {
  const {
    className = '',
    dropdownClassName = '',
    suffixIcon = <Icon name="calendar" />,
    fullWidth,
    ...otherProps
  } = props;

  return (
    <RangePicker
      className={cn(s.wrapper, { [className]: className, [s.fullWidth]: fullWidth })}
      suffixIcon={suffixIcon}
      dropdownClassName={cn({ [dropdownClassName]: dropdownClassName })}
      {...otherProps}
    />
  );
};

export default RangeDatePicker;
