import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './Typography.module.scss';

export type TypographyTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'main' | 'small' | 'table';
export type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'grey'
  | 'grey-medium'
  | 'secondary-light'
  | 'red'
  | 'green'
  | 'yellow';

export interface TypographyProps extends PropsWithChildren {
  type?: TypographyTypes;
  className?: string;
  color?: ColorTypes;
  weight?: 500 | 600;
}

const Typography: FC<TypographyProps> = (props) => {
  const { type = 'h3', children, color = '', className = '', weight } = props;

  return (
    <div
      className={cn(s[type], {
        [className]: className,
        [s[color]]: color,
        [s[`fw${weight}`]]: weight,
      })}>
      {children}
    </div>
  );
};

export default Typography;
