import { RootState } from 'store';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { RouteForBookingType } from 'types/routes/routesTypes';

export const eventsSliceSelector = (state: RootState) => state.eventsSlice;

export const createEventFormActiveStepSelector = (state: RootState): CREATE_EVENT_FORM_STEPS_ENUM =>
  eventsSliceSelector(state).activeStep;

export const routeForBookingSelector = (state: RootState): RouteForBookingType | undefined =>
  eventsSliceSelector(state).routeForBooking;
