import dayjs from 'dayjs';

import { KlrRouteType, RouteType } from 'types/routes/routesTypes';
import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

type BookingRouteType = RouteType | FullRouteType | KlrRouteType;
export const getRouteName = (route: BookingRouteType) => {
  if (route) {
    if ('routeName' in route) {
      return route?.routeName;
    }
    if ('route_name' in route) {
      return route?.route_name;
    }
    if ('route' in route) {
      return route?.route;
    }
  }
  return '';
};

export const getRouteDate = (route: BookingRouteType) => {
  if (route) {
    if ('departureDateTime' in route) {
      return dayjs(route?.departureDateTime).format('DD MMM YYYY HH:mm');
    }
    if ('departure_date' in route) {
      return route?.departure_date;
    }
  }
  return '';
};
