import React from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'src/styles/main.scss';

import { store } from 'store';

import App from './App';

dayjs.locale('uk');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
