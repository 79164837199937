import { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { handleCopyToClipboard } from 'tools/handleCopyToClipboard';
import { Table } from 'ui';

import {
  useChangeStatusMutation,
  useLazyGetTopTicketBookingsQuery,
} from 'services/bookings/bookingsApiService';

import FilterList from 'pages/tickets-list/contents/filter-list/filterList';
import { TICKETS_STATUS_ENUM, getRowStyle } from 'pages/tickets-list/ticketList.utils';

import Pagination from 'components/pagination';
import BookingsTableDropdown from 'containers/bookings-table-dropdown';

import { TopTicketBookingType } from 'types/bookings/bookingsTypes';
import { FullRouteType } from 'types/routes/top-tickets/topTicketsRoutesTypes';

const TopTicketBookingsList: FC = () => {
  const navigate = useNavigate();
  const [getBooking, { data: bookings }] = useLazyGetTopTicketBookingsQuery();
  const [changeStatus] = useChangeStatusMutation();
  const [requestFilter, setRequestFilter] = useState<{
    page?: number;
    limit?: number;
    sort?: string;
    order?: string;
    startDate?: string;
    endDate?: string;
    search?: string;
  }>({
    page: 1,
    limit: 10,
    sort: 'reservationDate',
    order: 'DESC',
  });

  const handleConfirm = (id, status) => {
    changeStatus({ id, action: status });
  };

  const handleActions = (key: string, order: TopTicketBookingType) => {
    if (key === 'copyLink') {
      handleCopyToClipboard(order.ticketUrl);
    }
    if (key === 'printTicket') {
      const newWindow = window.open(order.ticketUrl, '_blank');

      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
          newWindow.onafterprint = () => {
            newWindow.close();
          };
        };
      }
    }
    if (key === 'reBooking') {
      navigate(`/tickets/${order.id}`);
    }
    if (key === 'viber') {
      window.open(`viber://chat?number=${order.phone.replace(/\s/g, '')}`);
    }
    if (key === 'telegram') {
      window.open(`https://t.me/${order.phone.replace(/\s/g, '')}`);
    }
    if (key === 'whatsApp') {
      window.open(`https://wa.me/${order.phone.replace(/\s/g, '')}`);
    }

    if (key === 'confirmBooking') {
      handleConfirm(order.id, 'confirm');
    }

    if (key === 'cancelBooking') {
      handleConfirm(order.id, 'cancel');
    }
  };

  const columns = [
    {
      title: 'Перевізник, Маршрут',
      dataIndex: 'route',
      key: 'route',
      render: (route: FullRouteType) => {
        return (
          <div>
            {`${route?.carrier?.name || '-'}, ${route?.routeName || '-'}`}
            <br />
            {`${route?.fromCity || '-'} - ${route?.toCity || '-'}`}
          </div>
        );
      },
    },
    {
      title: 'Дата',
      dataIndex: 'tripDate',
      key: 'tripDate',
      render: (tripDate: string, row: TopTicketBookingType) => {
        const preparedDate = dayjs(tripDate).format('YYYY-MM-DD');
        const resultDate = row?.route?.departureTime
          ? dayjs(`${preparedDate} ${row?.route?.departureTime}`).format('DD MMMM HH:mm')
          : '-';

        return <div>{resultDate}</div>;
      },
    },
    {
      title: 'Пасажир',
      key: 'passenger',
      render: (row) => <div>{`${row?.firstName} ${row?.lastName}`}</div>,
    },
    {
      title: 'Контакти',
      dataIndex: 'phone',
    },
    {
      title: 'Ціна',
      dataIndex: 'route',
      key: 'price',
      render: (route: FullRouteType) => (
        <div>
          {route?.price?.value || '-'} {route?.price?.currency || '-'}
        </div>
      ),
    },
    {
      title: 'Підтвердження',
      dataIndex: 'state',
      key: 'status',
      render: (status) => <div>{TICKETS_STATUS_ENUM[status?.toUpperCase()]}</div>,
    },
    {
      title: 'Коментарі',
      dataIndex: 'comment',
    },
    {
      title: 'Дії',
      render: (row) => (
        <BookingsTableDropdown handleClick={(key) => handleActions(key, row)} status={row.state} />
      ),
    },
  ];

  const showedElements = Number(bookings?.data?.length) + (Number(bookings?.currentPage) - 1) * 10;

  const handleChangePage = (page: number) => {
    setRequestFilter((prevValue) => ({ ...prevValue, page }));
  };

  useEffect(() => {
    getBooking(requestFilter);
  }, [getBooking, requestFilter]);

  return (
    <div>
      <FilterList setRequestFilter={setRequestFilter} />
      <Table
        dataSource={bookings?.data}
        columns={columns}
        rowKey="id"
        pagination={false}
        rowClassName={(record) => getRowStyle(record.state)}
        scroll={{ x: 600 }}
      />
      <Pagination
        showedElements={showedElements}
        handlePageChange={handleChangePage}
        total={bookings?.totalPages}
        totalElements={bookings?.totalCount}
        currentPage={Number(bookings?.currentPage)}
      />
    </div>
  );
};

export default TopTicketBookingsList;
