import { FC, useCallback, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { getDate } from 'tools/formatDate';
import { Icon, Tooltip, Typography } from 'ui';

import { useLazyGetContraBusCarrierInfoQuery } from 'services/carriers/carriersApiService';
import { useLazyGetContraBusRouteInfoQuery } from 'services/routes/routesService';
import { setRouteForBooking } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { SERVICES_TOOLTIP } from 'pages/booking/booking-list/bookingList.utils';

import SelectRouteButton from 'components/select-route-button';
import ScheduleModal from 'containers/schedule-modal';

import { RouteType } from 'types/routes/routesTypes';

import s from './ContraBusItem.module.scss';

interface ContraBusItemProps {
  route?: RouteType;
}
const ContraBusItem: FC<ContraBusItemProps> = ({ route }) => {
  const dispatch = useAppDispatch();
  const [getServices, { data: services }] = useLazyGetContraBusCarrierInfoQuery();
  const [getRouteInfo, { data: routeInfo }] = useLazyGetContraBusRouteInfoQuery();
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  const handleSelectRoute = useCallback(() => {
    if (route) {
      dispatch(
        setRouteForBooking({
          route: route,
          owner: 'contraBus',
        }),
      );
    }
  }, [route, dispatch]);

  const handleGetServices = useCallback(async () => {
    if (route) {
      await getServices({
        data_bundle: route?.data_bundle,
        carrier_name: route?.carrier,
      });
    }
  }, [getServices, route]);

  const handleGetRouteInfo = useCallback(async () => {
    if (route) {
      const res = await getRouteInfo({
        data_bundle: route?.data_bundle,
      });

      if ('data' in res) {
        setOpenScheduleModal(true);
      }
    }
  }, [getRouteInfo, route]);

  return (
    <div
      className={cn(s.item, {
        [s.recommended]: route?.recommended,
        [s.priceLabel]: !!route?.price_label,
      })}>
      {route?.recommended && (
        <div className={s.favorite}>
          <Icon name="favorite" />
          <Typography type="small" color="green">
            Topticket рекомендує
          </Typography>
        </div>
      )}

      {route?.price_label && (
        <div className={s.favorite}>
          <Icon name="favorite" color="#F95A61" />
          <Typography type="small" color="red">
            {route?.price_label}
          </Typography>
        </div>
      )}
      <div>
        <div className={s.tripDate}>
          <Typography type="h3">
            {dayjs(getDate(route?.date, route?.departure_time)).format('HH:mm')}
          </Typography>
          <Typography type="main" weight={600}>
            {dayjs(getDate(route?.date, route?.departure_time)).format('DD MMM')}
          </Typography>
        </div>
        <div>
          <Typography type="main" color="primary">
            {route?.from},
          </Typography>
          <Typography type="small" color="primary">
            {route?.departure_station}
          </Typography>
        </div>
        <div className={s.hide}>
          <div className={s.actions}>
            <button className={s.action} onClick={handleGetRouteInfo}>
              <Typography type="small" className={s.actionText}>
                Маршрут
              </Typography>
            </button>
            <button className={s.action} onClick={handleGetServices}>
              <Typography type="small" className={s.actionText}>
                Зручності
              </Typography>
            </button>
          </div>
          <div>
            {services?.length && (
              <div>
                {services?.map((service) => (
                  <div key={service.carrier_id} className={s.service}>
                    {service.carrier_services.map((item) => (
                      <Tooltip key={item} title={SERVICES_TOOLTIP[item]}>
                        <div>
                          <Icon name={item} size={26} />
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className={s.tripDate}>
          <Typography type="h3">
            {dayjs(getDate(route?.arrival_date, route?.arrival_time)).format('HH:mm')}
          </Typography>
          <Typography type="main" weight={600}>
            {dayjs(getDate(route?.arrival_date, route?.arrival_time)).format('DD MMM')}
          </Typography>
        </div>
        <div>
          <Typography type="main" color="primary">
            {route?.to},
          </Typography>
          <Typography type="small" color="primary">
            {route?.arrival_station}
          </Typography>
        </div>
      </div>

      <div className={s.mobile}>
        <div className={s.actions}>
          <button className={s.action} onClick={handleGetRouteInfo}>
            <Typography type="small" className={s.actionText}>
              Маршрут
            </Typography>
          </button>
          <button className={s.action} onClick={handleGetServices}>
            <Typography type="small" className={s.actionText}>
              Зручності
            </Typography>
          </button>
        </div>
        <div>
          {services?.length && (
            <div>
              {services?.map((service) => (
                <div key={service.carrier_id} className={s.service}>
                  {service.carrier_services.map((item) => (
                    <Tooltip key={item} title={SERVICES_TOOLTIP[item]}>
                      <div>
                        <Icon name={item} size={26} />
                      </div>
                    </Tooltip>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={s.price}>
        <Typography type="h3" weight={600}>
          {`${Number(route?.price)}
          ${route?.currency}`}
        </Typography>
        <SelectRouteButton onClick={handleSelectRoute} />
      </div>

      {routeInfo?.schedule && (
        <ScheduleModal
          open={openScheduleModal}
          onClose={() => setOpenScheduleModal(false)}
          schedule={routeInfo?.schedule}
          changeInfo={route?.change_info}
        />
      )}
    </div>
  );
};

export default ContraBusItem;
